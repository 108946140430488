import React, { useContext, useEffect, useState} from "react";
import Swal from 'sweetalert2';
import './styles.css';
import { AuthContext } from '../../AuthProvider';
import { getUserActiveSubscription } from '../../APIFirebase';
import { cancelStripeSubscription } from "../../API";

const Card = ({ subscriptionType, logout }) => {
  const [activeSubscriptionId, setActiveSubscriptionId] = useState("");
  const [activeSubscriptionDescription, setActiveSubscriptionDescription] = useState("");

  useEffect(() =>{
    async function getSubscription(){
      const uid = await localStorage.getItem('uid');
      if (uid){
        const subscriptions = await getUserActiveSubscription(uid)
        if(subscriptions.length>0){
          const subscription_id = subscriptions[0].items[0].subscription
          const subscription_description = subscriptions[0].items[0].price.product.description
          setActiveSubscriptionId(subscription_id)
          setActiveSubscriptionDescription(subscription_description)
        }
      }
      
    }
    getSubscription()
 
   },[]);

   const onUnsubscribe = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Unsubscribe'
    }).then((result) => {
      if (result.isConfirmed) {
        if(activeSubscriptionId!=""){
          async function cancelSubscriptPlan() {
            try {
              await cancelStripeSubscription(activeSubscriptionId)
              //alert("Your subscription was canceled.");
              Swal.fire(
                'Unsubscribed!',
                'Your subscription was canceled',
                'success'
              )
              window.location.href = window.location.origin; // Replace '/' with the actual URL of your home page
            } catch (error) {
              alert("There was an error, please try again.")
            }
          }
          cancelSubscriptPlan();
        }else{
          alert("You are not subscribed to any subscription.")
        }
        
      }
    })
    
    
  };
  return (
    <div className="profile-card">
      <img className="profile-image" src="https://proton-resources-production.imgix.net/1ac6c764a8f8711c1171c11850f47ca5c11754c7de31a30db6b2c0784087f71d.png?orient=&auto=compress" alt="Profile" />
      <AuthContext.Consumer>
        {
          ({user}) => {
            return(
              <h2 className='data_profile my-4 font-edit-profile profile-name' style={{color:"white"}}>{user.name}</h2>
            )}
        }
      </AuthContext.Consumer>
      <AuthContext.Consumer>
        {
          ({user}) => {
            return(
              <b className='data_profile my-4 font-edit-profile profile-email' style={{color:"white"}}>{user.email}</b>
            )}
        }
      </AuthContext.Consumer>
      
      {activeSubscriptionId !== "" && (
        <div>
        <p className="profile-subscription" style={{color:'white'}}>Subscription: {activeSubscriptionDescription}</p>
        <button className="unsubscribe-button" onClick={onUnsubscribe}>
          Unsubscribe
        </button>
        </div>
      )}
      <button className="cta-button" style={{marginTop:"20px"}} onClick={logout}>Logout</button>
    </div>
  );
}

export default Card;
