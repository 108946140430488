import './styles.css';
import './../../App.css';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  return (
    <div className="forgot">
      <div className='box' >
        <div className='title_fg' >Password Reset</div>
        <div className='sub_title_fg' >
          Enter your email address and we'ill send you intructions on how to reset your password
        </div>
        
        <div className='mt-4' >
          <div className='my-1' >
            <Input title={"Email"} placeholder="Enter email..." />
          </div>
          <div className='mt-3' >
            <Button title={"SUBMIT"} />
          </div>
          <Link className='cancel_fg' to="/login" >
            CANCEL
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
