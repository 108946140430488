import axios from 'axios';
import { API_URL } from './constants';

const headers = {
    'Content-Type': 'application/json;',
    'X-Requested-With': 'XMLHttpRequest'
  };

export const createModel = (link_url,uuid,title,files,additionalText) => {
  // console.log("link ",link_url)
  // console.log("uuid ",uuid)
  // console.log("title ",title)
  const formData = new FormData();
  if (files.length > 0){
    files.forEach(file => {
      formData.append('files',file);
    });
    console.log("con archivos");
    return new Promise((resolve, reject) => {
      axios
        .post(                           
          //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
          `${API_URL}/upsert-file?project_name=${title}&uuid=${uuid}&user_name=${uuid.slice(0, -2)}&additional_text=${additionalText}`,
          formData,
          {
              // headers: {...headers, Authorization: 'Bearer ' + token},
              headers: {'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest'},
          },
        )
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          console.log('API ERROR (createModel): ');
          // console.log(error.message);
          reject(error);
        });
    });
  }
  return new Promise((resolve, reject) => {
    console.log("entra al upsert de solo texto");
    axios
      .post(                           
        //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
        `${API_URL}/upsert-file?project_name=${title}&uuid=${uuid}&user_name=${uuid.slice(0, -2)}&additional_text=${additionalText}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (createModel): ');
        // console.log(error.message);
        reject(error);
      });
  });
  
};

export const updateModelFiles = (uuid,title,files,additionalText) => {
  // console.log("link ",link_url)
  // console.log("uuid ",uuid)
  // console.log("title ",title)
  const context_url = `context/${uuid.slice(0,-2)}/data_context_${title}.csv`;
  const formData = new FormData();
  if (files.length > 0){
    files.forEach(file => {
      formData.append('files',file);
    });
    return new Promise((resolve, reject) => {
      axios
        .post(                           
          //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
          `${API_URL}/update-upsert-file?context_url=${context_url}&uuid=${uuid.slice(0,-2)}&additional_text=${additionalText}`,
          formData,
          {
              // headers: {...headers, Authorization: 'Bearer ' + token},
              headers: {'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest'},
          },
        )
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          console.log('API ERROR (createModel): ');
          // console.log(error.message);
          reject(error);
        });
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .post(                           
        //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
        `${API_URL}/update-upsert-file?context_url=${context_url}&uuid=${uuid.slice(0,-2)}&additional_text=${additionalText}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (createModel): ');
        // console.log(error.message);
        reject(error);
      });
  });
  
};


export const getAnswer = (context_url,personality,question,body,model) => {
  // console.log("context ",context_url)
  // console.log("question ",question)
  //console.log("api MODEL",model)
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/sendQuestionFirebase?context_url=${context_url}&question=${question}&personality=${personality}&model=${model}`,
        body,
        {
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (getAnswer): ');
        console.log(error.message);
        reject(error);
      });
  });
};
export const checkStatus = (uuid,title) => {
  var context_url = "context/"+uuid.slice(0, -2)+"/data_context_"+title+".csv"
  console.log(context_url)
  // console.log("UUID MODEL ",uuid)
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${API_URL}/getModelStateLinkFirebase?context_url=${context_url}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};

export const cancelStripeSubscription = (subscription_id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/cancelStripeSubscription?subscription_id=${subscription_id}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};

// Analytics

export const getBasicAnalyticsInfo = (id,start_date,end_date) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/getBasicAnalyticsChatsData?export_chat_id=${id}&start_date=${start_date}&end_date=${end_date}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};

export const getAiAnalyticsInfo = (id,start_date,end_date) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/getAnalyticsChatsData?export_chat_id=${id}&start_date=${start_date}&end_date=${end_date}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};

export const getSessionsData = (id,start_date,end_date) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL}/getUsersSessionsExportChatsData?export_chat_id=${id}&start_date=${start_date}&end_date=${end_date}`,
        {
            // headers: {...headers, Authorization: 'Bearer ' + token},
            headers: {...headers},
        },
      )
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log('API ERROR (checkStatus): ');
        console.log(error.message);
        reject(error.message);
      });
  });
};
/*export const generatePDFReport = async (report_data,report_name) => {
  /*report_data = [
    {
      "Executive Summary": "The provided chats are  between customers and a chatbot for a company called Marmolería Yerba Buena. The customers ask various questions about the products and services offered by the company, while the chatbot provides detailed responses. The conversation topics mainly revolve around the types of products available, such as marble and granite slabs, kitchen and bathroom countertops, stairs, chimneys, and marble restoration. The chatbot is able to handle inquiries about the company and its offerings, but it does not have access to personal information or the ability to remember previous conversations. The customers express positive sentiment towards the chatbot, appreciating its helpfulness and friendly manner.",
      "User Interaction Analysis": {
        "Conversation Trends": "There is a consistent trend of customers asking about the different products and services offered by Marmolería Yerba Buena. The chatbot provides detailed information about the various types of marble and granite slabs available, as well as the customization options for kitchen and bathroom countertops. Customers also inquire about the restoration services offered by the company.",
        "Product-related Queries": "The main product-related queries revolve around the types of products available, including marble and granite slabs, kitchen and bathroom countertops, stairs, chimneys, and marble restoration. Customers are interested in knowing the range of colors and finishes available for the slabs and the customization options for the countertops.",
        "Sentiment Analysis": "The sentiment analysis of the chats reveals a positive sentiment overall. Customers express satisfaction with the chatbot's helpfulness and friendly manner. There are no instances of negative sentiment in the provided chats."
      },
      "Key Insights and Recommendations": "Based on the analysis of the provided chats, it is clear that customers are primarily interested in the range of products offered by Marmolería Yerba Buena. To capitalize on this interest, the company should ensure that its website and other marketing materials prominently feature information about the different types of marble and granite slabs, as well as visuals showcasing the customization options for kitchen and bathroom countertops. Additionally, the company should consider showcasing examples of its restoration work to further highlight its expertise in this area. Finally, the positive sentiment expressed by customers towards the chatbot indicates that it is providing valuable assistance. The company should continue to monitor and improve the chatbot's performance to ensure a positive customer experience.",
      "Conclusion": "In conclusion, the analysis of the provided chats highlights customers' interest in the products and services offered by Marmolería Yerba Buena, particularly in relation to marble and granite slabs, kitchen and bathroom countertops, stairs, chimneys, and marble restoration. The chatbot is able to handle inquiries about these topics effectively, providing detailed and helpful responses. Customers express positive sentiment towards the chatbot, appreciating its assistance and friendly manner. The company should use these insights to optimize its marketing efforts and continue to provide a positive customer experience.",
      "KPIs": {
        "sentiment_analysis": {
          "positive%": 100,
          "neutral%": 0,
          "negative%": 0
        },
        "top_ten_topics": [
          {
            "rank": 1,
            "topic": "Types of products available (marble and granite slabs, kitchen and bathroom countertops, stairs, chimneys, and marble restoration)"
          },
          {
            "rank": 2,
            "topic": "Range of colors and finishes available for marble and granite slabs"
          },
          {
            "rank": 3,
            "topic": "Customization options for kitchen and bathroom countertops"
          },
          {
            "rank": 4,
            "topic": "Restoration services for marble"
          }
        ]
      }
    },
    {
      "total_conversations": 1,
      "average_conversation_duration": 1.45,
      "mean_conversation_length": 6,
      "Users by Day": {
        "2023-07-15": 1
      },
      "Users by Hour": {
        "12": 1
      },
      "percent_positive": 33.33333333333333,
      "percent_negative": 16.666666666666664,
      "percent_neutral": 50
    }
  ];
    axios
      .post(API_URL+'/generatePDFReport?report_name='+report_name,
      report_data,
      {headers: {
        "Content-Type": "application/json", 
      }}
      )
      .then((response) => {
        // The response should contain the PDF file
        console.log(response)
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        // Create a download link and trigger the download
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${report_name}.pdf`;
        a.click();

        // Clean up the object URL
        URL.revokeObjectURL(url);
        return true;
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        return false;
      });
    }
    */
    export const generatePDFReport = async (report_data, report_name) => {
      try {
        const response = await axios.post(
          `${API_URL}/generatePDFReport?report_name=${report_name}`,
          report_data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
    
        // The response should contain the PDF file
        console.log(response);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    
        // Create a download link and trigger the download
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${report_name}.pdf`;
        a.click();
    
        // Clean up the object URL
        URL.revokeObjectURL(url);
    
        return true;
      } catch (error) {
        console.error("Error generating PDF:", error);
        return false;
      }
    };
    

    export const createFreeModel = (personality,title,files,additionalText) => {
      // console.log("link ",link_url)
      // console.log("uuid ",uuid)
      // console.log("title ",title)
      const formData = new FormData();
      if (files.length > 0){
        files.forEach(file => {
          formData.append('files',file);
        });
        console.log("con archivos");
        return new Promise((resolve, reject) => {
          axios
            .post(                           
              //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
              `${API_URL}/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
              //`http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
              formData,
              {
                  // headers: {...headers, Authorization: 'Bearer ' + token},
                  headers: {'Content-Type': 'multipart/form-data',
                  'X-Requested-With': 'XMLHttpRequest'},
              },
            )
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              console.log('API ERROR (createModel): ');
              // console.log(error.message);
              reject(error);
            });
        });
      }
      return new Promise((resolve, reject) => {
        console.log("entra al upsert de solo texto");
        axios
          .post(                           
            //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
            `${API_URL}/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
           // `http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
            {
                // headers: {...headers, Authorization: 'Bearer ' + token},
                headers: {'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'},
            },
          )
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            console.log('API ERROR (createModel): ');
            // console.log(error.message);
            reject(error);
          });
      });
      
    };

    export const sendEmail = (body) => {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${API_URL}/sendContactEmail`,
            body,
            {
              headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token, // Uncomment and populate this if you need to include a token
                'X-Requested-With': 'XMLHttpRequest',
              },
            }
          )
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            console.log('API ERROR (sendEmail): ', error);
            reject(error);
          });
      });
    };

    export const calculateTokens = (additionalText, files) => {
      console.log("addtionaltext ",additionalText);
      console.log("files ",files);
      // console.log("title ",title)
      const formData = new FormData();
      if (files.length > 0){
        files.forEach(file => {
          formData.append('files',file);
        });
        console.log("con archivos", formData);
        return new Promise((resolve, reject) => {
          axios
            .post(                           
              //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
              `${API_URL}/getFileTokens`,
              //`http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
              formData,
              {
                  // headers: {...headers, Authorization: 'Bearer ' + token},
                  headers: {'Content-Type': 'multipart/form-data',
                  'X-Requested-With': 'XMLHttpRequest'},
              },
            )
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              console.log('API ERROR (createModel): ');
              // console.log(error.message);
              reject(error);
            });
        });
      }
      /*return new Promise((resolve, reject) => {
        console.log("entra al upsert de solo texto");
        axios
          .get(                           
            //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
            `${API_URL}/getFileTokens?additional_text=${additionalText}`,
           // `http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
            {
                // headers: {...headers, Authorization: 'Bearer ' + token},
                headers: {'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'},
            },
          )
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            console.log('API ERROR (createModel): ');
            // console.log(error.message);
            reject(error);
          });
      });*/
      
    };


    export const createModelNewVersion = (uuid,user_name,project_name,chat_title,personality,welcome_message,headerColor1,headerColor2,button_text,additional_text,files) => {
      // console.log("link ",link_url)
      // console.log("uuid ",uuid)
      // console.log("title ",title)
      const formData = new FormData();
      if (files.length > 0){
        files.forEach(file => {
          formData.append('files',file);
        });
        console.log("con archivos");
        return new Promise((resolve, reject) => {
          axios
            .post(                           
              //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
             // `${API_URL}/upsert-file-export?uuid=${uuid}&user_name=${user_name}&project_name=${project_name}&chat_title=${chat_title}&personality=${personality}&welcome_message=${welcome_message}&header_color_1=${headerColor1}&header_color_2=${"#000000"}&button_text=${"Chat now"}&additional_text=${additional_text}`,
              `${API_URL}/upsert-file-export?uuid=${uuid}&user_name=${uuid}&project_name=${chat_title}&chat_title=${chat_title}&personality=${personality}&welcome_message=${welcome_message}&header_color_1=${headerColor1}&header_color_2=${headerColor2}&button_text=${button_text}&additional_text=${additional_text}`,
              //`http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
              formData,
              {
                  // headers: {...headers, Authorization: 'Bearer ' + token},
                  headers: {'Content-Type': 'multipart/form-data',
                  'X-Requested-With': 'XMLHttpRequest'},
              },
            )
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              console.log('API ERROR (createModel): ');
              // console.log(error.message);
              reject(error);
            });
        });
      }
      return new Promise((resolve, reject) => {
        console.log("entra al upsert de solo texto");
        axios
          .post(                           
            //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
            `${API_URL}/upsert-file-export?uuid=${uuid}&user_name=${uuid}&project_name=${chat_title}&chat_title=${chat_title}&personality=${personality}&welcome_message=${welcome_message}&header_color_1=${headerColor1}&header_color_2=${headerColor2}&button_text=${button_text}&additional_text=${additional_text}`,
           // `http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
            {
                // headers: {...headers, Authorization: 'Bearer ' + token},
                headers: {'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'},
            },
          )
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            console.log('API ERROR (createModel): ');
            // console.log(error.message);
            reject(error);
          });
      });
      
    };

    export const updateModelNewVersion = (uuid,chat_title,personality,welcome_message,headerColor1,headerColor2,button_text,context_url,export_chat_id,additional_text,files) => {
      // console.log("link ",link_url)
      // console.log("uuid ",uuid)
      // console.log("title ",title)
      const formData = new FormData();
      if (files.length > 0){
        files.forEach(file => {
          formData.append('files',file);
        });
        console.log("con archivos");
        return new Promise((resolve, reject) => {
          axios
            .post(                           
              //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
             // `${API_URL}/upsert-file-export?uuid=${uuid}&user_name=${user_name}&project_name=${project_name}&chat_title=${chat_title}&personality=${personality}&welcome_message=${welcome_message}&header_color_1=${headerColor1}&header_color_2=${"#000000"}&button_text=${"Chat now"}&additional_text=${additional_text}`,
              `${API_URL}/update-upsert-file-export?uuid=${uuid}&chat_title=${chat_title}&personality=${personality}&welcome_message=${welcome_message}&header_color_1=${headerColor1}&header_color_2=${headerColor2}&button_text=${button_text}&context_url=${context_url}&export_chat_id=${export_chat_id}&additional_text=${additional_text}`,
              //`http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
              formData,
              {
                  // headers: {...headers, Authorization: 'Bearer ' + token},
                  headers: {'Content-Type': 'multipart/form-data',
                  'X-Requested-With': 'XMLHttpRequest'},
              },
            )
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              console.log('API ERROR (createModel): ');
              // console.log(error.message);
              reject(error);
            });
        });
      }
      return new Promise((resolve, reject) => {
        console.log("entra al upsert de solo texto");
        axios
          .post(                           
            //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
            `${API_URL}/update-upsert-file-export?uuid=${uuid}&user_name=${uuid}&project_name=${chat_title}&chat_title=${chat_title}&personality=${personality}&welcome_message=${welcome_message}&header_color_1=${headerColor1}&header_color_2=${headerColor2}&button_text=${button_text}&context_url=${context_url}&export_chat_id=${export_chat_id}&additional_text=${additional_text}`,
           // `http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
            {
                // headers: {...headers, Authorization: 'Bearer ' + token},
                headers: {'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'},
            },
          )
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            console.log('API ERROR (createModel): ');
            // console.log(error.message);
            reject(error);
          });
      });
      
    };


    export const deleteExportChat = (export_chat_id) => {
      console.log("addtionaltext ",export_chat_id);
        return new Promise((resolve, reject) => {
          axios
            .post(                           
              //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
              `${API_URL}/deleteExportChats?export_chat_id=${export_chat_id}`,
              //`http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
              {
                  // headers: {...headers, Authorization: 'Bearer ' + token},
                  headers: {'Content-Type': 'multipart/form-data',
                  'X-Requested-With': 'XMLHttpRequest'},
              },
            )
            .then(data => {
              resolve(data);
            })
            .catch(error => {
              console.log('API ERROR (createModel): ');
              // console.log(error.message);
              reject(error);
            });
        });
      };


      export const createStripeSubscriptionAppSumoCoupon = (uuid,coupon_id) => {
        console.log(uuid,coupon_id);
          return new Promise((resolve, reject) => {
            axios
              .post(                           
                //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
                `${API_URL}/createStripeSubscriptionAppSumoCoupon?uuid=${uuid}&coupon_id=${coupon_id}`,
                //`http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
                {
                    // headers: {...headers, Authorization: 'Bearer ' + token},
                    headers: {'Content-Type': 'multipart/form-data',
                    'X-Requested-With': 'XMLHttpRequest'},
                },
              )
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                console.log('API ERROR (createStripeSubscriptionAppSumoCoupon): ', error);
                console.log(error.message);
                reject(error);
              });
          });
        };

        export const checkCuponExists = (coupon_id) => {
            return new Promise((resolve, reject) => {
              axios
                .post(                           
                  //`${API_URL}/trainModelFirebase?proyect_name=${title}&data_url=${link_url}&uuid=${uuid}&user_name=Mauricio`, 
                  `${API_URL}/getStripeCouponStatus?coupon_id=${coupon_id}`,
                  //`http://127.0.0.1:8000/upsert-file-free?personality=${personality}&chat_title=${title}&additional_text=${additionalText}`,
                  {
                      // headers: {...headers, Authorization: 'Bearer ' + token},
                      headers: {'Content-Type': 'multipart/form-data',
                      'X-Requested-With': 'XMLHttpRequest'},
                  },
                )
                .then(data => {
                  resolve(data);
                })
                .catch(error => {
                  console.log('API ERROR (getStripeCouponStatus): ', error);
                  console.log(error.message);
                  reject(error);
                });
            });
          };

    
      
    


    