import './styles.css';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../../node_modules/boxicons/css/boxicons.min.css';


function SideBar({ isSidebarOpen, isMobile }) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const navigate = useNavigate()
    function toggleCollapse() {
        setIsCollapsed(!isCollapsed);
      }
   /* let sideBar = document.querySelector('.side-bar');
  let arrowCollapse = document.querySelector('#logo-name__icon');
  sideBar.onclick = () => {
    sideBar.classList.toggle('collapse2');
    arrowCollapse.classList.toggle('collapse');
    if (arrowCollapse.classList.contains('collapse')) {
      arrowCollapse.classList =
        'bx bx-arrow-from-left logo-name__icon collapse';
    } else {
      arrowCollapse.classList = 'bx bx-arrow-from-right logo-name__icon';
     }}*/
  return (
    <div class={`${isMobile ? 'side-bar' : 'side-bar'}${isSidebarOpen ? ' collapse2' : ''}`} onClick={toggleCollapse}>
  <div class="logo-name-wrapper">
  <div class="logo-name">
      <span class="logo-name__name logo">Jigma</span>
    </div>
    <button type="button" class="logo-name__button">
      <i
        class={`bx logo-name__icon ${isCollapsed ? 'collapse2 ' : ''}`}
        id="logo-name__icon"
      ></i>
    </button>
  </div>
  <ul class="features-list">
    <Link to='/dashboard' className="btn features-item inbox">
    <li className='features-item-box'>
      <i class="bx bx-conversation features-item-icon inbox-icon"
        ><span class="status"></span>
        </i>
      <span class="features-item-text">Chat</span>
      
      <span class="tooltip">Chat</span>
    </li>
    </Link>
    <Link to='/pre-gym' className="btn features-item draft">
    <li className='features-item-box'>
      <i class="bx bx-file-blank features-item-icon"></i>
      <span class="features-item-text">Create</span>
      <span class="tooltip">Create</span>
    </li>
    </Link>
    <Link to='/customize' className="btn features-item draft">
    <li className='features-item-box'>
      <i class="bx bx-export features-item-icon"></i>
      <span class="features-item-text">Export</span>
      <span class="tooltip">Export</span>
    </li>
    </Link>
    <Link to='/payments' className="btn features-item star" >
    <li className='features-item-box'>
      <i class="bx bx-dollar features-item-icon"></i>
      <span class="features-item-text">Pricing</span>
      <span class="tooltip">Pricing</span>
    </li>
    </Link>
    <Link to='/analytics' className="btn features-item star" >
    <li className='features-item-box'>
      <i class="bx bxs-report features-item-icon"></i>
      <span class="features-item-text">Analytics</span>
      <span class="tooltip">Analytics</span>
    </li>
    </Link>
    
  </ul>
  
</div>
  );
}

export default SideBar;