
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import Models from "./pages/Models";
import PreGym from "./pages/PreGym";
import Chat from "./pages/Chat";
import Gym from "./pages/Gym";
import EditProfile from "./pages/EditProfile";
import {
    createBrowserRouter,
  } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "profile/edit",
    element: <EditProfile />,
  },
  {
    path: "pre-gym",
    element: <PreGym />,
  },
  {
    path: "gym",
    element: <Gym />,
  },
  {
    path: "/dashboard",
    element: <Chat />,
  },
]);
export default router;