import { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthProvider';
import Header from '../../components/Header';
import './styles.css';
import { useAuth } from '../../utils';

function Home() {
  const navigator = useNavigate()
  const context = useContext(AuthContext);
  const {onLoginWithGoogle} = useAuth();
  // useEffect(()=> {
  //   if (context.user.email){
  //     navigator('/dashboard')
  //   }
  // },[])
  return (
    <div className="home container">
      <div className='col justify-content-center my-5 logo-image' >
            <h2 className='title_home text-center h2 font-inter' >  Jigma</h2>
            <img className=' img_home'   src="https://proton-resources-production.imgix.net/06e42ffce703c1dbab65d25be4d9c400d86ce8a5a526390b1221747bdf4dba53.png?orient=&auto=compress" />
      </div>
      <div className='row justify-content-center my-5' >
        <div className='col-4 row login-signup' >
            <Link to='/signup' className=' button-login-signup signup font-inter' >SIGN UP</Link>
            <Link to='/login' className=' button-login-signup font-inter' >LOG IN</Link>
            <button onClick={onLoginWithGoogle}>Sign in With Google</button>
        </div>
      </div>
      <div className='' ></div>

    </div>
  );
}

export default Home;
