import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import {  db, logInWithEmailAndPassword, logout} from "./firebase";
import { signInWithGoogle } from "./firebase";


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: null,
    accessToken: null,
    name: null,
    uid: null,
    models: [],
    hasUnfinishModel: false
  });
  const [isLoading, setIsLoading] = useState(true);
  const addToChat = (item,indexModel) => {
    // console.log("STARTED ")
    const modelAux = user.models[indexModel];
    // console.log("model ",modelAux)

      modelAux.chat = [
        ...modelAux.chat,
        item
      ];
    // console.log("new ",modelAux)
    // console.log("user ",{
    //   ...user,
    //   models: modelAux
    // })

      // setUser({
      //   ...user,
      //   models: modelAux
      // })
  }

  const navigate = useNavigate()
  const location = useLocation()
  const handleLogin = async (email,password) => {
    // const token = await fakeAuth();
    const res = await logInWithEmailAndPassword(email,password);
    const _user = res.user
    if (_user){
      const uid = _user.uid;
      // const q = query(collection(db, "users"), where("uid", "==", uid));
      // const doc = await getDocs(q);
      // const data = doc.docs[0].data();
      // console.log("asdasd",doc.docs[0].data())
      if (_user.emailVerified === false){
        return "Please, validate your email. Check the inbox of "+_user.email
      } else {
        const docRef = doc(db, "customers", uid);
        const docSnap = await getDoc(docRef);
        const models = docSnap?.data()?.models;
        const hasUnfinishModel = models?.length === 0 ? false : (models[models.length-1].link === null ? true : false)

        setUser({
          email: docSnap.data().email,
          accessToken: _user.accessToken,
          name: docSnap.data().name,
          uid: uid,
          docId: docSnap.data().docId,
          hasUnfinishModel,
        })

        await localStorage.setItem('uid',uid)
        setIsLoading(false);

     
      
      const origin = location.state?.from?.pathname || '/dashboard';
      navigate(origin);
      return true;
      } 
    }else {
      return "Your credentials are wrong";
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      // Call the signInWithGoogle function from your firebase.js file
      const res = await signInWithGoogle();
      console.log("la respuesta",res);
      // Extract user data from the response
      if(res.user){
        const _user = res.user;
  
        const uid = _user.uid;
        const docRef = doc(db, "customers", uid);
        const docSnap = await getDoc(docRef);
        const models = docSnap?.data()?.models;
        const hasUnfinishModel = models?.length === 0 ? false : (models[models.length - 1].link === null ? true : false);
    
        // Set the user state
        setUser({
          email: docSnap.data().email,
          accessToken: _user.accessToken,
          name: docSnap.data().name,
          uid: uid,
          docId: docSnap.data().docId,
          hasUnfinishModel,
        });
        await localStorage.setItem('uid', uid);
        setIsLoading(false);
        const origin = location.state?.from?.pathname || '/dashboard';
        navigate(origin);
        return true;
      }
      if(res[0] === true){
        const hasUnfinishModel=false;
        setUser({
          email: res[1],
          accessToken: res[2],
          name: res[3],
          uid: res[4],
          docId: res[4],
          hasUnfinishModel,
        });
        await localStorage.setItem('uid', res[4]);
        setIsLoading(false);
        const origin = location.state?.from?.pathname || '/dashboard';
        navigate(origin);
        return true;
      }
  
      
    } catch (err) {
      console.error(err);
      return err.message;
    }
  };
  
 

  const handleLogout =  async () => {
    // const token = await fakeAuth();
    setUser({
      email: null,
      accessToken: null,
      name: null,
      uid: null,
      models: [],
    })
    await localStorage.clear();
    logout();
    setIsLoading(false);
    // setToken(token);
  };


  const value = {
    user,
    isLoading,
    onLogin: handleLogin,
    onLoginWithGoogle: handleLoginWithGoogle,
    onLogout: handleLogout,
    setUser,
    addToChat
  };
  // useEffect(() => {
  //   // onAuthStateChanged(setUser);
  //   const auth = getAuth();
  //   onAuthStateChanged(auth, async (user) => {
  //     // console.log("USER CHANGEDD",user)
  //   if (user) {
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //       const uid = user.uid;
  //       const q = query(collection(db, "users"), where("uid", "==", uid));
  //       const doc = await getDocs(q);
  //       const data = doc.docs[0].data();
  //       // console.log("asdasd",doc.docs[0].data())
        
  //       setUser({
  //         email: data.email,
  //         accessToken: user.accessToken,
  //         name: data.name,
  //         uid: uid,
  //         docId: doc.docs[0].id,
  //         models: data.models,
  //       })
  //       // ...
  //   } else {
  //       setUser({
  //         email: null,
  //         accessToken: null,
  //         name: null,
  //         uid: null,
  //         models: [],
  //       })
  //   }
  //   });
  // }, []);

  useEffect(() => {
    async function updateStorage() {
      if (user.email !== null) {
        await localStorage.setItem('user', JSON.stringify(user));
      }
    }
    updateStorage();
  }, [user]);
  

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};