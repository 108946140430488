import { ref } from "firebase/database";
import { doc, query, collection, getDocs, where , updateDoc, getDoc, setDoc, addDoc, onSnapshot } from "firebase/firestore";
import { db } from './firebase';
import { getStorage,  ref as sRef,uploadString,getDownloadURL,uploadBytesResumable } from "firebase/storage";
import { getDate, useAuth } from './utils';

export const addModelToUser = async (title,description,userId,personality) => {
    try {
        const docRef = doc(db, "customers", userId);
        const docSnap = await getDoc(docRef);
        const _createdModels = docSnap.data().createdModels 
        const models = docSnap.data().models;
        const uuid = userId+"-"+(_createdModels+1).toString();
        //const uuid = userId+"-"+models.length;
        const _models = models;
        _models.push({
            title,
            description,
            personality: personality,
            uuid,
            link: "",
            chat: []
          });
        await updateDoc(docRef, {
          createdModels: _createdModels + 1,
          models: _models
        });
        
        return uuid;
    } catch (error) {
        console.log("ERROR (addModelToUser) ",error)
    }

}

export const addJigmaModelToUser = async (title,description,userId,personality,link) => {
  try {
      const docRef = doc(db, "customers", userId);
      const docSnap = await getDoc(docRef);
      const _createdModels = docSnap.data().createdModels
      const models = docSnap.data().models;
      const uuid = userId+"-"+(_createdModels+1).toString();
      //const uuid = userId+"-"+models.length;
      const _models = models;
      // Create a Date object using the current timestamp
      const dateObject = new Date();

      // Function to get the UTC offset string (e.g., UTC-3)
      function getUTCOffsetString(date) {
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes < 0 ? '+' : '-';
        return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
      }

      // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
      const formattedDate = `${dateObject.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      })} ${getUTCOffsetString(dateObject)}`;

      _models.push({
          title,
          description,
          personality: personality,
          uuid,
          link: link,
          chat: [{
            title: "How to use Jigma?",
            date: formattedDate
          },
          {
            title: `Welcome to Jigma! I am glad that you are here, I can help you guiding you in your first steps into Jigma, please ask everything you want. But first, here is how you can create your first assistant:

            1. Click the create button at the top
            2. Name your assistant and give it a personality
            3. Add the files you'd like to train your assistant on
            4. Click RUN to train your assistant
            5. You are ready to start chatting!`,
            date: formattedDate
          }]
        });
      await updateDoc(docRef, {
        createdModels: _createdModels + 1,
        models: _models
      });
      return uuid;
  } catch (error) {
      console.log("ERROR (addModelToUser) ",error)
  }
}

export const addChatToUser = async (text,userId,models,chatActive) => {
    try {
        const docRef = doc(db, "customers", userId);
        const _models = models;
        _models[chatActive].chat.push(text);
        await updateDoc(docRef, {
          models: _models
        });
    } catch (error) {
        console.log("ERROR (addChatToUser) ",error)
    }

}


export const removeLastChatOfUser = async (userId,models,chatActive) => {
  try {
      const docRef = doc(db, "customers", userId);
      const _models = models;
      _models[chatActive].chat.pop();
      await updateDoc(docRef, {
        models: _models
      });
  } catch (error) {
      console.log("ERROR (addChatToUser) ",error)
  }

}

export const updateModel = async (userId,) => {
    try {

      // const storage = getStorage();
      // const storageRef = sRef(storage, file_name);
      // Raw string is the default if no format is provided
      // const message = data;
      // await uploadString(storageRef, message)

      // const url = await getDownloadURL(storageRef)
      // console.log("URL  ",url)
      const docRef = doc(db, "customers", userId);
      const docSnap = await getDoc(docRef);
      const models = docSnap.data().models;
      const _models = models;
      // console.log(_models[_models.length-1])
      _models[_models.length-1].link = null;

        
        await updateDoc(docRef, {
          models: _models
        });


        
    } catch (error) {
        console.log("ERROR(updateModel) ",error)
    }

}


export const updateModelLink = async (userId,models,link) => {
    try {

    const docRef = doc(db, "customers", userId);
    const _models = models;
    _models[models.length-1].link = link
      // console.log("LINK  ",link)
    await updateDoc(docRef, {
      models: _models,
    });

        
    } catch (error) {
        console.log("ERROR(updateModelLink) ",error)
    }

}

export const uploadfile = async (file_name,file) => {

  return new Promise((resolve, reject) => {
    const storage = getStorage();
      const storageRef = sRef(storage,'data/Mauricio/'+ file_name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // console.log(progress);
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log(downloadURL)
          resolve( downloadURL);
        });
      }
    );
  })
   
      
  
}
export const getModels = async (userId) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then((doc)=> {
        resolve(doc.data().models)
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}
export const deleteModel = async (userId,models,user) => {
// console.log({
//   ...user,
//   models: models,
// })
  try {
    const docRef = doc(db, "customers", userId);
    await updateDoc(docRef, {
      ...user,
      models,
      hasUnfinishModel: false
    });
  } catch (error) {
      console.log("ERROR (deleteModel) ",error)
  }
   
      
  
}

export const updateCustomData = async (userId,models,user,customData,chatActive) => {
  // console.log({
  //   ...user,
  //   models: models,
  // })
  // console.log("userId",userId)
  // console.log("models",models)
  // console.log("user",user)
  // console.log("customData",customData)
  // console.log("chatActive",chatActive)
    try {

      const docRef = doc(db, "customers", userId);
      models[chatActive].customData = customData
      await updateDoc(docRef, {
        ...user,
        models,
      });
    } catch (error) {
        console.log("ERROR (updateCustomData) ",error)
    }
     
        
    
  }
  

  export const getCustomData = (userId,chatActive) => {
    return new Promise((resolve, reject) => {

      const docRef = doc(db, "customers", userId);
        getDoc(docRef).then((doc)=> {
          resolve(doc.data().models[chatActive].customData)
        }).catch((e) => {
          reject(e)
        });
    
    })
  }


  export async function  getProductByid(id){
    const cRef = collection(db,"products");
    const dRef = doc(cRef,id)
    const snapDoc = await getDoc(dRef)
    const product = snapDoc.data();
    return product;

  }

  export async function createCheckoutSession(uid,priceId,credits){
    const cRef = collection(db,"customers/"+uid+"/checkout_sessions");
    const {id} = await addDoc(cRef,{
      mode:"payment",
      success_url: window.location.origin+"?session_id={CHECKOUT_SESSION_ID}",
      cancel_url:  window.location.origin+"/payments",
      credits: credits,
      added_credits: false,
      line_items:[
        {quantity: 1,price: priceId}

      ]
    })

    const cancel = onSnapshot(doc(db,"customers/"+uid+"/checkout_sessions/"+id),
    (sn) => {
      const url = sn.data().url;
      if (url ){
        cancel();
        window.location.href = url;
      }
    }
    
    
    )

  }

  export  async function getActiveProducts () {
    const cRef = collection(db,"products")
    const filter = query(cRef,where('active',"==",true))
    const snaps = await getDocs(filter);
    const products = [];
    for await (const snap of snaps.docs){
        const product = snap.data();
        product.id = snap.id;
        const sPrice = await getDocs(collection(snap.ref,"prices"))
        var maxPrice = 0
        var maxPriceData = []
        var maxPriceId = 0
        for (let i in sPrice.docs){
          if (i == 0 ){
            maxPrice = sPrice.docs[i].data().unit_amount
            maxPriceId = sPrice.docs[i].id
            maxPriceData = sPrice.docs[i].data()
          }else{
            if (maxPrice < sPrice.docs[i].data().unit_amount ){
              maxPrice = sPrice.docs[i].data().unit_amount
              maxPriceId = sPrice.docs[i].id
              maxPriceData = sPrice.docs[i].data()
            }
          }
        }
        product.price = maxPriceData
        product.priceId = maxPriceId
        product.credits = maxPriceData.transform_quantity.divide_by
        product.type = maxPriceData.type
        products.push(product)
    }
    products.sort((a, b) => a.credits - b.credits);
    return products;
}

//USER CREDITS MANIPULATION///////////////////////////////////////////////////////////
export const addCredits = async (userId,buyedCredits) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        if(doc.data().credits<0){
          var _credits = buyedCredits
        }else{
          var _credits = doc.data().credits + buyedCredits
        }
        
        //console.log(_credits)
        await updateDoc(docRef, {
          credits: _credits
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}

export const addCreditsSubscription = async (userId,buyedCredits) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        if(doc.data().credits<0){
          var _credits = buyedCredits
        }else{
          var _credits =  buyedCredits
        }
        //console.log(_credits)
        await updateDoc(docRef, {
          credits: _credits
        });
      }).catch((e) => {
        reject(e)
      });
  })
}


export const discountCredits = async (userId,modelName) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        if(modelName == "GPT3.5"){
          var _credits = doc.data().credits - 1
        }
        if(modelName == "GPT4"){
          var _credits = doc.data().credits - 10
        }
        
        //console.log(_credits)
        await updateDoc(docRef, {
          credits: _credits
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}

export const newDiscountCredits = async (userId,creditsUsed) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        var _credits = doc.data().credits - creditsUsed
        //console.log(_credits)
        await updateDoc(docRef, {
          credits: _credits
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}


export const getUserCredits = async (userId) => {

  return new Promise((resolve, reject) => {
    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().credits)
        console.log(doc.data().credits)
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}

//USER CREDITS MANIPULATION///////////////////////////////////////////////////////////

export  async function updateCheckoutSession (userId,sessionId) {
  console.log(userId,sessionId)
  const cRef = collection(db,"customers/"+userId+"/checkout_sessions");
  const filter = query(
    cRef,
    where('sessionId', '==', sessionId),
    where('added_credits', '==', false)
  );
  const snap = await getDocs(filter);
  var checkout_session
  if(snap.docs[0].data()){
    checkout_session = snap.docs[0].data()
    console.log(checkout_session)
    const credits = checkout_session.credits
    if(checkout_session.mode=="subscription"){
      await addCreditsSubscription(userId,credits)
      await addModelsSubscription(userId,25)
      new Promise((resolve, reject) => {
        const docRef = doc(db, "customers", userId);
          getDoc(docRef).then(async(doc)=> {
            resolve(doc.data().credits)
            //console.log(_credits)
            await updateDoc(docRef, {
              last_checkout_session_id: sessionId
            });
          }).catch((e) => {
            reject(e)
          });
      })
    }else{
      await addCredits(userId,credits)
      await addModels(userId,10)
      const querySnapshot = await getDocs(
        query(
          collection(db, "customers", userId, "checkout_sessions"),
          where("sessionId", "==", sessionId)
        )
      );
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        try {
          await updateDoc(docRef, { added_credits: true });
          console.log('Checkout session updated successfully.');
        } catch (error) {
          console.error('Error updating checkout session:', error);
        }
      } else {
        console.log('No matching checkout session found.');
      }
    }
    
  }
}

export async function createSubscriptionCheckoutSession(uid,priceId,credits,trakdesk_cid){
  var currentDate = new Date();
  // Get the current month, day, and year
  var month = currentDate.getMonth()+1;
  var day = currentDate.getDate();
  var year = currentDate.getFullYear();
  // Increment the month by 1 to get the next month
  month = month + 1;
  // If the incremented month exceeds 11 (December), adjust the year accordingly
  if (month > 12) {
    month = 1; // Reset month to January
    year = year + 1; // Increment the year
  }
  // Set the day to 1 temporarily to avoid any overflow issues
  currentDate.setDate(1);
  // Set the month and year to get the last day of the current month
  currentDate.setMonth(month);
  currentDate.setFullYear(year);
  // Get the last day of the current month
  var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() , 0).getDate();
  // If the current day is greater than the last day of the current month, set it to the last day
  if (day > lastDay) {
    day = lastDay;
  }
  const cRef = collection(db,"customers/"+uid+"/checkout_sessions");
  const {id} = await addDoc(cRef,{
    price: priceId,
    success_url: window.location.origin+"?session_id={CHECKOUT_SESSION_ID}",
    cancel_url:  window.location.origin+"/pricing",
    client_reference_id: trakdesk_cid,
    credits: credits,
    added_credits: false,
    update_month: month,
    update_day: day,
    update_year: year
  });
  const cancel = onSnapshot(doc(db,"customers/"+uid+"/checkout_sessions/"+id),
  (sn) => {
    const url = sn.data().url;
    if (url ){
      cancel();
      window.location.href = url;
    }
  }
  )
}
export async function getUserActiveSubscription(uid){
  const cRef = collection(db,"customers/"+uid+"/subscriptions")
  const filter = query(cRef,where('status',"==","active"))
  const snaps = await getDocs(filter);
  const subscriptions = [];
  for await (const snap of snaps.docs){
      const subscription = snap.data();
      subscription.id = snap.id;
      subscription.product = snap.product
      //const sPrice = await getDocs(collection(snap.ref,"prices"))
      //product.price = sPrice.docs[0].data()
      //product.priceId = sPrice.docs[0].id
      //product.credits = sPrice.docs[0].data().transform_quantity.divide_by
      subscriptions.push(subscription)
  }
  console.log("subscriptions",subscriptions)
  return subscriptions;
}
export  async function updateSubscriptionCredits(userId) {
  const subscriptions = await getUserActiveSubscription(userId)
  var lastSessionId
  if(subscriptions.length>0){
    const price_id = subscriptions[0].price.id
    if(price_id === "price_1OCoXOIMXjbEWSV4j8DNuFyX"){
      new Promise((resolve, reject) => {
        const docRef = doc(db, "customers", userId);
          getDoc(docRef).then(async (doc)=> {
            resolve(doc.data().last_checkout_session_id)
            
            var updateDay = doc.data().app_sumo_sub_update_day
            var updateMonth = doc.data().app_sumo_sub_update_month
            var updateYear = doc.data().app_sumo_sub_update_year
            var updateDate = updateMonth + "-" + updateDay + "-" + updateYear
            var currentDate1 = new Date();
            var currentDate = (currentDate1.getMonth()+1) + "-" + currentDate1.getDate() + "-" + currentDate1.getFullYear()
            //currentDate = "2-1-2025"
            currentDate = new Date(currentDate);
            updateDate = new Date(updateDate);
            console.log(updateDate,currentDate)
            // Compare the dates using comparison operators
            if (currentDate > updateDate) {
              await addCreditsSubscription(userId,1000)
              //await addModelsSubscription(userId,25)
              updateMonth = updateMonth + 1;
              if (updateMonth > 12) {
                updateMonth = 1; // Reset month to January
                updateYear = updateYear + 1; // Increment the year
              }
              updateDate.setDate(1);
              updateDate.setMonth(updateMonth);
              updateDate.setFullYear(updateYear);
              console.log("lastday1",updateMonth,updateDay,updateYear)
              var lastDay = new Date(updateDate.getFullYear(), updateDate.getMonth() , 0).getDate();
              console.log("lastday",lastDay)
              if (updateDay > lastDay) {
                updateDay = lastDay;
              }
              console.log(updateMonth,updateDay,updateYear)
              await updateDoc(docRef, {
                app_sumo_sub_update_month: updateMonth,
                app_sumo_sub_update_day: updateDay,
                app_sumo_sub_update_year: updateYear
              });
              console.log("App Sumo Subscription Credits Updated");
            } else {
              // Perform the action when date1 is less than date2
              console.log("App Sumo Subscription Credits Not Updated");
            }
          }).catch((e) => {
            reject(e)
          });
      })
      
    }else{
      new Promise((resolve, reject) => {
        const docRef = doc(db, "customers", userId);
          getDoc(docRef).then(async (doc)=> {
            resolve(doc.data().last_checkout_session_id)
            lastSessionId = doc.data().last_checkout_session_id
            const cRef = collection(db,"customers/"+userId+"/checkout_sessions")
            const filter = query(cRef,where('sessionId',"==",lastSessionId))
            const snap = await getDocs(filter);
            const checkoutSession = snap.docs[0].data();
            const credits = checkoutSession.credits
            var updateDay = checkoutSession.update_day
            var updateMonth = checkoutSession.update_month
            var updateYear = checkoutSession.update_year
            var updateDate = updateMonth + "-" + updateDay + "-" + updateYear
            var currentDate1 = new Date();
            var currentDate = (currentDate1.getMonth()+1) + "-" + currentDate1.getDate() + "-" + currentDate1.getFullYear()
            //currentDate = "2-1-2025"
            currentDate = new Date(currentDate);
            updateDate = new Date(updateDate);
            console.log(updateDate,currentDate)
            // Compare the dates using comparison operators
            if (currentDate > updateDate && checkoutSession.added_credits == true) {
              await addCreditsSubscription(userId,credits)
              await addModelsSubscription(userId,25)
              updateMonth = updateMonth + 1;
              if (updateMonth > 12) {
                updateMonth = 1; // Reset month to January
                updateYear = updateYear + 1; // Increment the year
              }
              updateDate.setDate(1);
              updateDate.setMonth(updateMonth);
              updateDate.setFullYear(updateYear);
              console.log("lastday1",updateMonth,updateDay,updateYear)
              var lastDay = new Date(updateDate.getFullYear(), updateDate.getMonth() , 0).getDate();
              console.log("lastday",lastDay)
              if (updateDay > lastDay) {
                updateDay = lastDay;
              }
              console.log(updateMonth,updateDay,updateYear)
              await updateDoc(snap.docs[0].ref, {
                update_day: updateDay,
                update_month: updateMonth,
                update_year: updateYear
              });
              console.log("Subscription Credits Updated");
            } else {
              // Perform the action when date1 is less than date2
              console.log("Subscription Credits Not Updated");
            }
          }).catch((e) => {
            reject(e)
          });
      })
    }
  }
}

//USER MODELS CREATION REMAINING MANIPULARTION////////////////////////////////////////

export const discountModels = async (userId) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().remaining_models)
        var remaining_models = doc.data().remaining_models
        var _remaining_models = remaining_models - 1
        //console.log(_credits)
        await updateDoc(docRef, {
          remaining_models: _remaining_models
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}

export const addModels = async (userId,buyedModels) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().remaining_models)
        var remaining_models = doc.data().remaining_models
        var _remaining_models = remaining_models + buyedModels
        //console.log(_credits)
        await updateDoc(docRef, {
          remaining_models: _remaining_models
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}

export const addModelsSubscription = async (userId,buyedModels) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().remaining_models)
        var remaining_models = doc.data().remaining_models
        var _remaining_models = remaining_models + buyedModels
        //console.log(_credits)
        await updateDoc(docRef, {
          remaining_models: buyedModels
        });
        
      }).catch((e) => {
        reject(e)
      });
  
  })
       
  
}



export const getModelsRemaining = async (userId) => {

  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().remaining_models)
        console.log(doc.data().remaining_models)
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}
//USER MODELS CREATION REMAINING MANIPULARTION////////////////////////////////////////

//EXPORT CHATS//////////////////////////////////////////////////////////////////////////////////////
export const addExportChat = async (modelId,logoImage,contextUrl,chatTitle,personality,welcomeMessage,headerColor1,headerColor2,buttonText) => {
  try {
    const docRef = doc(db, "export_chats/"+modelId);
    getDoc(docRef)
      .then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          await updateDoc(docRef, {
            logoImage,
            contextUrl,
            chatTitle,
            personality,
            welcomeMessage,
            headerColor1,
            headerColor2,
            buttonText
          });
          return modelId;
        } else {
          await setDoc(doc(db,"export_chats/"+modelId), {
            logoImage,
            contextUrl,
            chatTitle,
            personality,
            welcomeMessage,
            headerColor1,
            headerColor2,
            buttonText,
            chats: []
          });
          return modelId;
        }
      })
      .catch(async (error) => {
        await setDoc(doc(db,"export_chats/"+modelId), {
          logoImage,
          contextUrl,
          chatTitle,
          personality,
          welcomeMessage,
          headerColor1,
          headerColor2,
          buttonText,
          chats: []
        });
        return modelId;
        console.error("Error getting document:", error);
      });
    } catch (error) {
        console.log("ERROR (addExportChat) ",error)
    }
}

export const addChatToExportChat = async (userId,modelId) => {
  try {
      const docRef = doc(db, "export_chats/"+modelId);
      const docSnap = await getDoc(docRef);
      const chats = docSnap.data().chats;
      //const uuid = userId+"-"+models.length;
      const _chats = chats;
      _chats.push({
          uuid: userId,
          chat: []
        });
      await updateDoc(docRef, {
        chats: _chats
      });
      
      return {
        userId,
        chat: []
      };
  } catch (error) {
      console.log("ERROR (addChatToExportChat) ",error)
  }

}

export const addChatMessageToExportChat = async (text, userId, modelId) => {
  try {
    let userChatId;
    console.log(text,userId,modelId)
    const docRef = doc(db, "export_chats/" +modelId);
    const docSnap = await getDoc(docRef);
    const chats = docSnap.data().chats;
    //const _chats = [...chats]; // create a copy of the array

    for (var chat in chats) {
      if (chats[chat].uuid === userId) {
        chats[chat].chat.push(text);
        await updateDoc(docRef, {
          chats: chats
        });
        //userChatId = chat.id;
        break;
      }
    }

  } catch (error) {
    console.log("ERROR (addChatMessageToExportChat)", error);
  }
};




export const removeChatMessageFromExportChat = async (modelId, userId) => {
  try {
    let userChatId;
    const docRef = doc(db, "export_chats/" + modelId);
    const docSnap = await getDoc(docRef);
    const chats = docSnap.data().chats;
    const _chats = [...chats]; // create a copy of the array

    for (const chat of _chats) {
      if (chat.userId === userId) {
        userChatId = chat.id;
        break;
      }
    }

    if (userChatId !== undefined) {
      _chats[userChatId].chat.pop();
      await updateDoc(docRef, {
        chats: _chats
      });
    } else {
      console.log("User chat not found");
    }
  } catch (error) {
    console.log("ERROR (removeChatFromExportChat)", error);
  }
};



export const getExportChats = async (modelId) => {
  try {
    

    let userChatId;
    const docRef = doc(db, "export_chats/"+ modelId);
    const docSnap = await getDoc(docRef);
    const export_chat = docSnap.data();
    

    return export_chat;
  } catch (error) {
    console.log("ERROR (getChatFromExportChats)", error);
  }
};

//EXPORT CHATS//////////////////////////////////////////////////////////////////////////////////////

export const validateUserKey = async (userId, key) => {
  try {
    const docRef = doc(db, "customers", userId);
    const docSnap = await getDoc(docRef);
    const _accessKey = docSnap.data().accessToken;
    const newArray = _accessKey.split('.');
    const _accessKeyFirstPart = newArray[0];
    const valid = _accessKeyFirstPart === key;
    console.log("yes", _accessKeyFirstPart, key);
    return valid;
  } catch (error) {
    throw error;
  }
};


//Analytics

export const getExportChatsByUserId = async (userId) => {
  const exportChatsRef = collection(db, "export_chats");

  const querySnapshot = await getDocs(exportChatsRef);
  const exportChatsList = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.contextUrl && data.contextUrl.includes(userId)) {
      data.id = doc.id; // If you want to keep the document ID
      exportChatsList.push(data);
    }
  });

  return exportChatsList;
}


export const setUpdateDateAppSumoSubscription = async (userId) => {
  
  return new Promise((resolve, reject) => {

    const docRef = doc(db, "customers", userId);
      getDoc(docRef).then(async(doc)=> {
        resolve(doc.data().app_sumo_sub_update_month)
        if (!doc.data().app_sumo_sub_update_month) {
          var currentDate = new Date();
          // Get the current month, day, and year
          var month = currentDate.getMonth()+1;
          var day = currentDate.getDate();
          var year = currentDate.getFullYear();
          // Increment the month by 1 to get the next month
          month = month + 1;
          // If the incremented month exceeds 11 (December), adjust the year accordingly
          if (month > 12) {
            month = 1; // Reset month to January
            year = year + 1; // Increment the year
          }
          // Set the day to 1 temporarily to avoid any overflow issues
          currentDate.setDate(1);
          // Set the month and year to get the last day of the current month
          currentDate.setMonth(month);
          currentDate.setFullYear(year);
          // Get the last day of the current month
          var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() , 0).getDate();
          // If the current day is greater than the last day of the current month, set it to the last day
          if (day > lastDay) {
            day = lastDay;
          }
          
          //console.log(_credits)
          await updateDoc(docRef, {
            app_sumo_sub_update_month: month,
            app_sumo_sub_update_day: day,
            app_sumo_sub_update_year: year
          });
          await addCreditsSubscription(userId,1000)
        }
        
      }).catch((e) => {
        reject(e)
      });
  
  })
   
      
  
}