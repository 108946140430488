import React from "react";
import "./styles.css";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createCheckoutSession,getActiveProducts,createSubscriptionCheckoutSession } from '../../APIFirebase';
import { createStripeSubscriptionAppSumoCoupon,checkCuponExists } from '../../API';
import { ReactComponent as FreePlan } from '../../Assets/Pricing/pricing-free-plan.svg';
import { ReactComponent as LaunchAIArrow } from '../../Assets/Pricing/launch-ai-arrow.svg';
import { ReactComponent as MonthlyPricing } from '../../Assets/Pricing/pricing-monthly.svg';
import globe from '../../Assets/SignIn/worldwide.png';
import growth from '../../Assets/SignIn/growth.png';
import smartphone from '../../Assets/SignIn/smartphone.png';
import { useAuth } from '../../utils';
import Loading from "../Loading";


export const PricingPanelEdited = ({buy,products}) => {
  const [sliderValue, setSliderValue] = useState(1000);  // initial value
  const [price, setprice] = useState(0.00);
  const [selected, setSelected] = useState(0);
  const [messageRate, setmessageRate] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [trakdesk_cid, setTrakDeskCid] = useState(null);
  const {user} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Function to get a cookie by name
    const getCookie = (name) => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };

    // Example: Get the value of a cookie named "myCookie"
    const myCookieValue = JSON.parse(getCookie('trakdesk_cid'));

    if (myCookieValue) {
      const _trakdeskCid = myCookieValue['cid']
      if(_trakdeskCid){
        setTrakDeskCid(_trakdeskCid)
        console.log('Value of trakdesk_cid:', _trakdeskCid);
      }
    } else {
      console.log('trakdesk_cid not found');
    }
  }, []);

  useEffect(() => {
    switch(sliderValue) {
      case 1000:
        setSelected(0);
        setprice(10);
        setmessageRate(0.01);
        break;
      case 5000:
        setSelected(10);
        setprice(25);
        setmessageRate(0.005);
        break;
      case 10000:
        setSelected(1);
        setprice(45);
        setmessageRate(0.0045);
        break;
      case 15000:
        setSelected(2);
        setprice(65);
        setmessageRate(0.00433);
        break;
      case 20000:
        setSelected(3);
        setprice(85);
        setmessageRate(0.00425);
        break;
      case 25000:
        setSelected(4);
        setprice(105);
        setmessageRate(0.00420);
        break;
      case 30000:
        setSelected(5);
        setprice(120);
        setmessageRate(0.00400);
        break;
      case 35000:
        setSelected(6);
        setprice(130);
        setmessageRate(0.00371);
        break;
      case 40000:
        setSelected(7);
        setprice(135);
        setmessageRate(0.00338);
        break;
      case 45000:
        setSelected(8);
        setprice(140);
        setmessageRate(0.00311);
        break;
      case 50000:
        setSelected(9);
        setprice(145);
        setmessageRate(0.00290);
        break;
      default:
        setSelected(0);
        setprice(0);
        setmessageRate(0.00);
    }
  }, [sliderValue]);

  /*useEffect(() => {
    console.log(sliderValue);
    if(sliderValue>50){
        setprice(sliderValue/1000);
    }
    else{
        setprice(0.00);
    }
    
  }, [sliderValue]);*/

  const redeemCode = async () => {
    Swal.fire({
      title: "Submit your promo code",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Redeem",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const res1 = await checkCuponExists(login);
          if(res1.data.status !== true){
            Swal.showValidationMessage(`
            Request failed: Used coupon
          `);
            return
          }
          console.log("datos enviados", user.docId , login);
          const res = await createStripeSubscriptionAppSumoCoupon(user.docId,login);
          console.log("el subscription id",res.data.subscriptionId);
          navigate(`/dashboard?app_sumo_sub_id=${res.data.subscriptionId}`);
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: Invalid coupon or error on the server
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon:'success',
          title: `Succesfully Subscribed!`
        });
      }
    });
  }

  const toBuy = async () => {
    try {
        console.log(" ",products[selected].priceId);
        if(products[selected].type=="one_time"){
          console.log("credits")
          await createCheckoutSession(user.docId,products[selected].priceId,products[selected].credits,trakdesk_cid);
        }else if(products[selected].type=="recurring"){
          console.log("subscription")
          await createSubscriptionCheckoutSession(user.docId,products[selected].priceId,products[selected].credits,trakdesk_cid);
        }
        
    } catch (error) {
      console.log("Err ",error)      
    }
  }
  

  const handleSliderChange = (event) => {
    // Since we want specific values, we will adjust the incoming event value.
    const val = parseInt(event.target.value);
    if(val <= 1000) {
      setSliderValue(1000);
    } else {
      setSliderValue(Math.ceil((val - 1000) / 5000) * 5000);
    }
  }
  return (
    <div>
    <div className="content">
        <div className="text-content">
            <div className="ultimate-no-code">
                <div className="hero-text">
                    <div className="ai-container">
                        <span className="ai-assistant-can">Pay as you go, </span>
                        <span className="ai-assistant-can"> No upfront commitment</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="360" height="14" viewBox="0 0 360 14" fill="none">
                        <path d="M0.500061 11C83.7501 4.96301 296.5 -3.48877 481.5 11" stroke="url(#paint0_angular_407_183)" stroke-width="5"/>
                        <defs>
                            <radialGradient id="paint0_angular_407_183" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(241 7) rotate(90) scale(4 240.5)">
                            <stop offset="0.0993526" stop-color="#FFC876"/>
                            <stop offset="0.4375" stop-color="#ACFF8E"/>
                            <stop offset="0.739587" stop-color="#9453FF"/>
                            <stop offset="0.913343" stop-color="#FF89C2"/>
                            </radialGradient>
                        </defs>
                        </svg>
                </div>
                <span className="no-code-text">The latest in generative AI with a transparent pricing model that scales with your needs.</span>
            </div>
        </div>
    </div>
    <div className="pricing-container">
            <div className="pricing-content">
                <div className="pricing-items1">
                    <div className="first-item-container">
                        <div className="first-item-content">
                            <div className="pricing-free-plan-content">
                                <div className="free-plan-description">
                                    <div className="free-plan-title">
                                        <FreePlan />
                                        <span className="title-text">Free plan</span>
                                    </div>
                                    <span className="description-text">Explore the power of Jigma's AI Workforce at no cost.</span>
                                </div>
                                <div className="messages-per-month">
                                    <div>
                                    <span className="number-messages">50</span><span className="incomming-messages">/Credits</span>
                                    </div>
                                    <span className="per-month">Per month</span>
                                    <span className="second-item-description">Unused credits expire at the end of the month</span>
                                </div>
                            </div>
                            <div className="redeem-code-container">
                            <button className="pricing-get-started-button" onClick={() => navigate('/signup')}>
                                <div>
                                    <span className="pricing-started-button-text">Start Free Trial</span>
                                </div>
                            </button> 
                            <button className="pricing-get-started-button" onClick={() => {if (user.docId) {redeemCode();} else {Swal.fire({
                              icon: 'warning',
                              title: 'You are not loged in',
                              text: 'Create an account first to redeem a code',
                              footer: '<a href="/signup">Create Account</a>'
                            })}}}>
                                <div>
                                    <span className="pricing-started-button-text">Redeem Code</span>
                                </div>
                            </button> 
                            </div>
                        </div>
                    </div>
                    <div className="second-item-container">
                        <div className="second-item-content">
                            <span className="second-item-title">Looking for Custom Capabilities?</span>
                            <span className="second-item-description">Custom solutions and dedicated support to drive innovation at scale. Don't settle—let's co-create the perfect solution together.</span>
                            <button className="pricing-get-in-touch" onClick={() => navigate('/contact-us')}>
                                <span className="pricing-get-in-touch-text">🌟 Request A Demo 🌟</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="pricing-items2">
                    <div className="pricing-items2-content">
                        <div className="pricing-calculator">
                            <div className="free-plan-description">
                                <div className="monthly-pricing-title">
                                    <MonthlyPricing />
                                    <span className="title-text">Monthly pricing</span>
                                </div>
                                <span className="description-text">Adjust your plan according to your needs, anytime.</span>
                            </div>
                            <div className="messages-per-month">
                                <div>
                                    <span className="number-messages">${price}</span><span className="incomming-messages">/Estimated (USD)</span>
                                </div>
                                <span className="per-month">Per month</span>
                            </div>
                            <div className="slider-values">
                                <div className="text">1k</div>
                                <div className="text">10k</div>
                                <div className="text">20k</div>
                                <div className="text">30k</div>
                                <div className="text">40k</div>
                                <div className="text">50k</div>
                            </div>
                            <div className="slider-container">
                                <input 
                                    type="range" 
                                    min="1000" 
                                    max="50000" 
                                    step="5000"
                                    value={sliderValue} 
                                    onChange={handleSliderChange}
                                    className="pricing-slider" 
                                    id="myRange" 
                                />
                            </div>
                        </div>
                        <div className="pricing-details">
                            <div className="pricing-additional-messages">
                                <span className="text1">Credits</span>
                                <span className="text2">{sliderValue}</span>
                            </div>
                            <div className="pricing-jigma-messages">
                                <span className="text1">Avg message rate</span>
                                <span className="text2">${messageRate}</span>
                            </div>
                        </div>
                        

                    </div>
                    <div className="pricing-items2-features">
                        <div className="feature1">
                            <img src={globe} alt={`image-2`} id={2} style={{width:"24px",height:"24px"}}/>
                            <span className="feature1-text">Embed your chatbot directly on your website</span>
                        </div>
                        <div className="feature1">
                            <img src={growth} alt={`image-3`} id={3} style={{width:"24px",height:"24px"}}/>
                            <span className="feature1-text">Analyze user behavior</span>
                        </div>
                        <div className="feature1">
                          <img src={smartphone} alt={`image-4`} id={4} style={{width:"24px",height:"24px"}}/>
                          <span className="feature1-text">Enable human-AI collaboration on iOS and Android</span>
                        </div>
                    </div>
                    {
                      user.email ? (
                        
                          !isLoading ? (
                            <button onClick={() => {toBuy(); setisLoading(true);}} className="pricing-get-started-button">
                              <span className="pricing-started-button-text">Pay</span>
                            </button> 
                          ):(
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf:'center'}}><Loading /></div>
                          )               
                      ):(
                      <button onClick={() => {navigate('/signup');}} className="pricing-get-started-button">
                        <span className="pricing-started-button-text">Get Started</span>
                      </button> 
                      )
                    }
                </div>

            </div>
        </div>
    </div>
  );
};

export default PricingPanelEdited;
