import {  useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkStatus, getAnswer } from '../../API';
import { addChatToUser,deleteModel, updateModelLink, getModels, removeLastChatOfUser, discountCredits, getUserCredits, addJigmaModelToUser } from '../../APIFirebase';
import Header from '../../components/Header';
import ItemChat from '../../components/ItemChat';
import ItemChatModel from '../../components/ItemChatModel';
import Loading from '../../components/Loading';
import { getDate, useAuth } from '../../utils';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import './styles.css';
import Modal from 'react-awesome-modal';
import InstallButton from '../../components/InstallButton';
import SideBar from '../../components/SideBar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from "react-dropdown-select";
import 'react-perfect-scrollbar/dist/css/styles.css';

function Chat() {
  const {user,setUser} = useAuth();
  const [chatActive,_chatActive] = useState(0)
  const [count, setCount] = useState(0);
  const [showModal, _showModal] = useState(false);
  const [modalAlreadyShowed, _modalAlreadyShowed] = useState(false);
  const [msg, _msg] = useState("");
  const [isLoadingModels, setLoadingModels] = useState(1);
  const [isLoading, setLoading] = useState(1);
  const [countChat, setCountChat] = useState(0);
  const [existPending, _existPending] = useState(false);
  const [models, setModels] = useState([]);
  const [isFirstTime, _isFirstTime] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showSideBar, setshowSideBar] = useState(true);
  const [credits, setCredits] = useState(null);
  const [jigmaBotCreated, setjigmaBotCreated] = useState(false);
  const navigate = useNavigate();
  const chatWindowRef = useRef(null);
  const ps = useRef();
  const [selectedOption, setSelectedOption] = useState([{ value: 'GPT3.5', label: 'GPT3.5 (1 credit)' }]);

  const handleOptionChange = (selected) => {
    setSelectedOption(selected);
  };

  const options = [
    { value: 'GPT3.5', label: 'GPT3.5 (1 credit)' },
    { value: 'GPT4', label: 'GPT4 (10 credits)' },
  ];


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
      setshowSideBar(true);
    } else {
      setIsMobile(false);
      setshowSideBar(true);
    }
  };

  useEffect(() => {
    console.log("Mobile:", isMobile);
    console.log("SideBar:", showSideBar);
  }, [isMobile, showSideBar]);

  useEffect(() => {
    handleResize();
  }, []);


  const { transcript, listening } = useSpeechRecognition();

  const addQuestion = async () => {
    const coins = await getUserCredits(user.docId);
    //console.log(credits)
    if (isLoading)
      return;
    if (msg === ""){
      alert("The message is empty");
    }else if( models.length === 0 ){
      alert("You need to add a new model first");
    }else if( credits <= 0 ){
      alert("You have no credits. Buy more at the Get More Credits section.");
    }else if(models[chatActive].link === null && existPending){
      alert("This model is training yet, you need to wait for it to finish")
    }else if(models[chatActive].link === null){
      alert("You do not have a file for this model, remove the chat and try again")
    }else if(models[chatActive].chat.length > 100){
      alert("sorry, you already reached 100 messages")
    }else if(!navigator.onLine){
      alert("Check your internet conection")
    }
    else {
      setLoading(true)  
      // Create a Date object using the current timestamp
      const dateObject = new Date();

      // Function to get the UTC offset string (e.g., UTC-3)
      function getUTCOffsetString(date) {
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes < 0 ? '+' : '-';
        return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
      }

      // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
      const formattedDate = `${dateObject.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      })} ${getUTCOffsetString(dateObject)}`;

      const aux = {
        title: msg,
        date: formattedDate
      };
      await addChatToUser(aux,user.docId,models,chatActive)
      _msg("")
      setCountChat((x) => x+1)
      setCredits(coins);
    }
  }
  const intervalIdRef = useRef(null);

useEffect(() => {
  async function renderJigmaChatbot(){
    setLoadingModels(true);
    const res1 = await getModels(user.docId);
    setModels(res1);
    setLoadingModels(false);
  }
  renderJigmaChatbot();
}, [jigmaBotCreated]);

  

useEffect(() => {
  async function fetchData() {
    console.log("Getting models...");
    const res = await getModels(user.docId);
    const coins = await getUserCredits(user.docId);
    setModels(res);
    setCredits(coins);
    if (res.length === 0){
      const res1 = await addJigmaModelToUser("Jigma Assistant","A guide to your first steps",user.docId,"You are an assistant in an app called Jigma, you are inside the app, so your goal is to attend the users requests regarding the usage of jigma. You will use the information provided to you to assist the users.","context/TG2IODC9NvbPiQqMrfdgVsXKuCq2/data_context_Jigma Assistant.csv");
      setjigmaBotCreated(true);
    }
    setLoadingModels(false);
    console.log("Models updated:", res);
    console.log("Checking status...");
  }

  fetchData();
}, []);

useEffect(() => {
  const lastModel = models[models.length - 1];
  if (lastModel?.link !== null){
    return () => clearInterval(intervalIdRef.current);
  }
  if (lastModel?.link === null) {
    _existPending(true)
      if (!modalAlreadyShowed){
        _showModal(true)
      }
    }
  intervalIdRef.current = setInterval(async () => {
    
    
    if (lastModel?.link === null) {
      
      console.log("modelos: ",models);
      const res = await checkStatus(lastModel.uuid,lastModel.title);
      if (res.data.state) {
        clearInterval(intervalIdRef.current);
        await updateModelLink(user.docId, models, res.data.model_url);
        console.log("Model link updated:", res.data.model_url);
        const _models = models;
        // console.log("MODEL TO UPDATE ",models)
        _models[_models.length-1].link = res.data.model_url
        setUser({
          ...user,
          hasUnfinishModel: false
        })
        _existPending(false);
        changeActive(models.length-1);
      }
    }
  }, 5000);

  return () => clearInterval(intervalIdRef.current);
}, [models]);

/* Previous ScrollDown
useEffect(() => { //Scroll to the bottom when new message is send
    
  if (chatWindowRef.current) {
    const curr = ps.current
  if (curr) {
    curr.scrollTop = 80000;
  } 
  }
}, [countChat,isLoading]);
  */
  useEffect(() => { //Scroll to the bottom when new message is send
    
    if (chatWindowRef.current) {
      const curr = ps.current;
      if (curr) {
        const scrollHeight = curr.scrollHeight;
        const start = curr.scrollTop;
        const end = scrollHeight - curr.clientHeight;
        const duration = 500; // Adjust this value to control the scrolling speed

        let startTime = null;

        const scrollAnimation = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const elapsed = timestamp - startTime;
          const scrollValue = easeInOutQuad(elapsed, start, end - start, duration);
          curr.scrollTop = scrollValue;
          
          if (elapsed < duration) {
            requestAnimationFrame(scrollAnimation);
          }
        };

        const easeInOutQuad = (t, b, c, d) => {
          t /= d / 2;
          if (t < 1) return c / 2 * t * t + b;
          t--;
          return -c / 2 * (t * (t - 2) - 1) + b;
        };

        requestAnimationFrame(scrollAnimation);
      }
    }
  }, [countChat,isLoading]);

  useEffect(() => {
  setTimeout(() => {
    if (chatWindowRef.current) {
      const curr = ps.current;
      if (curr) {
        const scrollHeight = curr.scrollHeight;
        const start = curr.scrollTop;
        const end = scrollHeight - curr.clientHeight;
        const duration = 500; // Adjust this value to control the scrolling speed

        let startTime = null;

        const scrollAnimation = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const elapsed = timestamp - startTime;
          const scrollValue = easeInOutQuad(elapsed, start, end - start, duration);
          curr.scrollTop = scrollValue;
          
          if (elapsed < duration) {
            requestAnimationFrame(scrollAnimation);
          }
        };

        const easeInOutQuad = (t, b, c, d) => {
          t /= d / 2;
          if (t < 1) return c / 2 * t * t + b;
          t--;
          return -c / 2 * (t * (t - 2) - 1) + b;
        };

        requestAnimationFrame(scrollAnimation);
      }
    }
  }, 500);
}, []);


  /*useEffect(() => { //Scroll to the bottom when new message is send
    
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTo({
        top: chatWindowRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [countChat]);*/


  //////////////////////////////////////////////////////////////////////////


  useEffect(() => { //sending a question to get a answer
    async function fetchData(){
      try {
        const context_url = models[chatActive].link;
        var personality = models[chatActive].personality;
        let body = null
        const length_chats = models[chatActive].chat.length
        if (length_chats>3){
          const questions  = models[chatActive].chat.slice(length_chats-5,length_chats-1).filter((e,i) => i%2 === 0).map((x) => x.title);
          const answers  = models[chatActive].chat.slice(length_chats-4,length_chats).filter((e,i) => i%2 === 0).map((x) => x.title);
          body = {
            questions,answers
          }
        var response
        }
        var modelOpenAI = selectedOption[0].value
        if (personality == null){
          personality = "an assistant"
          response = await getAnswer(context_url,personality,models[chatActive].chat[models[chatActive].chat.length-1].title,body,modelOpenAI)
        }else{
          response = await getAnswer(context_url,personality,models[chatActive].chat[models[chatActive].chat.length-1].title,body,modelOpenAI)
        }
        if (response.data){
          // Create a Date object using the current timestamp
          const dateObject = new Date();

          // Function to get the UTC offset string (e.g., UTC-3)
          function getUTCOffsetString(date) {
            const offsetInMinutes = date.getTimezoneOffset();
            const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
            const offsetMinutes = Math.abs(offsetInMinutes) % 60;
            const sign = offsetInMinutes < 0 ? '+' : '-';
            return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
          }

          // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
          const formattedDate = `${dateObject.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
          })} ${getUTCOffsetString(dateObject)}`;

          //const modifiedResponse = convertMarkdownLinksToHtml(response.data.answer);

          const aux = {
            title: response.data.answer,
            date: formattedDate
          };
          // console.log("ANSSS ",response.data)
          await addChatToUser(aux,user.docId,models,chatActive)
          // addToChat(aux,chatActive)
        }
        await discountCredits(user.docId,modelOpenAI);
        const coins = await getUserCredits(user.docId);
        setCredits(coins);
        setLoading(false)
      } catch (error) {
        // console.log("ERROR ",error)
        removeLastChatOfUser(user.docId,models,chatActive)
        setLoading(false)
        if (error?.response?.status == 429){
          alert(error.response.data.message+"("+error.response.data.seconds_remaining+" seconds)")
        }else alert(error.message)
      }
    }
    if (isLoading){
      if (!isFirstTime && models[chatActive].chat.length>0 && models[chatActive].chat.length%2 === 1 )
        fetchData();
      else {
        _isFirstTime(false)
        setLoading(false)
      }
    }
  },[countChat])

  useEffect(() => {//Set the text of the transcription to the input
    _msg(transcript)
  }, [transcript])

  const handleListen = () => {//Starts speech recognition while talking
    if (!listening) {
      SpeechRecognition.startListening();
    } else {
      SpeechRecognition.stopListening();

    }
  };

  const changeActive = (idx) =>  {
    _chatActive(idx)
    if(isMobile)
    {
      chatRef.current.classList.toggle('d-none');
      setshowSideBar(false);
    }
  }


  const _deleteModel = (idx) => {
    
    if (window.confirm('Are you sure you wish to delete this model?')) {
      const _models = models;
      if (_models.length-1 === idx){
        _existPending(false)
      }
      if (idx>0){
        _chatActive(idx-1)
      }else {
        _chatActive(null)
      }
      
      _models.splice(idx,1)
      setModels(_models)
      setUser({
        ...user,
        hasUnfinishModel: false
      })
      deleteModel(user.docId,models,user)

    } 
  }
  
  const toggleRef = useRef(null);
  const modelMenuRef = useRef(null);
  const chatRef = useRef(null);
  const navBarRef = useRef(null);
  

  function toggleElements() {
    const modelMenuShown = !modelMenuRef.current.classList.contains('show');
    //modelMenuRef.current.classList.toggle('show');
    setshowSideBar(true);
    navBarRef.current.classList.toggle('w-100', !modelMenuShown);
    //navBarRef.current.classList.toggle('d-none');
    console.log(showSideBar);
    //console.log(modelMenuShown);
    chatRef.current.classList.toggle('d-none');
  }
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }
  return (
    <div className="app-web">
        <SideBar isSidebarOpen={isSidebarOpen} isMobile={isMobile}/>
        <div className="content-static"> 
        <Header title="Chat" onToggleSidebar={toggleSidebar} back_active={0} gym_active={0} edit_active={0} profile_active={1}  credits={credits} />
        <div className='content-dynamic'>
        <nav className={`navbar navbar-expand-lg model-nav-bar navbar-dark ${showSideBar ? '' : 'd-none'}`} ref={navBarRef}>
        <aside className='sidemenu navbar-collapse flex-column' id='modelMenu' ref={modelMenuRef}>
        <div className='p-2 w-100 model-content'>
          <div className='text-center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
         {
          existPending ?
            <Loading />
          :
            <button className="circle-button button-margin" onClick={() => {_modalAlreadyShowed(false);navigate('/pre-gym')}}>
              <i className="bx bxs-user-plus add-model-icon-chat"></i><span className='create-text'> Create Assistant</span>
            </button>
            
            }
         </div>
        </div>
            <h4 className='subtitle-models text-left w-100'>Your Models</h4>
            <div className='col model-container'>
            {
            isLoadingModels ?
            (
                <div style={{color: 'white', marginTop: '20px'}} >Loading models..</div>
            )
            :
            (
              models.length === 0 ?
              (
                <div style={{color: 'white', marginTop: '20px'}} >You don't have trained models yet</div>

              ) 
              :
              (
                models?.map((e,i) => (
                  <ItemChat deleteItem={() => {_deleteModel(i)}}  is_active={i===chatActive} onclick={() => {changeActive(i)}} link={e.link} key1={i} title={e.title} subtitle={e.description} uuid={e.uuid} />
               ))
              ) 
            )
            }
            </div>
            
            <div className='install-button'>
            <InstallButton />
            </div>
        </aside>
        </nav>
        <Modal className="modal_chat" visible={showModal} effect="fadeInUp" onClickAway={() => _showModal(false)}>
            <div className='row modal-color text-center' >
              <div className='col-12 pt-3' >
                <h3 className='title_modal' >Training in Progress!</h3>
              </div>
              <div className='col-12 mt-5' >
                <p className='content_modal' > The training wheels are on! You'll find it in your model list once it's ready.</p>
              </div>
              <div className='col-12 pt-5 ' >
                <a className="btn btn-block modal-ok-button py-3 px-3 mb-4 shadow-sm rounded-pill" href="javascript:void(0);" onClick={() => {_showModal(false);_modalAlreadyShowed(true)}}>Ok</a>
              </div>
            </div>
        </Modal>
        <section className={`chatbox row navbar-window show ${isMobile ? 'd-none' : ''} `} id='chat' ref={chatRef}>
        <div className='model-selector'>
        <span>Model:</span> 
            <Select className='model-dropdown' options={options} defaultValue={{ value: 'GPT3.5', label: 'GPT3.5 (1 credit)' }} placeholder={options[0].label} onChange={handleOptionChange}  />
            </div>
            <div>
            
            {
              
              isMobile &&
              <button className="navbar-toggler mb-2 ml-2 toggle-button navbar-button float-left" type="button" data-toggle="collapse" data-target="#modelMenu, #chat" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" ref={toggleRef} onClick={ toggleElements}>
                <i class='bx bx-arrow-back toggle-icon float-left'></i>
              </button>
                  }
            
               {
             
             models[chatActive]?.chat?.length>0 ?
             (
                <PerfectScrollbar style={{ height: isMobile ? '75svh' : '73svh', marginTop: '5px'}} containerRef={el => (ps.current = el)} >
                  
                <div className='chat-window' ref={chatWindowRef}>
                     {
                       models[chatActive]?.chat.map((e,i) => {
                         return (
                           <ItemChatModel key={i} is_ans={i%2 !== 0} {...e} />
                         )
                       })
                     }
                     {
                      isLoading &&
                      <div className='text-center mb-4' ><div class="dot-flashing"></div></div>
                     }
                     
                </div>
                </PerfectScrollbar>
            
             )
             :
             (
                 <div className='container my-5' >
                   <div className='row justify-content-center'  >
                     <div className='col-11  ' >
                       <div className='row text-center mb-5 center-wrapper' >
                         
                         
                         <div className='col-12' >
                           <div className='title_card mt-2' >
                             How to start?
                           </div>
                         </div>
                         <div className='col-12' >
                           <div className='sub_title_card mt-2' >
                             1. Click the create button at the top
                           </div>
                           <div className='sub_title_card mt-2' >
                             2. Name your assistant and give it a personality
                           </div>
                           <div className='sub_title_card mt-2' >
                             3. Add the files you’d like to train your assistant on
                           </div>
                           <div className='sub_title_card mt-2' >
                             4. Click RUN to train your assistant
                           </div>
                           <div className='sub_title_card mt-2' >
                             5. You are ready to start chatting!
                           </div>
                         </div>
                         {
                          isMobile && (
                            <button className="circle-button mt-5" onClick={() => {navigate('/pre-gym')}}>
                            <i className="bx bxs-user-plus add-model-icon-chat"></i><span className='create-text'> Create Assistant</span>
                           </button>
                          )
                         }                       
                       </div>
                     </div>
                   </div>
                 </div>
             )
             
           }
               
                  <div className= {isSidebarOpen ? `chat-input-holder` : `chat-input-holder-reduced` }>
                  <textarea 
                    disabled={isLoading} 
                    placeholder='Chat with your AI!' 
                    name="msg" 
                    value={msg} 
                    onChange={(value) => { 
                      _msg(value.target.value)
                      value.target.style.height = 'auto';
                      value.target.style.height = value.target.scrollHeight + 'px';
                    }} 
                    onKeyDown={(event) => {
                    if (event.keyCode === 13 && !isLoading) {
                      event.preventDefault();
                      addQuestion();
                    }
                    }} 
                    onKeyUp={(event) => {
                      if (event.keyCode === 13 && !isLoading) {
                        event.target.style.height = 'auto';
                        event.target.style.height = '3em'; // Resets the height to 1 row
                      }
                    }}
                    className='chat-input-textarea' 
                    style={{ resize: 'none', overflow: 'hidden' }} 
                    rows={1}>
                    </textarea>
                    <a  onClick={handleListen} >
                      <i class='bx bx-microphone button-mic-transcription'></i>
                    </a>                   
                    <a  onClick={addQuestion} >
                      <i class='bx bx-send button_send_chat'></i>
                    </a>
                  </div>
                </div> 
              
        </section> 
        </div>
        </div>
    </div>
  );
}

export default Chat;

