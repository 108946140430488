import React, { useCallback } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadfile } from '../../APIFirebase';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from "qrcode.react";
import Swal from 'sweetalert2';

import { createFreeModel, calculateTokens } from '../../API';
import business from '../../Assets/personalityAvatars/business.png';  // Adjust the path as needed
import customerService from '../../Assets/personalityAvatars/customerService.png';
import legal from '../../Assets/personalityAvatars/legal.png';
import custom from '../../Assets/personalityAvatars/custom.png';
import chef from '../../Assets/personalityAvatars/chef.png';
import doctor from '../../Assets/personalityAvatars/doctor.png';
import organizer from '../../Assets/personalityAvatars/organizer.png';
import travel from '../../Assets/personalityAvatars/travel.png';
import priest from '../../Assets/personalityAvatars/priest.png';
import './styles.css';
import Loading from '../../components/Loading';
import NewHeader from '../../components/NewHeader';
import NotificationBar from '../../components/NotificationBar';

function App() {
    const [url,_url] = useState(null);
    const [nameFileReal,_nameFileReal] = useState([]);
    const [file,_file] = useState([]);
    const [nameFile,setNamefile] = useState([]);
    const [isLoading,setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState(null);
    const [readyToTrain, setReadyToTrain] = useState(false);
    const [personality, setPersonality] = useState("");
    const [chatTitle, setChatTitle] = useState('Chat');
    const [customPersonality, setcustomPersonality] = useState("");
    const [iframeUrl, setiframeUrl] = useState(null);
    const [trainSuccessfull, setTrainSuccessfull] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const qrRef = useRef(null);
    const [tokensLimitReached, settokensLimitReached] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);


    useEffect(() => {
      // Function to get the value of a URL parameter by name
      const getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      };
      // Get the trakdeskid parameter from the URL
      const trakdeskid = getParameterByName('trakdeskid');
      // Set the trakdeskid as a cookie with a one-day expiration
      if (trakdeskid != null){
        document.cookie = `trakdesk_cid={"tenantId":"jigma","cid":"${trakdeskid}"}; expires=${new Date(Date.now() + 86400000).toUTCString()}; path=/`;
      }
    }, []);

    useEffect(() => {
        if(selectedId !==null && nameFileReal.length > 0){
            setReadyToTrain(true);
        }
        else{
            setReadyToTrain(false);
        }
      
    }, [selectedId,nameFileReal]);

    useEffect(() => {
        switch(selectedId) {
          case 1:
            setPersonality("You are a highly trained business professional with extensive knowledge in finance, marketing, and strategy. Your focus is on providing data-driven advice to help users make informed decisions in their professional lives.");
            break;
          case 2:
            setPersonality("As a customer service representative, you prioritize user satisfaction above all. You're friendly, approachable, and always eager to resolve any issues that the user may have. Your primary objective is to provide swift and efficient solutions.");
            break;
          case 3:
            setPersonality("You are a well-versed legal assistant with a comprehensive understanding of laws and regulations. You help users by providing general legal information, clarifying complex terms, and guiding them through legal processes. Note that you don't give legal advice but can offer useful insights.");
            break;
          case 4:
            setPersonality("You are a seasoned traveler and a walking encyclopedia of travel tips, destinations, and cultural know-how. Your aim is to help users plan the perfect trip by offering recommendations on places to visit, activities to try, and foods to eat");
            break;
          case 5:
            setPersonality("You are an excellent chef that knows all the recipes in the world. You have all the information and tips needed to cook the best food. Your demeanor is gentle, and you passionately help people improve their cooking skills.");
            break;
          case 6:
            setPersonality("As a medical expert, you have a wealth of knowledge about healthcare and medicine. You offer general health tips, describe common medical conditions, and provide guidelines for a healthy lifestyle. Note that you can't give medical advice, but you can offer useful information.");
            break;
          case 7:
            setPersonality("You are a meticulous organizer, skilled in time management and project coordination. You love helping users sort out their schedules, set reminders, and keep track of their tasks. Your ultimate goal is to make the user's life more organized and less stressful.");
            break;
          case 8:
            setPersonality("As a spiritual guide, your focus is on moral and ethical guidance. You offer words of wisdom, comfort in times of need, and provide insights into various religious and spiritual questions. Your main objective is to help users find peace and spiritual fulfillment.");
            break;
          case 9:
            setPersonality(customPersonality);
            break;
          default:
            setPersonality(""); // Set to empty string or some default value if selectedId doesn't match any cases
            break;
        }
      }, [selectedId,customPersonality]);

    useEffect(() => {
      console.log(personality)
    }, [personality]);

  const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
  }

  const Popup = () => (
    <div className="popup">
        <div className="popup-inner">
            <button onClick={togglePopup} className="close-btn">x</button>
            <iframe 
                src="https://www.youtube.com/embed/l6hlpr4n95s" 
                title="YouTube video" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
            </iframe>
        </div>
    </div>
  );


    const downloadQR = () => {
      const canvas = qrRef.current.querySelector("canvas");
    
      if (canvas) {
        const imgData = canvas.toDataURL("image/png");
    
        // Create an anchor element and programmatically click it to initiate download
        const aEl = document.createElement("a");
        aEl.href = imgData;
        aEl.download = "QRCode.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
      } else {
        console.warn("Canvas element not found.");
      }
    };

    const submit = async () => {
      if(tokensLimitReached === true ){
        Swal.fire({
          icon: 'error',
          title: 'Token Limit Surpassed',
          text: 'Your files reach the limit of tokens. Reduce the amount of tokens',
        });
        setLoading(false);
        return;
      }
      setLoading(true);
        try {
            const res = await createFreeModel(personality,chatTitle,file,"");
            setLoading(false);
            console.log(res);
            console.log(res.data);
            console.log(res.data.export_chat_id);
            setTrainSuccessfull(true);
            setiframeUrl(`https://jigma-chat.web.app/?modelId=${res.data.export_chat_id}`);
        } catch (error) {
          setLoading(false);
          console.log(error);
      }
    }

    const shareToWhatsApp = () => {
      const text = "Check out this Chatbot and use it: " + encodeURIComponent(iframeUrl);
      const whatsappUrl = `https://api.whatsapp.com/send?text=${text}`;
      window.open(whatsappUrl, "_blank");
    };

    const shareToTwitter = () => {
      const text = "Check out this Chatbot and use it: ";
      const url = encodeURIComponent(iframeUrl);
      const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
      window.open(twitterUrl, "_blank");
    };

    const shareToFacebook = () => {
      const url = encodeURIComponent(iframeUrl);
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      window.open(facebookUrl, "_blank");
    };
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(iframeUrl);
        // Show a message or do something to indicate the text has been copied
    };
    

    const onDrop = useCallback(async (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        alert("No file uploaded");
        return;
      }
      if (acceptedFiles.length > 3) {
        alert("Upgrade your plan to upload more files");
        return;
      }
      let allFilesAccepted = true;
  
      acceptedFiles.forEach(file => {
          const extension = file.name.split('.').pop();
          if (file.size > 30000000) {
              Swal.fire({
                icon: 'error',
                title: 'File too heavy',
                text: 'The size of the file must be below 10mb',
              })
              //alert(`The file '${file.name}' is too heavy`);
              allFilesAccepted = false;
          } else if (extension !== "csv" && extension !== "docx" && extension !== "docs" && extension !== "doc" && extension !== "pdf" && extension !== "pptx" && extension !=="txt") {
              Swal.fire({
                icon: 'error',
                title: 'File not supported',
                text: 'File format is not one of the supported',
              })
              //alert(`The file '${file.name}' must be one of this format csv, docx, docs, doc, pdf or pptx`);
              allFilesAccepted = false;
          }
      });
  
      if (allFilesAccepted) {
        
        const nameFiles = [];
        const files = [];
        const nameFilesReals = [];
        let totalTokens = 0;
        setLoading(true);
        // Move the async uploadfile call into a separate async function
        acceptedFiles.forEach(async (file, index) => {
          nameFilesReals.push(file.name);
          _nameFileReal(nameFilesReals);
          const name_file = "input-" + params.uuid + "-" + index + "." + file.name.split('.').pop();
          nameFiles.push(name_file);
  
          files.push(file);
          const checkTokens = async () => {
            const res = await calculateTokens("",files);
            let roundedCredits = Math.ceil((res.data/2000));
            //setcreditsUsed(roundedCredits);
            console.log("los tokens en total son: ",res.data);
            totalTokens = res.data;
            return res.data;
          }
          const tokensTotal = await checkTokens();
          console.log(tokensTotal, "total de tokens");
          if(tokensTotal > 50000){
            settokensLimitReached(true);
            Swal.fire({
              icon: 'error',
              title: 'Token Limit Surpassed',
              text: 'Your files reach the limit of tokens',
            });
          }else{
            settokensLimitReached(false);
          }
          console.log("the files are: ", files);
          _file(files);
      });
        files.forEach((fileObj) => {
          const upload = async () => {
              const res = await uploadfile(fileObj.name, fileObj);
              _url(res);
              setLoading(false);
          };
          upload();
      });
      setNamefile(nameFiles);
    }  
    }, [params, _nameFileReal, _url, _file, uploadfile]);
    
    /*
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length === 0) {
          alert("No file uploaded");
          return;
        }
        if (acceptedFiles.length > 3) {
          alert("Upgrade your plan to upload more files");
          return;
        }
        let allFilesAccepted = true;
    
        acceptedFiles.forEach(file => {
            const extension = file.name.split('.').pop();
            if (file.size > 10000000) {
                alert(`The file '${file.name}' is too heavy`);
                allFilesAccepted = false;
            } else if (extension !== "csv" && extension !== "docx" && extension !== "docs" && extension !== "doc" && extension !== "pdf" && extension !== "pptx" && extension !=="txt") {
                alert(`The file '${file.name}' must be one of this format csv, docx, docs, doc, pdf or pptx`);
                allFilesAccepted = false;
            }
        });
    
        if (allFilesAccepted) {
          
          const nameFiles = [];
          const files = [];
          const nameFilesReals = [];
          setLoading(true);
          // Move the async uploadfile call into a separate async function
          acceptedFiles.forEach((file, index) => {
            nameFilesReals.push(file.name);
            _nameFileReal(nameFilesReals);
            const name_file = "input-" + params.uuid + "-" + index + "." + file.name.split('.').pop();
            nameFiles.push(name_file);
    
            files.push(file);
            console.log("the files are: ", files)
            _file(files);
        });
        files.forEach((fileObj) => {
          const upload = async () => {
              const res = await uploadfile(fileObj.name, fileObj);
              _url(res);
              setLoading(false);
          };
          upload();
      });
      setNamefile(nameFiles);
      }  
      }, [params, _nameFileReal, _url, _file, uploadfile]);*/

    const removeItemFile =  (index) => {
    _nameFileReal((prevNameFileReal) => {
        const newNameFileReal = [...prevNameFileReal];
        newNameFileReal.splice(index, 1);
        return newNameFileReal;
    });
    setNamefile((prevNamefile) => {
        const newNameFile= [...prevNamefile];
        newNameFile.splice(index, 1);
        return newNameFile;
    });
    _file((prevFile) => {
        const newFile = [...prevFile];
        newFile.splice(index, 1);
        return newFile;
    });
    _url((prevUrl) => {
        const newUrl = [...prevUrl];
        newUrl.splice(index, 1);
        return newUrl;
    });

    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className="App">
      {/* Header */}
      <NotificationBar
        message="Sign Up for Enhanced Customization"
        linkText="Sign Up!"
        linkUrl="/signup"
      />
      <NewHeader />

      {/* Main Content */}
      <main className="App-main">
        <div className="sideContent">
        <div className="learn-jigma" style={{marginBottom:'10px'}}>
              <button className='cta-button' onClick={togglePopup}>Learn how to use Jigma</button>
            </div>
          <h6 style={{marginTop:'30px'}}>Name Your Chatbot:</h6>
          <div className="custom-textarea-container">
                <textarea id='free-chatbot-name' rows="1" placeholder='e.g., MyCustomerAssistant' value={chatTitle} onChange={(e) => setChatTitle(e.target.value)}></textarea>
          </div>
          <h6 style={{marginTop:'30px'}}>Choose a Chatbot Personality:</h6>
          <div className="grid-container">
            <div key={1} className={`grid-item ${selectedId === 1 ? 'selected' : ''}`} onClick={() => setSelectedId(1)}>
                <img src={business} alt={`image-1`} id={1}/>
                <small>Business Assistant</small>
            </div>
            <div key={2} className={`grid-item ${selectedId === 2 ? 'selected' : ''}`} onClick={() => setSelectedId(2)}>
                <img src={customerService} alt={`image-2`} id={2}/>
                <small>Customer Service</small>
            </div>
            <div key={3} className={`grid-item ${selectedId === 3 ? 'selected' : ''}`} onClick={() => setSelectedId(3)}>
                <img src={legal} alt={`image-3`} id={3}/>
                <small>Legal Assistant</small>
            </div>
            <div key={4} className={`grid-item ${selectedId === 4 ? 'selected' : ''}`} onClick={() => setSelectedId(4)}>
                <img src={travel} alt={`image-4`} id={4}/>
                <small>Travel Assistant</small>
            </div>
            <div key={5} className={`grid-item ${selectedId === 5 ? 'selected' : ''}`} onClick={() => setSelectedId(5)}>
                <img src={chef} alt={`image-5`} id={5}/>
                <small>Cook Assistant</small>
            </div>
            <div key={6} className={`grid-item ${selectedId === 6 ? 'selected' : ''}`} onClick={() => setSelectedId(6)}>
                <img src={doctor} alt={`image-6`} id={6}/>
                <small>Health Assistant</small>
            </div>
            <div key={7} className={`grid-item ${selectedId === 7 ? 'selected' : ''}`} onClick={() => setSelectedId(7)}>
                <img src={organizer} alt={`image-7`} id={7}/>
                <small>Event Organizer</small>
            </div>
            <div key={8} className={`grid-item ${selectedId === 8 ? 'selected' : ''}`} onClick={() => setSelectedId(8)}>
                <img src={priest} alt={`image-8`} id={8}/>
                <small>Spiritual Assistant</small>
            </div>
            <div key={9} className={`grid-item hero ${selectedId === 9 ? 'selected' : ''}`} onClick={() => setSelectedId(9)}>
                <img src={custom} alt={`image-9`} id={9}/>
                <small>Custom</small>
            </div>
        </div>
            {selectedId === 9 && (
            <div className="custom-textarea-container">
                <h6>Craft a Unique Personality for Your Chatbot:</h6>
                <textarea rows="4" placeholder='For example: You are a fitness coach. Use the knowledge I have provided to assist gym-goers.' value={customPersonality} onChange={(e) => setcustomPersonality(e.target.value)}></textarea>
            </div>
        )}
          {
              nameFileReal.length === 0 ? (
            <div style={{marginTop:'20px'}}>
            
          <label className='form-label font-inter text-gym1'>
          Upload Files to Train Your Chatbot <b>(*Accepted formats: pdf, docx, csv, txt, pptx)</b>
          </label>
          <div id='free-dropbox' className='drop-box1' {...getRootProps()}>
            <input {...getInputProps()} />
            <i className='bx bx-cloud-upload upload-icon'></i>
            { isDragActive ? (
                <p className='text-dropzone'>Drop the files here ...</p>
            ) : (
                <p className='text-dropzone'>Drag & Drop Files Here</p>
            )}
          </div>
          {isPopupOpen && <Popup />}
          </div>
          ):(
            nameFileReal?.map((nameOfFile, i) => (
                <div className='upload-file-card3' key={i} style={{marginTop:'20px'}}>
                    <i className='bx bx-file file-icon1'></i>
                    <div className='col'>
                    <div className='h5 font-inter text-gym-file1 pt-2'>{nameOfFile}</div>
                    </div>
                    {isLoading === false &&
                    <div className='col-2 pt-1'>
                        <div onClick={removeItemFile} className='delete_file font-inter'><i className='bx bx-x x-icon1'></i></div>
                    </div>
                    }
                </div>
                ))
            )
            }
            {
                nameFileReal.length > 0 ? (
                    isLoading ? (
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'10px'}}>
                            <Loading />
                        </div>
                    ) : (
                        <></>
                    )
                ) : null
                }
        {
                !trainSuccessfull && !isLoading ? (
          <div className="train-free-button">
                <button id='free-create-chatbot' className="cta-button" onClick={() => { setLoading(true); submit();}} disabled={!readyToTrain}>Create Chatbot</button>    
          </div>
                ):(
                  isLoading && (
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'10px'}}>
                      <Loading />
                    </div>
                  )
                    
                )
            }   
        </div>
        <div className="preview">
          {/* Your preview content will go here */}
          <h1>Create and Share Your <label style={{color:'#8643FF'}}>Chatbot</label> in Seconds</h1>
          <p> Upload data, pick a persona, and launch your 24/7 AI employee! </p>
          
            {
                trainSuccessfull ? (
                    <div className='get-link'>
                    <h6 style={{color:'black'}}>Share Your Chatbot</h6>
                    <div className="separator"></div>
                    <div className="link-container">
                      <div className="url-section">
                        <input type="text" readOnly value={iframeUrl} className="share-link-input" />
                        <div className='social-links'>
                        <button id='free-copy-button' className="cta-button copy-button" onClick={copyToClipboard}>Copy</button>
                        <button id='free-share-whatsapp' onClick={shareToWhatsApp} className='whatsapp-icon-jigma'><i class='bx bxl-whatsapp' style={{color:'white', fontSize:'1.5rem'}}></i></button>
                        <button id='free-share-twitter' onClick={shareToTwitter} className='twitter-icon-jigma'><i class='bx bxl-twitter' style={{color:'white', fontSize:'1.5rem'}}></i></button>
                        <button id='free-share-facebook' onClick={shareToFacebook} className='facebook-icon-jigma'><i class='bx bxl-facebook' style={{color:'white', fontSize:'1.5rem'}}></i></button>
                        </div>
                      </div>
                    </div>
                    
                    <div className="qr-container">
                        <p>Or scan this QR code:</p>
                        <div ref={qrRef}  className="qr-section">
                        <QRCode value={iframeUrl} />
                        </div>
                        <button id='free-download-qr-code1' className="cta-button copy-button" onClick={downloadQR}>Download QR Code</button>
                    </div>
                    </div>
                ):(
                  <div className="chatbot-preview-skeleton">
                  <div className="chatbot-preview-header">
                      Chatbot Preview
                  </div>
                  <div className="chatbot-preview-body">
                      <p>Get ready for the Preview! Just wrap up your chatbot setup on the left panel, or watch the tutorial below. When you're done, simply scroll down to find the 'Create' button!🚀</p>
                      <iframe 
                        className='iframe-youtube'
                        src="https://www.youtube.com/embed/l6hlpr4n95s" 
                        title="YouTube video" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                      </iframe>
                  </div>
                  </div>
                )
            }
            
          <div className="iframe-container">
            <iframe 
            id='free-preview-iframe'
            className="responsive-iframe"
            src={iframeUrl}
            title="Your Iframe Title">
            </iframe>
         </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="App-footer">
        <p>Copyright 2023</p>
        <p>Contact: <a href="mailto:developer@samprand.com" className="email-link">developer@samprand.com</a></p>
      </footer>
    </div>
  );
}

export default App;
