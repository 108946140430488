import { useContext, useState } from 'react';
import Button from '../../components/Button';
import Card from '../../components/CardProfile';
import Header from '../../components/Header';
import Input from '../../components/Input';
import './styles.css';
import { getAuth, updateProfile,updateEmail,reauthenticateWithCredential } from "firebase/auth";
import { AuthContext } from '../../AuthProvider';
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { query, collection, getDocs, where } from "firebase/firestore";
import Loading from '../../components/Loading';
import SideBar from '../../components/SideBar';

function EditProfile() {
  const context = useContext(AuthContext);
  const [email,_email] = useState(context.user.email)
  const [password,_password] = useState('');
  const [name,_name] = useState(context.user.name);
  const [isLoading, setLoading] = useState(false)

  const onChangeEmail = (value) => {
    _email(value.target.value)
  }
  const onChangePass = (value) => {
    _password(value.target.value)
  }
  const onChangeName = (value) => {
    _name(value.target.value)
  }
  const submit = () => {
    setLoading(true)

    if (email==""){
      alert("You need a email");
      setLoading(false)

    }else if (name=="You need a name"){
      alert("You need a name");
      setLoading(false)


    }else {
      const auth = getAuth();
      // console.log("ss",auth.currentUser)
      updateEmail(auth.currentUser, email).then(async () => {
        try {
          const q = query(collection(db, "users"), where("uid", "==", auth.currentUser.uid));
          const docc = await getDocs(q);
          const docRef = doc(db, "users", docc.docs[0].id);
          //updating document
          const user = doc(db, "users", "uid");
          await updateDoc(docRef, {
            name: name,
            email: email,
          });
          alert("updated")
      setLoading(false)

        } catch (error) {
          alert(error)
      setLoading(false)

        }
  
  
      }).catch((error) => {
        // An error occurred
        // ...
        alert(error)
      setLoading(false)

      });
    }
    
    
    // updateEmail(auth.currentUser, email).then(() => {
    //   alert("UPDATED ")
    // }).catch((error) => {
    //   // An error occurred
    //   // ...
    // });
    

    
   }
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <div className="app-web">
      <SideBar isSidebarOpen={isSidebarOpen} />
      <div className="content-static">
        <Header title="Profile" onToggleSidebar={toggleSidebar} profile_active={0} gym_active={0} back_active={0} edit_active={0}/>
      <div className='content-edit-profile'>
        <div className='edit_profile_card  '>
          <div className='container my-5 py-5' >
            <div className='row justify-content-center' >
              <div className='col-10 ' >
                <Input name="email" type={"email"} onChange={onChangeEmail} value={email} title="Email" placeholder={"Enter email..."} />
                {/* <Input type={"password"} name="password" onChange={onChangePass}  value={password} title="Password" placeholder={"Enter a new password..."} /> */}
                <Input name="name" value={name} onChange={onChangeName}   title="Full Name" placeholder={"Enter full name..."} />
                <div className='mt-5 mb-3 ' >
                  {
                    isLoading ?
                    (
                      <div className='text-center' ><Loading /></div>
                    )
                    :
                    (
                      <div className="button_container">
                        <button onClick={submit} className='btn button-login-signup  col-12 button'  >UPDATE USER</button>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        
      </div>
      </div>
      </div>
  </div>
  );
}

export default EditProfile;
