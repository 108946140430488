import './styles.css';
import './../../App.css';
import ReactMarkdown from 'react-markdown';

function ItemChatModel({title,date,is_ans}) {
  return (
    <div className={is_ans == 0 ? 'item_chat_model-question mb-3' : 'item_chat_model-answer mb-3'}>
      {
        is_ans== 0 ?
        (
          
        <div className='box_chat right' >
          <div className='row ' > 
            <div className='col'> 
              <p className='input-text-user'>
                <b>{title}</b>
              </p>
            </div>
            
          </div>
          
          
        </div>
        )
        :
        (
          <div className='box_chat2 left ' >
            <div className='row d-flex flex-nowrap'> 
              <div className='col '> 
              <div className='bg_chat_model' >
                <ReactMarkdown className='input-text-ai' linkTarget="_blank">
                  {title}
                </ReactMarkdown>

              </div>
              </div>
            </div>
          </div>
        )
      }
      { is_ans==0 ? (
      <div className='bottom_text_chat_item_model_question' >
        <b>
          Question
        </b>
         - {date} 

      </div>
      )
      :
      (
        <div className='bottom_text_chat_item_model_answer' >
        <b className='text-right'>
          Answer
        </b>
         - {date} 

      </div>
      )
      }
    </div>
  );
}

export default ItemChatModel;
