import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useAuth } from '../../utils';
import googlePlaystore from '../../Assets/SignIn/playstoreicon.png';


function NewFooter() {
    const {user,setUser, onLogout} = useAuth();
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [chatManagerDropdownOpen, setChatManagerDropdownOpen] = useState(false);

  return (
    <footer>
     {/* Footer */}
     <div className="footer">
     <div className="footer-section">
       <h1 className="footer-logo">Jigma.io</h1>
     </div>

     <div className="footer-section">
       <h2>Product</h2>
       <ul>
         <li><label className="pricing-link-footer" onClick={() => navigate('/dashboard')}>Home</label></li>
         <li><label className="pricing-link-footer" onClick={() => navigate('/analytics')}>Analytics</label></li>
         <li><label className="pricing-link-footer" onClick={() => navigate('/contact-us')}>Contact Us</label></li>
         <li><label className="pricing-link-footer" onClick={() => navigate('/pricing')}>Pricing</label></li>
         <li><label className="pricing-link-footer">Affiliates</label></li>
         <li><label className="pricing-link-footer">FAQ</label></li>
       </ul>
     </div>

     <div className="footer-section">
       <h2>Company</h2>
       <ul>
        <li><label className="pricing-link-footer">Terms & Conditions</label></li>
        <li><label className="pricing-link-footer">Privacy Policy</label></li>
       </ul>
     </div>
     <div>                
     <div className="footer-section-email">
       <div>
       <h4>Stay Connected</h4>
       <input type="email" placeholder="Email*" />
       </div>
       <form>
         <button type="submit">Subscribe</button>
       </form>   
       
     </div>
     <p className="contact-direct-email">Contact: <a href="mailto:developer@samprand.com" className="email-link">developer@samprand.com</a></p>
     </div>  
   </div>
   </footer>
  );
}

export default NewFooter;











