import React, { useContext, useEffect, useState} from "react";
import {
  NavLink,
  Route,
  Navigate,
  useNavigate,
  Routes,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './var.css';
import router from "./Navigator";
import { AuthProvider } from "./AuthProvider";
import Home from "./pages/Home";
import Chat from "./pages/Chat";
import NoMatch from "./pages/NoMatch";
import Login from "./pages/Login";
import { db, logInWithEmailAndPassword } from "./firebase";
import EditProfile from "./pages/EditProfile";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import { useAuth } from "./utils";
import ForgotPassword from "./pages/ForgotPassword";
import PreGym from "./pages/PreGym";
import Gym from "./pages/Gym";
import { doc, getDoc } from "firebase/firestore";
import CustomizeChat from "./pages/CustomizeChat";
import ChatExport from "./pages/ChatExport";
import Payment from "./pages/payments";
import PaymentSuccess from "./pages/PaymentSuccess";
import { updateCheckoutSession, updateSubscriptionCredits, getUserActiveSubscription, setUpdateDateAppSumoSubscription } from './APIFirebase';
import Analytics from "./pages/Analytics";
import { cancelStripeSubscription } from "./API";
import SignInNew from "./pages/SignInNew";
import LoginNew from "./pages/LoginNew";
import FreeChatbot from "./pages/FreeChatbot";
import TrainChatbot from "./pages/TrainChatbot";
import NewPricing from "./pages/NewPricing";
import NewAnalytics from "./pages/NewAnalytics";
import ContactUs from "./pages/ContactUs";
import NewProfile from "./pages/NewProfile";
//import ChatBotComponent from "./components/ChatbotComponent";

const FlexibleRoute = ({ children }) => {
  const { setUser,user } = useAuth();
  useEffect(() => {
    // console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS")
    async function check(){
      const uid = await localStorage.getItem('uid');
      if (uid){
        const docRef = doc(db, "customers", uid);
        const docSnap = await getDoc(docRef);
        const models = docSnap.data().models;
        const hasUnfinishModel = models.length == 0 ? false : (models[models.length-1].link == null ? true : false)

        setUser({
          email: docSnap.data().email,
          accessToken: docSnap.data().accessToken,
          name: docSnap.data().name,
          uid: uid,
          docId: docSnap.data().docId,
          hasUnfinishModel,
        })
      }}
      check();
    },[]);
  return user ? children : children;
};

const ProtectedRoute = ({ children }) => {
  const {setUser,user} = useAuth();
  const [hasSubscription, setHasSubscription] = useState(false);
  const uid = localStorage.getItem('uid');
  useEffect(() => {
    // console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS")
    async function check(){
      const uid = await localStorage.getItem('uid');
      if (uid){
        const docRef = doc(db, "customers", uid);
        const docSnap = await getDoc(docRef);
        const models = docSnap.data().models;
        const hasUnfinishModel = models.length == 0 ? false : (models[models.length-1].link == null ? true : false)

        setUser({
          email: docSnap.data().email,
          accessToken: docSnap.data().accessToken,
          name: docSnap.data().name,
          uid: uid,
          docId: docSnap.data().docId,
          hasUnfinishModel,
        })
        //console.log("LLEGA")
        const searchParams = new URLSearchParams(window.location.search);

        var appSumoSubId = searchParams.get('app_sumo_sub_id')
        if(appSumoSubId){
          await setUpdateDateAppSumoSubscription(uid)
        }
        //console.log(window.location)
        //console.log(searchParams.get('session_id'))
        //Extract the session_id parameter value
        var sessionId = searchParams.get('session_id');
        //sessionId = "cs_test_a1Afg4huZD9CyGUsv1sMnOEfLmnQj7lPTTBLnQ4qxhxIFGchWz5cp3GHup"
        // Check if session_id exists
        if (sessionId) {
          const subscriptions = await getUserActiveSubscription(uid)
          if(subscriptions.length>0){
            setHasSubscription(true)
            let newestDate = subscriptions[0].created; // Initialize with the first timestamp in the array
            var subscription_id = subscriptions[0].items[0].subscription
            for (let i = 1; i < subscriptions.length; i++) {
              const currentDate = subscriptions[i].created;
              if (currentDate > newestDate) {
                await cancelStripeSubscription(subscription_id)
                subscription_id = subscriptions[i].items[0].subscription
                newestDate = currentDate;
              }
            }
            alert("You successfully subscribed to Jigma.");
          }else{
            setHasSubscription(false)
          }
          //console.log(sessionId)
          // Execute your function or perform desired actions with sessionId
          await updateCheckoutSession(uid,sessionId);
        }
        await updateSubscriptionCredits(uid);
      }
    }
    check();
  },[]);
  if(user.email){
    return children;
  }
  if(!uid){
    return <Navigate to="/" replace />;
  }
  
};

const RedirectIfIsAlreadyLoged = ({ children }) => {
  const {setUser,user} = useAuth();
  const [hasSubscription, setHasSubscription] = useState(false);
  
  useEffect(() => {
    // console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS")
    async function check(){
      const uid = await localStorage.getItem('uid');
      if (uid){
        const docRef = doc(db, "customers", uid);
        const docSnap = await getDoc(docRef);
        const models = docSnap.data().models;
        const hasUnfinishModel = models.length == 0 ? false : (models[models.length-1].link == null ? true : false)

        setUser({
          email: docSnap.data().email,
          accessToken: docSnap.data().accessToken,
          name: docSnap.data().name,
          uid: uid,
          docId: docSnap.data().docId,
          hasUnfinishModel,
        })
        //console.log("LLEGA")
        const searchParams = new URLSearchParams(window.location.search);
        //console.log(window.location)
        //console.log(searchParams.get('session_id'))
        //Extract the session_id parameter value
        var sessionId = searchParams.get('session_id');
        //sessionId = "cs_test_a1Afg4huZD9CyGUsv1sMnOEfLmnQj7lPTTBLnQ4qxhxIFGchWz5cp3GHup"
        // Check if session_id exists
        if (sessionId) {
          const subscriptions = await getUserActiveSubscription(uid)
          if(subscriptions.length>0){
            setHasSubscription(true)
            let newestDate = subscriptions[0].created; // Initialize with the first timestamp in the array
            var subscription_id = subscriptions[0].items[0].subscription
            for (let i = 1; i < subscriptions.length; i++) {
              const currentDate = subscriptions[i].created;
              if (currentDate > newestDate) {
                await cancelStripeSubscription(subscription_id)
                subscription_id = subscriptions[i].items[0].subscription
                newestDate = currentDate;
              }
            }
            alert("You successfully subscribed to Jigma.");
          }else{
            setHasSubscription(false)
          }
          //console.log(sessionId)
          // Execute your function or perform desired actions with sessionId
          await updateCheckoutSession(uid,sessionId);
        }
        await updateSubscriptionCredits(uid);
      }

    }
    check()
  },[]);
  // console.log(user)
  if (user.email) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

const App = () => {
  
  return (
    <AuthProvider>
      

      <Routes>
        {/* <Route index element={<Home />} /> */}
        <Route
          path="/"
          element={
            <RedirectIfIsAlreadyLoged>
              <FreeChatbot />
            </RedirectIfIsAlreadyLoged>
          }
        />
        <Route
          path="login"
          element={
            <RedirectIfIsAlreadyLoged>
              <LoginNew />
            </RedirectIfIsAlreadyLoged>
          }
        />
        <Route
          path="signup"
          element={
            <RedirectIfIsAlreadyLoged>
              <SignInNew />
            </RedirectIfIsAlreadyLoged>
          }
        />
        <Route
          path="forgot-password"
          element={
            <RedirectIfIsAlreadyLoged>
              <ForgotPassword />
            </RedirectIfIsAlreadyLoged>
          }
        />
        {/* <Route path="/" element={<Home  />} /> */}
        {/* <Route path="login" element={<Login  />} /> */}
        {/* <Route path="signup" element={<SignUp  />} />
        <Route path="forgot-password" element={<ForgotPassword  />} /> */}
       

        {/*
        ____________________
        ____________________
        
        PROTECTED ROUTES 
        ____________________
        ____________________
        */}
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <TrainChatbot />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <NewProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile/edit"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="pre-gym"
          element={
            <ProtectedRoute>
              <PreGym />
            </ProtectedRoute>
          }
        />
        <Route
          path="gym/:name/:description/:uuid"
          element={
            <ProtectedRoute>
              <Gym />
            </ProtectedRoute>
          }
        />
        <Route
          path="pricing"
          element={
            <FlexibleRoute>
              <NewPricing />
            </FlexibleRoute>
          }
        />
        <Route
          path="contact-us"
          element={
            <FlexibleRoute>
              <ContactUs />
            </FlexibleRoute>
          }
        />
        <Route
          path="customize"
          element={
            <ProtectedRoute>
              <CustomizeChat />
            </ProtectedRoute>
          }
        />
        <Route
          path="export-chat"
          element={
              <ChatExport />
          }
        />
        <Route
          path="payment-success"
          element={
            <ProtectedRoute>
              <PaymentSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="analytics"
          element={
            <ProtectedRoute>
              <NewAnalytics />
            </ProtectedRoute>
          }
        />
        {/*
        ____________________
        ____________________
        
        END PROTECTED ROUTES 
        ____________________
        ____________________
        */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </AuthProvider>
  );
};
export default App;