import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './styles.css';
import { useAuth } from '../../utils';
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase";
import profilePicture from "../../Assets/SignIn/profile_picture_signin.png";
import googleIcon from "../../Assets/SignIn/google_icon.png";
import Loading from '../../components/Loading';

import rocket from '../../Assets/SignIn/rocket.png';
import globe from '../../Assets/SignIn/worldwide.png';
import email from '../../Assets/SignIn/email.png';
import growth from '../../Assets/SignIn/growth.png';
import party from '../../Assets/SignIn/party-popper.png';
import smartphone from '../../Assets/SignIn/smartphone.png';
import { sendPasswordReset } from "../../firebase";

function SignInNew() {
    const [user, loading, error] = useAuthState(auth);
    const [isLoading, setLoading] = useState(false);
    const [email,_email] = useState('')
    const [password,_password] = useState('');
    const [name,_name] = useState('');
    const {onLoginWithGoogle} = useAuth();

    useEffect(() => {
        // Function to get the value of a URL parameter by name
        const getParameterByName = (name, url) => {
          if (!url) url = window.location.href;
          name = name.replace(/[\[\]]/g, '\\$&');
          const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
            results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, ' '));
        };
        // Get the trakdeskid parameter from the URL
        const trakdeskid = getParameterByName('trakdeskid');
        // Set the trakdeskid as a cookie with a one-day expiration
        if (trakdeskid != null){
          document.cookie = `trakdesk_cid={"tenantId":"jigma","cid":"${trakdeskid}"}; expires=${new Date(Date.now() + 86400000).toUTCString()}; path=/`;
        }
    }, []);

    const onChangeEmail = (value) => {
        _email(value.target.value)
    }
    const onChangePass = (value) => {
        _password(value.target.value)
    }
    const onChangeName = (value) => {
        _name(value.target.value)
    }

    const sendPassReset = () => {
        Swal.fire({
            title: 'Enter Your Email To Reset Password',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Reset',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
              await sendPasswordReset(login);
            },
            allowOutsideClick: () => !Swal.isLoading()
          })
    }
    
  
    useEffect(() => {
      console.log(name,email,password);
    }, [name,email,password]);
    
    const navigate = useNavigate();
   const submit = async (email,password,name) => {
    setLoading(true)
    if (name==""){
        Swal.fire(
            'Error',
            `You need to define a name`,
            'error'
          );
      setLoading(false)
  
    }else if (email==""){
        Swal.fire(
            'Error',
            `You need to define an email`,
            'error'
          );
      setLoading(false)
  
    }else if (password==""){
        Swal.fire(
            'Error',
            `You need to create a password`,
            'error'
          );
    setLoading(false)
  
    }else {
        console.log(name,email,password);
      const res = await registerWithEmailAndPassword(name, email, password);
      
      setLoading(false)
      if (res.user){
        Swal.fire(
            'Account Created!',
            `Please validate your email. Check the inbox on ${email}`,
            'warning'
          )
        navigate('/login')
      }else {
        alert(res)
      }
  
    }
  
   }
  
  return (
    <div className="signin-login">
        <div className="background">
        </div>
        <div className="left-content">
            <div className="left-container">
                <div className="start-content-container">
                    <div className="start-top-content">
                        <div>
                            <span className="jigma-text">Jigma.</span><span className="io-text">io</span>
                            <div className="start-title-description">
                                <span className="start-title-signup">Start your journey with us.</span>
                                <span className="description">The Ultimate No-Code Tool for Training your Own AI Assistant</span>
                            </div>
                        </div>
                        <div className="features-container">
                            <div className="features-content">
                                <img src={rocket} alt={`image-1`} id={1} style={{width:"24px",height:"24px"}}/>
                                <span className="start-feature-text">50 free chatbot messages every month</span>
                            </div>
                            <div className="features-content">
                                <img src={globe} alt={`image-2`} id={2} style={{width:"24px",height:"24px"}}/>
                                <span className="start-feature-text">Embed your chatbot directly on your website</span>
                            </div>
                            <div className="features-content">
                                <img src={growth} alt={`image-3`} id={3} style={{width:"24px",height:"24px"}}/>
                                <span className="start-feature-text">Analyze user behavior</span>
                            </div>
                            <div className="features-content">
                                <img src={smartphone} alt={`image-4`} id={4} style={{width:"24px",height:"24px"}}/>
                                <span className="start-feature-text">Enable human-AI collaboration on iOS and Android</span>
                            </div>
                        </div>
                    </div>
                    <div className="start-bottom-container">
                        <div className="start-bottom-content">
                            <div className="start-bottom-quota">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <g clip-path="url(#clip0_512_2748)">
                                <path d="M6.11067 23.0956C4.73733 21.637 4 20.001 4 17.349C4 12.6823 7.276 8.49964 12.04 6.43164L13.2307 8.26897C8.784 10.6743 7.91467 13.7956 7.568 15.7636C8.284 15.393 9.22133 15.2636 10.14 15.349C12.5453 15.5716 14.4413 17.5463 14.4413 20.001C14.4413 21.2387 13.9497 22.4256 13.0745 23.3008C12.1993 24.176 11.0123 24.6676 9.77467 24.6676C8.344 24.6676 6.976 24.0143 6.11067 23.0956ZM19.444 23.0956C18.0707 21.637 17.3333 20.001 17.3333 17.349C17.3333 12.6823 20.6093 8.49964 25.3733 6.43164L26.564 8.26897C22.1173 10.6743 21.248 13.7956 20.9013 15.7636C21.6173 15.393 22.5547 15.2636 23.4733 15.349C25.8787 15.5716 27.7747 17.5463 27.7747 20.001C27.7747 21.2387 27.283 22.4256 26.4078 23.3008C25.5327 24.176 24.3457 24.6676 23.108 24.6676C21.6773 24.6676 20.3093 24.0143 19.444 23.0956Z" fill="url(#paint0_linear_512_2748)"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_512_2748" x1="4" y1="15.5496" x2="27.7747" y2="15.5496" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#7122FF"/>
                                <stop offset="1" stop-color="#9256FF"/>
                                </linearGradient>
                                <clipPath id="clip0_512_2748">
                                <rect width="32" height="32" fill="white"/>
                                </clipPath>
                            </defs>
                            </svg>
                                <span className="quota-text">“As a developer, integrating Jigma.io into client sites has been a breeze. It elevates their customer engagement effortlessly. It's now my top recommendation”</span>
                            </div>
                            <div className="start-bottom-profile">
                                <div className="start-bottom-profile-info">
                                    <img src={profilePicture} />
                                    <div className="">
                                        <span className="name">Jahid H</span>
                                        <span className="profile-job">UX/UI Designer</span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                </div>
                <div className="start-three-dots">

                </div>
            </div>
        </div>
        <div className="right-container">
            <div className="right-content">
                <div className="signup-container">
                    <div className="signup-title-description">
                        <span className="signup-title">Sign Up</span>
                        <span className="signup-description">Join us today and unlock a world of possibilities with our seamless sign-up process.</span>
                    </div>
                    <div className="signup-buttons">
                        <button onClick={onLoginWithGoogle} className="signup-google">
                            <img src={googleIcon} />
                            <span className="google-button-text">Sign up with Google</span>
                        </button>
                    </div>
                </div>
                <span className="or-text">Or</span>
                <div className="info-container">
                    <div className="signup-inputs">
                        <label>Name*</label>
                        <input className="input-name" type="text" value={name} onChange={onChangeName} placeholder="Enter your name"></input>
                        <label>Email*</label>
                        <input className="input-email" type="email" value={email} onChange={onChangeEmail} placeholder="Enter you Email"></input>
                        <label>Password*</label>
                        <input className="input-password" type="password" value={password} onChange={onChangePass} placeholder="Create a password"></input>
                        <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
                            <span className="must-be-at-least" style={{color:"black"}}>Must be at least 8 characters</span>
                            <span className="signin-to-your-account" style={{textDecorationLine:"underline"}} onClick={() => sendPassReset()}>Forgot your password? Click Here</span>
                        </div>
                        <div>
                            <input className="input-checkbox" type="checkbox" defaultChecked={true} />
                            <label className="checkbox-labels" style={{color:"black"}}>I agree to receive email updates</label>
                        </div>
                        {
                            !isLoading ? (
                                <button className="create-account-button" onClick={() => submit(email,password,name)}>
                                    <span className="create-account-button-text">Create your account</span>
                                </button>
                            ):(
                                <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'10px'}}>
                                    <Loading />
                                </div>
                            )
                        } 
                        <div>
                            <span className="have-an-account-text" style={{color:"black"}}>Have an account?</span>
                            <Link to='/login'>
                            <span className="signin-to-your-account">Sign in to your account</span>
                            </Link>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>
  );
}

export default SignInNew;




