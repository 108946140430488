import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils';

import { getUserCredits, createCheckoutSession,getActiveProducts,createSubscriptionCheckoutSession } from '../../APIFirebase';

import './styles.css';
import Loading from '../../components/Loading';
import PricingPanelEdited from '../../components/PricingPanel copy';
import NewHeader from '../../components/NewHeader';
import NewFooter from '../../components/NewFooter';

function NewPricing() {
  const {user,setUser, onLogout} = useAuth();
  const navigate = useNavigate();
  
  const [credits, setcredits] = useState(null);
  const [products,setProducts] = useState([]);
  const [selected,setSelected] = useState(0);

  useEffect(() => {
    async function getCredits(){
      console.log("retrieve credits from firebase");
      const coins = await getUserCredits(user.docId);
      setcredits(coins);
      await localStorage.setItem("credits",coins);
    }
    const creditCheck = localStorage.getItem("credits");
    if(creditCheck === null){
      getCredits();
    }else{
      setcredits(creditCheck);
    }
  }, []);

  useEffect(() =>{
    async function getData(){
     // const querySnapshot = await getDocs(collection(db, "products"));
     // querySnapshot.forEach(async (doc) => {
 
     //   setProducts( (products) =>
     //     [ ...products,doc.data()]
     //   )
     // });
 
     const p = await getActiveProducts()
     //Code to set the second product as the last
     const secontItem = p[1]
     p.splice(1,1)
     p.push(secontItem)
     ////////////////////////////////////////////
     setProducts(p)
     console.log(p)
    }
    getData()
 
   },[])

  const toBuy = async () => {
    try {
        console.log(" ",products[selected].priceId);
        if(products[selected].type=="one_time"){
          console.log("credits")
          await createCheckoutSession(user.docId,products[selected].priceId,products[selected].credits);
        }else if(products[selected].type=="recurring"){
          console.log("subscription")
          await createSubscriptionCheckoutSession(user.docId,products[selected].priceId,products[selected].credits);
        }
        
    } catch (error) {
      console.log("Err ",error)      
    }
  }
    
  return (
    <div className="App">
      {/* Header */}
      <NewHeader credits={credits}/>
      {/* Main Content */}
      <main className="App-main" style={{justifyContent:"center"}}>
        <PricingPanelEdited buy={toBuy} products={products}/>
      </main>

      <NewFooter />
    </div>
  );
}

export default NewPricing;
