import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const NotificationBar = ({ message, linkText, linkUrl }) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  if (!isVisible) return null;

  return (
    <div className="notificationBar">
      <div className="notification-content">
        <span className="notification-message">{message}</span>
        <span className="notification-link" onClick={() => navigate(`${linkUrl}`)}>{linkText}</span>
      </div>
      <button onClick={() => setIsVisible(false)} className="notification-closeButton">
        ✖
      </button>
    </div>
  );
};

export default NotificationBar;
