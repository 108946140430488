import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useAuth } from '../../utils';
import googlePlaystore from '../../Assets/SignIn/playstoreicon.png';


function NewHeader({credits}) {
    const {user,setUser, onLogout} = useAuth();
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [chatManagerDropdownOpen, setChatManagerDropdownOpen] = useState(false);

  return (
    <header className="App-header">
      <div className="header-left">
        <h2 onClick={() => navigate('/dashboard')} style={{cursor:"pointer"}}>Jigma.io</h2>
      </div>
      <div className="header-right">
        <div className='coin-mobile'>
          <span className='coin-number'>{credits}</span><i className='bx bx-coin-stack coin-icon'></i>
        </div>
        <button className="menu-button" onClick={() => setMenuOpen(!isMenuOpen)}>
          <i className='bx bx-menu hamburger-icon'></i>
        </button>
        {
          user.docId ? (
            <div className={`menu-items ${isMenuOpen ? "active" : ""}`}>
          <label className="pricing-link" onClick={() => navigate('/dashboard')}>Home</label>
          <label className="pricing-link" onClick={() => navigate('/analytics')}>Analytics</label>
          <label 
            className="pricing-link chat-manager-wrapper"
            onMouseEnter={() => setChatManagerDropdownOpen(true)}
            onMouseLeave={() => setChatManagerDropdownOpen(false)}
          >
            Chat Manager
            <div className={`profile-dropdown ${!chatManagerDropdownOpen ? '' : 'hidden'}`}>
              <label onClick={() => window.open('https://jigma-chat-manager.web.app', '_blank')} style={{fontWeight:"lighter"}}>Web Chat Manager</label>
              <img src={googlePlaystore} alt={`image-1`} id={1} style={{width:"118px", height:"36px", borderRadius:"15px", cursor:"pointer"}} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.jigma.jigmaChatManager&pcampaignid=web_share', '_blank')}/>
            </div>
          </label>
          <label className="pricing-link" onClick={() => navigate('/contact-us')}>Contact Us</label>
          <label className="pricing-link" onClick={() => navigate('/pricing')}>Pricing</label>
          <div className='coin-desktop'>
            <span className='coin-number'>{credits}</span><i className='bx bx-coin-stack coin-icon'></i>
          </div>
          <div className="profile-container-header">
            <button className="profile-icon" onClick={() => setDropdownOpen(!isDropdownOpen)}>
              <i className='bx bx-user'></i>
            </button>
              <div className={`profile-dropdown ${!isDropdownOpen ? '' : 'hidden'}`}> 
                <label onClick={() => navigate('/profile')}>Profile</label>
                <button className='cta-button' onClick={() => onLogout()}>Logout</button>
              </div>
          </div>
        </div>
          ):(
            <div className={`menu-items ${isMenuOpen ? "active" : ""}`}>
              <label className="pricing-link" onClick={() => navigate('/')}>Home</label>
              <label className="pricing-link" onClick={() => window.open('https://jigma.io', '_blank')}>Features</label>
              <label className="pricing-link" onClick={() => navigate('/contact-us')}>Contact Us</label>
              <label className="pricing-link" onClick={() => navigate('/pricing')}>Pricing</label>
              <a className="cta-button" href="/signup" style={{textDecoration:"none"}}>Sign Up</a>
            </div>
          )
        }
        
      </div>
      {isMenuOpen && (
        <div className="menu-overlay" onClick={() => setMenuOpen(false)}></div>
      )}
    </header>
  );
}

export default NewHeader;




