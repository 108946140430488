import Header from '../../components/Header';
import './styles.css';
import { useState, useEffect } from 'react';
import { checkStatus, createModel, updateModelFiles } from '../../API';
import { deleteModel, getModels, updateModel, uploadfile, discountModels, checkExistingContext } from '../../APIFirebase';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../utils';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import SideBar from '../../components/SideBar';

function Gym() {

  const [url,_url] = useState(null);
  const [nameFileReal,_nameFileReal] = useState([]);
  const [file,_file] = useState([]);
  const [nameFile,setNamefile] = useState([]);
  const [isLoading,setLoading] = useState(false);
  const params = useParams();
  const navigator = useNavigate();
  const {user,setUser} = useAuth()
  const [textAreaValue, setTextAreaValue] = useState("");
  
  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      alert("No file uploaded");
      return;
    }
    if (acceptedFiles.length > 3) {
      alert("Upgrade your plan to upload more files");
      return;
    }
    let allFilesAccepted = true;

    acceptedFiles.forEach(file => {
        const extension = file.name.split('.').pop();
        if (file.size > 10000000) {
            alert(`The file '${file.name}' is too heavy`);
            allFilesAccepted = false;
        } else if (extension !== "csv" && extension !== "docx" && extension !== "docs" && extension !== "doc" && extension !== "pdf" && extension !== "pptx" && extension !=="txt") {
            alert(`The file '${file.name}' must be one of this format csv, docx, docs, doc, pdf or pptx`);
            allFilesAccepted = false;
        }
    });

    if (allFilesAccepted) {
      
      const nameFiles = [];
      const files = [];
      const nameFilesReals = [];
      setLoading(true);
      // Move the async uploadfile call into a separate async function
      acceptedFiles.forEach((file, index) => {
        nameFilesReals.push(file.name);
        _nameFileReal(nameFilesReals);
        const name_file = "input-" + params.uuid + "-" + index + "." + file.name.split('.').pop();
        nameFiles.push(name_file);

        files.push(file);
        console.log("the files are: ", files)
        _file(files);
    });
    files.forEach((fileObj) => {
      const upload = async () => {
          const res = await uploadfile(fileObj.name, fileObj);
          _url(res);
          setLoading(false);
      };
      upload();
  });

  setNamefile(nameFiles);
    
  }
    
  
  }, [params, _nameFileReal, _url, _file, uploadfile]);
  
  const onChangeFile = async (value) => {
    const extention = value.target.files[0].name.split('.').pop();
    setLoading(true)
    if (value.target.files[0].size>10000000){
      alert("The file is so heavy")
    }else if(extention !== "csv" && extention!=="docx" && extention !=="pdf"){
      alert("The file must be csv or docs format")
    }else {
      _nameFileReal(value.target.files[0].name)
      const name_file = "input-"+params.uuid+"."+extention;
      setNamefile(name_file);
      const res = await uploadfile(name_file,value.target.files[0])
      _url(res)
      _file(value.target.files[0])
    }
    setLoading(false)
  }

  const submit = async () => {
    try {
      console.log(params.name);
      const res = await checkStatus(params.uuid,params.name);
      console.log(res);
      console.log("el res state es:",res.data.state);
      if(res.data.state){
        if (url===''){
          alert("You must set a url or a file")
        }
        setLoading(true);
        const res = await updateModelFiles(params.uuid,params.name,file,textAreaValue);
        if (res.data){
          await discountModels(user.docId);
          setLoading(false);
          navigator("/dashboard");
  
        }else {
          alert("Error "+res)
        }
        console.log("llamar al que updatea");
      }
      else {
        if (url===''){
          alert("You must set a url or a file")
        }else {
          setLoading(true);
          const url_2 = "data/Mauricio/"+nameFile;
          const res = await createModel(url_2,params.uuid,params.name,file,textAreaValue);
          // console.log("STATUS SSS ",res)
          if (res.data){
            updateModel(user.docId);
            await discountModels(user.docId);
            setLoading(false);
            navigator("/dashboard");
    
          }else {
            alert("Error "+res)
          }
        }
      }
      /*
      
     */
    } catch (error) {
        console.log("ERROR ",error)
        const models = await getModels(user.docId);

        models.pop();
        await deleteModel(user.docId,models,user)
        setUser({
          ...user,
          hasUnfinishModel: false
        })
        if (error?.response?.status == 429){
          alert(error.response.data.message+"("+error.response.data.seconds_remaining+" seconds)")
        } else {
          alert(error.message)
        }
        navigator("/dashboard")
    
    
  }
}

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  const removeItemFile =  (index) => {
    _nameFileReal((prevNameFileReal) => {
      const newNameFileReal = [...prevNameFileReal];
      newNameFileReal.splice(index, 1);
      return newNameFileReal;
    });
    setNamefile((prevNamefile) => {
      const newNameFile= [...prevNamefile];
      newNameFile.splice(index, 1);
      return newNameFile;
    });
    _file((prevFile) => {
      const newFile = [...prevFile];
      newFile.splice(index, 1);
      return newFile;
    });
    _url((prevUrl) => {
      const newUrl = [...prevUrl];
      newUrl.splice(index, 1);
      return newUrl;
    });

  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="app-web">
  <SideBar isSidebarOpen={isSidebarOpen} />
  <div className="content-static">
    <Header title="Gym" onToggleSidebar={toggleSidebar} profile_active={0} gym_active={0} back_active={0} edit_active={1} />
    <div>
      <div className='row mt-1 content-gym'>
        <div className='h2 font-inter text-gym mt-5'>{params.name}</div>
        <div className='h3 font-inter text-gym'>{params.description}</div>
        <div className='col-12 pt-5 mt-2 ml-3 text-left'>
          <label style={{ color: "black", fontSize: "18px", fontFamily: "Inter" }}>Welcome to the training center of your AI Assistant! You have two ways to impart knowledge: either upload files or jot down text, or even both. Your Assistant is eager to learn!</label>
        </div>
        <div className="row mt-5 mr-2">
          <div className="col-6">
            {
              nameFileReal.length === 0 ? (
                <div className="input">
                  <label className='form-label font-inter text-gym'>Upload Files to Train Your Chatbot <b>(*Accepted formats: pdf, docx, csv, txt, pptx)</b></label>
                  <div className='drop-box' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <i className='bx bx-cloud-upload upload-icon'></i>
                    {isDragActive ? (
                      <p className='text-dropzone'>Drop the files here ...</p>
                    ) : (
                      <p className='text-dropzone'>Drag & Drop Files Here</p>
                    )}
                  </div>
                </div>
              ) : (
                nameFileReal?.map((nameOfFile, i) => (
                  <div className='upload-file-card2' key={i}>
                    <i className='bx bx-file file-icon'></i>
                    <div className='col'>
                      <div className='h5 font-inter text-gym-file pt-2'>{nameOfFile}</div>
                    </div>
                    {isLoading === false &&
                      <div className='col-2 pt-1'>
                        <div onClick={removeItemFile} className='delete_file font-inter'><i className='bx bx-x x-icon'></i></div>
                      </div>
                    }
                  </div>
                ))
              )
            }
          </div>
          <div className="col-1">
            <div className="separator-line"></div>
          </div>
          <div className="col-5 text-left">
            <label style={{ color: "black" }}><label style={{fontWeight:"600"}}>Type Your Teachings:</label> Type in the box below to teach your Assistant. Be as specific or as general as you'd like. Your Assistant is all ears!</label>
            <textarea className="form-control" value={textAreaValue} onChange={handleTextAreaChange} placeholder="Write your text here" style={{ height: '60%' }}></textarea>
          </div>
        </div>
        {
          isLoading ? (
            <div className="text-center mt-5">
              <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
          ) : (
            <div className="text-center"> {/* Center align the button */}
              <button onClick={submit} className='btn btn-primary mt-5 button-train' style={{ width: '300px' }}><label className='button-train-text'>Start Training</label></button>
            </div>
          )
        }
      </div>
    </div>
  </div>
</div>

  );
}

export default Gym;
