import { Link,useNavigate } from 'react-router-dom';
import './styles.css';
import { useState } from 'react';

function Header({title,onToggleSidebar,profile_active,back_active,gym_active,edit_active,credits }) {
  const navigate = useNavigate();
  const [isSideBarOpenHeader, setisSideBarOpenHeader] = useState(false)
  
  function toggleSidebarHeader() {
    setisSideBarOpenHeader(!isSideBarOpenHeader);
  }
  return (
    <header className="header">
      <div className='wrapper_grid' >
        <div className='one' >
          {
            back_active==1 &&
            <a onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill='white' style={{
                height: '5rem',
                width: '5rem',
                paddingTop: '1.2rem',
                paddingLeft: '1.5rem',
                transform: 'scale(0.6)',
            }}><path d="M24 40 8 24 24 8l2.1 2.1-12.4 12.4H40v3H13.7l12.4 12.4Z"/>
            </svg>

            </a>

          }
        </div>
        <div className='two pt-2' >
        <i class='bx bx-menu toggle-icon-header' onClick={() => {onToggleSidebar(); toggleSidebarHeader();}} ></i>
        <div class="wrap">
          <div class="search">
            <input type="text" class="searchTerm" placeholder="Search" />
            <button type="submit" class="searchButton">
              <i class="bx bx-search"></i>
             </button>
            </div>
          </div>
        </div>
        
        <div className='three' >
          
        {
          gym_active==1 &&
          <Link to="/chat" >
            <svg xmlns="http://www.w3.org/2000/svg"  fill='black'><path d="m27.5 43.8-2.1-2.1 7.1-7.1-19.1-19.1-7.1 7.1-2.1-2.1L7 17.6l-2.8-2.8 4.25-4.25-2.1-2.1 2.1-2.1L10.6 8.4l4.2-4.2L17.6 7l2.9-2.8 2.1 2.1-7.1 7.1 19.1 19.1 7.1-7.1 2.1 2.1-2.8 2.9 2.8 2.8-4.3 4.3 2.1 2.1-2.1 2.1-2.1-2.1-4.2 4.2-2.8-2.8Z"/></svg>    
          </Link>
        }
        {
          profile_active==1 && 
          <div className='coin-profile-container'>
            <Link to="/payments" className='coin-profile-container' style={{ textDecoration: 'none' }}>
            <span className='coin-number'>{credits}</span><i class='bx bx-coin-stack coin-icon'></i>
            <button
            className='buy-credit-button'
            id="setup_button"
            aria-label="Buy More Credits!"
            title="Buy More Credits!"
            >
            Upgrade your Plan!
            </button>
            </Link>
          <Link to="/profile" >
            <svg xmlns="http://www.w3.org/2000/svg" fill='black' ><path d="M11.1 35.25q3.15-2 6.225-3.025Q20.4 31.2 24 31.2q3.6 0 6.7 1.025t6.25 3.025q2.2-2.7 3.125-5.45Q41 27.05 41 24q0-7.25-4.875-12.125T24 7q-7.25 0-12.125 4.875T7 24q0 3.05.95 5.8t3.15 5.45ZM24 25.5q-2.9 0-4.875-1.975T17.15 18.65q0-2.9 1.975-4.875T24 11.8q2.9 0 4.875 1.975t1.975 4.875q0 2.9-1.975 4.875T24 25.5ZM24 44q-4.2 0-7.85-1.575-3.65-1.575-6.35-4.3Q7.1 35.4 5.55 31.75 4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z"/></svg>
          </Link>
          </div>
        }
        {
          edit_active==1 && 
          <Link to="/profile/edit" >
            <svg xmlns="http://www.w3.org/2000/svg" fill='black'><path d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z"/></svg>
          </Link>
        }
        
        </div>
      </div>
    </header>
  );
}

export default Header;
