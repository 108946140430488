import './styles.css';
import './../../App.css';
import Header from '../../components/Header';
import Card from '../../components/CardPreGym';
import SideBar from '../../components/SideBar';
import { useState } from 'react';

function PreGym() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }
  return (
    <div className="app-web">
      <SideBar isSidebarOpen={isSidebarOpen} />
      <div className="content-static">
        <Header title="Profile" onToggleSidebar={toggleSidebar} profile_active={0} gym_active={0} back_active={0} edit_active={1}/>
      <div className='content-dynamic-pregym'>
      <div className='container' >
        <div className='row justify-content-center ' >
              <div className='title_page my-2' ></div>
        </div>
        <div className='row justify-content-center mt-5' >
          <div className='row justify-content-center' >
            <div className='col-12 col-md-8 col-lg-6 row pre-gym-box' >
            <Card title="Let's create your Personal AI Assistant" />
            </div>
          </div>
        </div>
      </div>
      <footer className='footer' >
      </footer>
      </div>
      </div>
  </div>
  );
}

export default PreGym;
