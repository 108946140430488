import './styles.css';
import './../../App.css';

function Input({title,placeholder,value,name,onChange,type,rows}) {
  return (
    <div className="input">
      <label className='form-label font-edit-profile ' >{title}</label>
      <textarea type={type} rows={rows} onChange={onChange} name={name} value={value} placeholder={placeholder} className='form-control font-inter font-edit-profile' >
      </textarea>
    </div>
  );
}

export default Input;
