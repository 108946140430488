import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import Input from '../Input';
import Loading from '../../components/Loading';
import './styles.css';

function Card({title,submit,isLoading}) {
  const [email,_email] = useState('')
  const [password,_password] = useState('');
  const [name,_name] = useState('');

  const onChangeEmail = (value) => {
    _email(value.target.value)
  }
  const onChangePass = (value) => {
    _password(value.target.value)
  }
  const onChangeName = (value) => {
    _name(value.target.value)
  }

  return (
    <div className="card_signup">
      <div className='container mt-2 mb-5' >
        <div className='row justify-content-center'  >
          <div className='col-10 my-5' >
            <div className='row' >
              <div className='col-12 text-center' >
                <h2 className='card_title font-inter' >{title}</h2>
              </div>
              <div className='col-12 my-2' >
                <Input name="email" type={"email"} value={email} onChange={onChangeEmail} title="Email" placeholder={"Enter email..."} required/>
              </div>
              <div className='col-12 my-2' >
                <Input type={"password"} name="password" value={password} onChange={onChangePass}  title="Password" placeholder={"Enter password..."} required/>
              </div>
              <div className='col-12 my-2' >
                <Input name="name" value={name} onChange={onChangeName}  title="Full name" placeholder={"Full name..."} required/>
              </div>
              <div className='col-12 mt-2' >
              <div className='text-center' >
              {
                isLoading ?
                (
                  <Loading />
                )
                :
                (
                  <>
                  <div className="button_container">
                  <button onClick={() => submit(email,password,name)} className='button-login-signup col-12 font-inter'  >Create Account</button>
                  </div>
                  <div className='text-center pt-2' >
                    <Link className='button_text font-inter' to="/login" >ALREADY HAVE AN ACCOUNT?</Link>
                  </div>
                  </>
                )
              }
              </div>
                
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
