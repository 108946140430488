import Card from '../../components/CardSignUp';
import './styles.css';

import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase";

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


function SignUp() {


  const [user, loading, error] = useAuthState(auth);

  const [isLoading, setLoading] = useState(false)

  

  const navigate = useNavigate();
  const submit = async (email,password,name) => {
  // console.log("first",email)
  // console.log("first",password)
  // console.log("first",name)
  setLoading(true)
  if (name==""){
    alert("you need to define a name")
    setLoading(false)

  }else if (email==""){
    alert("you need to define a email")
    setLoading(false)

  }else if (password==""){
    alert("you need to define a password")
  setLoading(false)

  }else {
    const res = await registerWithEmailAndPassword(name, email, password);
    
    setLoading(false)
    if (res.user){
      alert("Please, validate your email. Check the inbox of "+email);
      navigate('/login')
    }else {
      alert(res)
    }

  }

 }

//  useEffect(() => {
//   if (loading) return;
//   if (user) navigate("/login");
// }, [user, loading]);

  return (
    <div className="signup ">
      <div className='container py-5' >
        <div className='col justify-content-center logo-image ' >
          <h2 className='title_signup text-center h2' >  Jigma</h2>
        </div>
        <div className='row justify-content-center mt-2 ' >
          <div className='row justify-content-center' >
            <div className='col-md-6 col-10 col-lg-5 row' >
              <Card submit={submit} isLoading={isLoading} title="Sign up" />
            </div>
          </div>
        </div>

      </div>
      <footer className='footer' >
        
      </footer>
    </div>
  );
}

export default SignUp;
