import './styles.css';
import './../../App.css';
import { Link } from 'react-router-dom';

function Button({title,to}) {
  return (
    <div className="button_container">
      <Link to={to} className='btn btn-primary  col-12 button'  >{title}</Link>
    </div>
  );
}

export default Button;
