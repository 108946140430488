import './styles.css';
import './../../App.css';
import Loading from '../Loading';
import { useNavigate, Link } from 'react-router-dom';

function ItemChat({title,subtitle,link,key1,is_active,onclick,isLoading,deleteItem,goToCustomChat,uuid}) {
  const navigate = useNavigate();
  return (
    <div className='row chat-card'>
    <div className='chat-card-inner' onClick={onclick} >
      <div className={is_active ? 'row chat_active ' : 'row'} >
        <div className='col-2 text-center' >
          <img className='rounded_image_model my-2' src="https://proton-resources-production.imgix.net/9e98ea9b5e8f37683a2894209607873d1f4fc42a0dfdfd35e20689987a9324da.png?orient=&auto=compress" />
        </div>
        <div className='col-10 item_model_chat pl-4' >
          <div className={is_active ? 'title_item_chat' : 'title_item_chat '} > {title}
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          {
            isLoading ?
            (
              <div style={{float: 'right' }}>
                <Loading />
              </div>
            )
            :
            (
              link==null ?
                (
                  <span className=" badge bg-warning">Pending</span>
                )
                :
                  <span className=" badge bg-success">Ready</span>
            )
          }
          </div>
          <div className='subtitle_item_chat' > {subtitle}</div>
        </div>
        
       
      </div>

    </div>
    {
      is_active && key1>0 &&
      
      <div className='trash-container pr-4' >
        <Link to= {`/gym/${title}/${subtitle}/${uuid}`} className='mt-1'>
      <i class="bx bx-plus-circle trash_btn mr-2 mt-1"  title="Add Files"></i>
      </Link>
        <Link to= {`/customize?modelId=${uuid}`} className='mt-1'>
      <i class="bx bx-export trash_btn mr-2"  title="Export to ChatBot"></i>
      </Link>
      {/* <div onClick={deleteItem} className='btn trash_btn'  ><svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 50 1960 1960" ><path d="M261 936q-24 0-42-18t-18-42V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm106-146h60V391h-60v399Zm166 0h60V391h-60v399Z"/></svg></div> */}
      {/* <img className='trash_btn' src={require('../../images/trash.png')} ></img> */}
      <i class="bx bx-trash trash_btn" onClick={deleteItem} title="Delete"></i>
    </div>
    }
    </div>
  );
}

export default ItemChat;
