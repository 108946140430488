import Header from '../../components/Header';
import './styles.css';
import { useAuth } from '../../utils';
import SideBar from '../../components/SideBar';
import { deleteModel, getCustomData, getModels, updateModel, uploadfile, addExportChat, getExportChats, getUserActiveSubscription} from '../../APIFirebase';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Chatbot from 'react-chatbot-kit';
import '../../components/bot/bot.css';
import { createChatBotMessage } from 'react-chatbot-kit';
import MessageParser from '../../components/bot/MessageParser.js';
import ActionProvider from '../../components/bot/ActionProvider';
import ChatbotHeader from '../../components/bot/header';
import FlightBotAvatar  from '../../components/bot/botAvatarIcon';
import BotChatMessage from '../../components/bot/customMessage.js';
import Select from "react-dropdown-select";


    
function CustomizeChat() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [headerColor1, setHeaderColor1] = useState('#2E7FF1');
  const [headerColor2, setHeaderColor2] = useState('#000000');
  const [chatTitle, setChatTitle] = useState('Chat');
  const [personality, setPersonality] = useState('A perfect and helpfull assistan for customers');
  const [buttonText, setButtonText] = useState('Chat with us');
  const [welcomeMessage, setWelcomeMessage] = useState('Welcome to our website, how can I help you?');
  const [logoImage, setLogoImage] = useState(null);
  const [url,_url] = useState(null);
  const [nameFileReal,_nameFileReal] = useState(null);
  const [file,_file] = useState('');
  const [nameFile,setNamefile] = useState(null);
  const [isLoading,setLoading] = useState(false);
  const params = useParams();
  const {user,setUser} = useAuth();
  const [models, setModels] = useState([]);
  const navigate = useNavigate();
  const [modelID, setmodelID] = useState(null);
  const [options, setOptions] = useState([]);
  const [_config, set_Config] = useState(null);
  const [js, setjs] = useState("");
  const [css, setcss] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [activeSubscriptionDescription, setActiveSubscriptionDescription] = useState("");
  const [needUpgrade, setneedUpgrade] = useState(false);
  //const[modelId,setModelId] = useState(null)
  var modelId

  const copyToClipboardJs = () => {
    navigator.clipboard.writeText(js)
      .then(() => {
        // Success
        return
      })
      .catch((error) => {
        // Error
        console.error('Failed to copy to clipboard:', error);
      });
  };

  const copyToClipboardCss = () => {
    navigator.clipboard.writeText(css)
      .then(() => {
        // Success
        return
      })
      .catch((error) => {
        // Error
        console.error('Failed to copy to clipboard:', error);
      });
  };

  const onDrop = useCallback((acceptedFiles) => {

    // Use the acceptedFiles argument instead of value
    const extention = acceptedFiles[0].name.split('.').pop();
  
  
    if (acceptedFiles[0].size > 10000000) {
      alert("The file is too heavy")
    } else if (extention !== "csv" && extention !== "docx" && extention !== "pdf") {
      alert("The file must be in CSV or DOCX format")
    } else {
      setLoading(true)
      _nameFileReal(acceptedFiles[0].name)
  
      const name_file = "input-" + params.uuid + "." + extention;
      setNamefile(name_file);
  
      // Move the async uploadfile call into a separate async function
      const upload = async () => {
        setLoading(true)
        const res = await uploadfile(name_file, acceptedFiles[0])
        _url(res)
        _file(acceptedFiles[0])
        setLoading(false)
      }
      upload();
    }
  }, [params, _nameFileReal, _url, _file, uploadfile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
  const updateCustomDataFunction = async () => {
    const searchParams = await new URLSearchParams(window.location.search);

    const customData = {
      logoImage,
      chatTitle,
      headerColor1,
      headerColor2
    }
    setLoading(true)
    const models = await getModels(user.docId)
    var i = 0
    var contextUrl
    for(var model in models){
      if(models[model].uuid===modelID){
          console.log("context url",models[i].link)

          contextUrl = models[i].link

      }
      i = i + 1
    }
    await addExportChat(modelID,logoImage,contextUrl,chatTitle,personality,welcomeMessage,headerColor1,headerColor2,buttonText)
    //await updateCustomData(user.docId,models,user,customData,params.chatActive)
    setLoading(false)
  }
  const resizeImage = (image) => {
    const canvas = document.createElement('canvas');
    canvas.width = 24;
    canvas.height = 24;
  
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, 24, 24);
  
    return canvas.toDataURL('image/png');
  }

  const removeItemFile =  () => {
    _nameFileReal(null);
    setNamefile();
    _file('')
    _url(null)
  }

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  useEffect(() => {
    async function updateModelSelected(){
    const export_chats = await getExportChats(modelID);
        setHeaderColor1(export_chats.headerColor1);
        setHeaderColor2(export_chats.headerColor2);
        setLogoImage(export_chats.logoImage);
        setChatTitle(export_chats.chatTitle);
        setPersonality(export_chats.personality);
        setWelcomeMessage(export_chats.welcomeMessage);
        setButtonText(export_chats.buttonText);
    }
    updateModelSelected();
    setjs(`<script type="text/javascript">
      (function(d, t) {
          var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
          // create the link element
          var link = d.createElement('link');
          link.rel = 'stylesheet';
          link.href = 'https://jigma-export-chat.web.app/static/css/main.22f864ae.css';
          // add to the head of the document
          d.getElementsByTagName('head')[0].appendChild(link);
          v.onload = function() {
            window.initChatbot("${modelID}");
          }
          v.src = "https://jigma-export-chat.web.app/static/js/main.0e503606.js";
          v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
      })(document, 'script');
    </script>`);
  setcss(`<link rel="stylesheet" href="https://sam-ai-177ab--test-chat-jigma-ii5sa6yr.web.app/static/css/main.22f864ae.css">`)
  
  }, [modelID]);

  useEffect(() => {
    if(activeSubscriptionDescription !== ""){
      //setneedUpgrade(false);
      console.log("setea");
    }
  }, [activeSubscriptionDescription])
  
  

  useEffect(() => {
    async function fetch_(){
      const searchParams = await new URLSearchParams(window.location.search);
      modelId = await searchParams.get('modelId');
      const res = await getModels(user.docId);
      setModels(res);
      const newOptions = res.map(model => ({value: model.uuid, label: model.title}));
      setOptions(newOptions);
      if(modelId){
        const selectedModel = res.find(model => model.uuid === modelId);
        if (selectedModel) {
          setSelectedOption({value: selectedModel.uuid, label: selectedModel.title}); // Set selectedOption state
        }
        const export_chats = await getExportChats(modelId);
        setmodelID(modelId);
        setHeaderColor1(export_chats.headerColor1);
        setHeaderColor2(export_chats.headerColor2);
        setLogoImage(export_chats.logoImage);
        setChatTitle(export_chats.chatTitle);
        setPersonality(export_chats.personality);
        setWelcomeMessage(export_chats.welcomeMessage);
        setButtonText(export_chats.buttonText);
      }
      const uid = await localStorage.getItem('uid');
      if (uid){
        const subscriptions = await getUserActiveSubscription(uid)
        if(subscriptions.length>0){
          const subscription_description = subscriptions[0].items[0].price.product.description
          setActiveSubscriptionDescription(subscription_description)
        }
      }
    }
    fetch_()
  },[]);

  useEffect(() => {
    const config = {
      initialMessages: [
        createChatBotMessage(welcomeMessage),
      ],
      customComponents: {
        // Replaces the default header
        header: (props) => <ChatbotHeader {...props} title={chatTitle} headerColor={headerColor1}/>,
      },
      customStyles: {
        // Overrides the chatbot message styles
        botMessageBox: {
          backgroundColor: headerColor1,
        },
        // Overrides the chat button styles
        chatButton: {
          backgroundColor: headerColor2,
        },
      },
    };
    set_Config(config);
  
  }, [])
  
  

  useEffect(() => {
    const config = {
      initialMessages: [
        createChatBotMessage(welcomeMessage),
      ],
      customComponents: {
        // Replaces the default header
        header: (props) => <ChatbotHeader {...props} title={chatTitle} headerColor={headerColor1}/>,
        botAvatar: (props) => <FlightBotAvatar  {...props} avatarColor={headerColor1} />,
        botChatMessage: (props) => <BotChatMessage {...props} containerColor={headerColor1} />,
      },
      customStyles: {
        // Overrides the chatbot message styles
        botMessageBox: {
          backgroundColor: 'rgb(242, 246, 249)',
        },
        // Overrides the chat button styles
        chatButton: {
          backgroundColor: headerColor2,
        },
      },
    };
    set_Config(config);
    
  }, [welcomeMessage,chatTitle,headerColor1,headerColor2]);

  const handleOptionChange = (selected) => {
    setSelectedOption(selected);
    setmodelID(selected[0].value);
  };
  
  const validateInput = (message) => {
    return false;
  }

  const {onLogout} = useAuth();
  return (
    <div className="app-web">
      <SideBar isSidebarOpen={isSidebarOpen} />
      <div className="content-static" style={{overflowX:"scroll"}}>
        <Header title="Profile" onToggleSidebar={toggleSidebar} profile_active={0} gym_active={0} back_active={0} edit_active={1}/>
        
      
        {
          needUpgrade ? (
            <div className="upgrade-plan">
            <div className="upgrade-plan-content">
                <h2>Expand Your Access!</h2>
                <p>Unlock more features and capabilities with our premium plan.</p>
                <Link to="/payments" >
                <button className="upgrade-button" >Upgrade Now!</button>
                </Link>
            </div>
        </div>
          ):
          (
            <div className='content-dynamic-customize'>
            <div className='control-panel'>
        
              <h2 className='control-panel-title'>Step 1: Customize Your Chat</h2>
              <span style={{textAlign:'left'}}>Select one assistant to export as a chatbot. You can see a preview of the chatbot on the bottom right corner </span>
                <div className='chat-selector'>
                    <label className='assistant-label'>Your Assistants:</label> 
                    {options.length > 0 ? (
                      <Select 
                        className='chat-dropdown' 
                        options={options} 
                        defaultValue={selectedOption} 
                        placeholder={selectedOption.label} 
                        onChange={handleOptionChange}
                      />
                    ) : (
                      <p>Loading options...</p>
                    )}      </div>
              <div className='control-panel-row mt-3'>
                <label htmlFor='color-input' className='control-panel-label'>Header Color</label>
                <input
                  id='color-input'
                  type='color'
                  value={headerColor1}
                  onChange={(e) => setHeaderColor1(e.target.value)}
                  className='control-panel-input'
                />
              </div>
              
            
              <div className='control-panel-row'>
                <label htmlFor='chat-title-input' className='control-panel-label m-0 pr-2'>Chatbot Name:</label>
                <input type='text' value={chatTitle} onChange={(e) => setChatTitle(e.target.value)} id='chat-title-input-preview' className='chat-title-input-preview' />
                </div>
                <div className='control-panel-row'>
                <label htmlFor='chat-title-input' className='control-panel-label m-0 pr-2'>Personality of your chatbot:</label>
                <input type='text' value={personality} onChange={(e) => setPersonality(e.target.value)} id='chat-title-input-preview' className='chat-personality-input-preview' />
                </div>
                <div className='control-panel-row'>
                <label htmlFor='chat-title-input' className='control-panel-label m-0 pr-2'>Welcome message:</label>
                <input type='text' value={welcomeMessage} onChange={(e) => setWelcomeMessage(e.target.value)} id='chat-title-input-preview' className='chat-welcome-input-preview' />
                </div>
                <div className='control-panel-row'>
                <label htmlFor='chat-title-input' className='control-panel-label m-0 pr-2'>Chat opening button text:</label>
                <input type='text' value={buttonText} onChange={(e) => setButtonText(e.target.value)} id='chat-title-input-preview' className='chat-welcome-input-preview' />
                </div>
            {
              isLoading ?
              (
                <label  className='control-panel-label m-0 pr-2'>Loading..</label>
              )
              :
              (
                <div>
                <div  onClick={updateCustomDataFunction}  className='btn btn-success text-white'>Update </div>
                </div>
              )
              
            }
            
                <h2 className='control-panel-title mt-4'>Step 2: Copy integration script</h2>
                <span style={{textAlign:'left'}}>Copy this code snippet and paste it before the closing body tag on every page you want the chatbot to appear </span>
                <div className='script-container'>
              <SyntaxHighlighter
                language="javascript" // Replace "javascript" with the appropriate language for your script
                style={vscDarkPlus} // Use any desired style from the available options
                className='script-js-area'
              >
                {js}
              </SyntaxHighlighter>
              <button
                className='copy-js-button'
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  padding: '8px',
                  fontSize: '16px',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                onClick={copyToClipboardJs}
                >
                  Copy
              </button>
            </div>

                  
                      {/* <span className='embed-title'>Embed Code</span>
                      <textarea className='embed-code-box'></textarea> */}
                    
                </div>
                <div className='chatbot-outside-container'>
                  <Chatbot
                    config={_config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                    validator={validateInput}
                  />
                </div>
                </div>
          )
        }
      
          
      
      </div>
  </div>
  );
}

export default CustomizeChat;
