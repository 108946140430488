import {  useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkStatus, getAnswer } from '../../API';
import { addChatToUser,deleteModel, updateModelLink, getModels, removeLastChatOfUser, getExportChats, addChatMessageToExportChat, addChatToExportChat, validateUserKey, getUserCredits, discountCredits} from '../../APIFirebase';
import ItemChatModel from '../../components/ItemChatModel';
import Loading from '../../components/Loading';
import { getDate, useAuth } from '../../utils';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import './styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { validate } from 'json-schema';

    
function ChatExport() {
    const {user,setUser} = useAuth();
    const [chatActive,_chatActive] = useState(0);
    const [count, setCount] = useState(0);
    const [showModal, _showModal] = useState(false);
    const [modalAlreadyShowed, _modalAlreadyShowed] = useState(false);
    const [msg, _msg] = useState("");
    const [isLoadingModels, setLoadingModels] = useState(1);
    const [isLoading, setLoading] = useState(1);
    const [countChat, setCountChat] = useState(0);
    const [existPending, _existPending] = useState(false);
    const [models, setModels] = useState([]);
    const [isFirstTime, _isFirstTime] = useState(true);
    const [headerColor1, setHeaderColor1] = useState('#ffffff');
    const [headerColor2, setHeaderColor2] = useState('#ffffff');
    const [chatTitle, setChatTitle] = useState('Chat');
    const [logoImage, setLogoImage] = useState(null);
    const [userChat, setUserChat] = useState([]);
    const [validUser,setValidUser] = useState(false)
    const [credits, setCredits] = useState(null);
    const [uuid, setUUID] = useState(null);
    const navigate = useNavigate()
    //var modelId
    var modelOrder
    //var uuid
    //var userId
    const [userId,setUserId] = useState(null);
    const [modelId,setModelId] = useState(null)
    const [question,setQuestion] = useState(null)
    //var userChat
    //var models
    
    const chatWindowRef = useRef(null);
    const ps = useRef();
    const { transcript, listening } = useSpeechRecognition();
    // console.log("asd",context.user.models[chatActive].link)
    const addQuestion = async () => {
      console.log("UUID",uuid)
      const coins = await getUserCredits(uuid);
      if (isLoading)
        return;
      if (msg === ""){
        alert("The message is empty");
      }else if( models.length === 0 ){
        alert("You need to add a new model first");
      }else if( credits <= 0 ){
        alert("You have no credits. Buy more at the Get More Credits section.");
      }else if(models[chatActive].link === null && existPending){
        alert("This model is training yet, you need to wait for it to finish")
      }else if(models[chatActive].link === null){
        alert("You do not have a file for this model, remove the chat and try again")
      }else if(userChat.chat.length > 100){
        alert("sorry, you already reached 100 messages")
      }else if(!navigator.onLine){
        alert("Check your internet conection")
      }
      else {
        setLoading(true)  
  
        // Create a Date object using the current timestamp
        const dateObject = new Date();

        // Function to get the UTC offset string (e.g., UTC-3)
        function getUTCOffsetString(date) {
          const offsetInMinutes = date.getTimezoneOffset();
          const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
          const offsetMinutes = Math.abs(offsetInMinutes) % 60;
          const sign = offsetInMinutes < 0 ? '+' : '-';
          return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
        }

        // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
        const formattedDate = `${dateObject.toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })} ${getUTCOffsetString(dateObject)}`;

        const aux = {
          title: msg,
          date: formattedDate
        };
        // console.log(user)
        //await addChatToUser(aux,user.docId,models,chatActive)
        console.log("addQuestion",aux,userId,modelId)
        await addChatMessageToExportChat(aux,userId,modelId)
        setQuestion(aux.title)
        _msg("")
        setCountChat((x) => x+1)
        setCredits(coins);
      }
    }
    

    useEffect(() => { //VALIDATE USER BY ACCESS KEY
      async function validateUser(){
        const searchParams = await new URLSearchParams(window.location.search);
        var _modelId = await searchParams.get('modelId')
        const newArray = _modelId.split('-')
        var _uuid = newArray[0]
        await setUUID(_uuid)
        //const accessKey = new URLSearchParams(window.location.search).get('X-Access-Key');
        //const accessKey = window?.headers?.get('X-Jigma-Key');
        const accessKey = "eyJhbGciOiJmtpZCI6IjU0NWUyNDZjNTEwNmExMGQ2MzFiMTA0M2E3MWJiNTllNWJhMGM5NGQiLCJ0eXAiOiJKV1QifQ"
        //console.log("token",accessKey)
        if(accessKey){
          const valid = await validateUserKey(_uuid,accessKey)
          console.log("valid?",valid)
          if(valid==true){
            setValidUser(true)
          }
        }else{
          setValidUser(false)
        }
        //setValidUser(true)
      }
      validateUser()
    },[])

    useEffect(() => { //GET MODELS
      async function getData(){
        const searchParams = await new URLSearchParams(window.location.search);
        var _modelId = await searchParams.get('modelId')
        setModelId(_modelId)
        var _userId = await searchParams.get('userId')
        setUserId(_userId)
        //const newArray = _modelId.split('-')
        //uuid = newArray[0]
        const _models = await getModels(uuid)
        await setModels(_models)
        //models = _models
        const coins = await getUserCredits(uuid);
        await setCredits(coins);
        var i = 0
        for(var model in _models){
          if(_models[model].uuid===_modelId){
            modelOrder = i
            _chatActive(modelOrder)
          }
          i = i + 1
        }
        if(_modelId){
          const export_chats = await getExportChats(_modelId)
          const chats = export_chats.chats
          var found = false
          for(var chat in chats){
            if(chats[chat].uuid==_userId){
              //userChat = chats[chat]
              setUserChat(chats[chat])
              found = true
            }
          }
          if(found==false){
            const _userChat = await addChatToExportChat(_userId,_modelId)
            setUserChat(_userChat)
          }
          setHeaderColor1(export_chats.headerColor1);
          setHeaderColor2(export_chats.headerColor2);
          setLogoImage(export_chats.logoImage);
          setChatTitle(export_chats.chatTitle);
        }
        //const res = await getModels(user.docId);
        //await setModels(_models)
        setLoadingModels(false)  
      }
      if(validUser==true){
        getData()
      }
      
    },[validUser])
    
    useEffect(() => { //Scroll to the bottom when new message is send
      
      console.log("user chat use effect",userChat) 
      

    }, [userChat]);


    /////////////////////////////////////////////////
    useEffect(() => { //Scroll to the bottom when new message is send
    
      if (chatWindowRef.current) {
        const curr = ps.current;
        if (curr) {
          const scrollHeight = curr.scrollHeight;
          const start = curr.scrollTop;
          const end = scrollHeight - curr.clientHeight;
          const duration = 500; // Adjust this value to control the scrolling speed
  
          let startTime = null;
  
          const scrollAnimation = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const scrollValue = easeInOutQuad(elapsed, start, end - start, duration);
            curr.scrollTop = scrollValue;
            
            if (elapsed < duration) {
              requestAnimationFrame(scrollAnimation);
            }
          };
  
          const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
          };
  
          requestAnimationFrame(scrollAnimation);
        }
      }
    }, [countChat,isLoading]);
  
    useEffect(() => {
    setTimeout(() => {
      if (chatWindowRef.current) {
        const curr = ps.current;
        if (curr) {
          const scrollHeight = curr.scrollHeight;
          const start = curr.scrollTop;
          const end = scrollHeight - curr.clientHeight;
          const duration = 500; // Adjust this value to control the scrolling speed
  
          let startTime = null;
  
          const scrollAnimation = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const scrollValue = easeInOutQuad(elapsed, start, end - start, duration);
            curr.scrollTop = scrollValue;
            
            if (elapsed < duration) {
              requestAnimationFrame(scrollAnimation);
            }
          };
  
          const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
          };
  
          requestAnimationFrame(scrollAnimation);
        }
      }
    }, 500);
  }, []);

  //////////////////////////////////////////
  
    useEffect(() => { //Scroll to the bottom when new message is send
      async function getData(){
        if(modelId){
          const export_chats = await getExportChats(modelId)
          const chats = export_chats.chats
          console.log("chats",chats)
          for(var chat in chats){
            console.log("chat[i]",chat,chats[chat])
            if(chats[chat].uuid==userId){
              //userChat = chats[chat]
              setUserChat(chats[chat])
              console.log("user chat",userChat)
            }
          }
          setHeaderColor1(export_chats.headerColor1);
          setHeaderColor2(export_chats.headerColor2);
          setLogoImage(export_chats.logoImage);
          setChatTitle(export_chats.chatTitle);
        }
        //const res = await getModels(user.docId);
        //await setModels(_models)
        setLoadingModels(false)  
      }
      getData()
    }, [countChat]);
    
    useEffect( () => { //check if is pending a model link
      // console.log("EFFECT")
      async function fetchh(uuid){
        
        const res = await checkStatus(uuid)
        console.log("respuesta a check status",res.data)
        if (res.data.state ){
          await updateModelLink(user.docId,models,res.data.model_url)
          const _models = models;
          // console.log("MODEL TO UPDATE ",models)
          _models[_models.length-1].link = res.data.model_url
          setUser({
            ...user,
            hasUnfinishModel: false
          })
          _existPending(false)
        }
        else{
          // console.log("SET")
          setTimeout(() => {
            setCount(count+1)
          },4000)
        }
  
      }
      async function getData(){
        const res = await getModels(user.docId)
        return res;
      }
      getData().then((data) => {
        if (data.length>0 && data[data.length-1].link==null){
          _existPending(true)
          if (!modalAlreadyShowed){
            _showModal(true)
          }
          fetchh(data[data.length-1].uuid)
        }else{
          _existPending(false)
        }
      });
      
    },[count,modalAlreadyShowed,models,user,setUser])
  
  
    useEffect(() => { //sending a question to get a answer
      async function fetchData(){
        try {
          // console.log("making qs",chatActive)
          const context_url = models[chatActive].link;
          var personality = models[chatActive].personality;
          // const context_url = "https://drive.google.com/file/d/1WxtJF_kfJi8sbl31WOvb1I8woxU6FHlQ/view?usp=share_link";
          // console.log("making qs",model[chatActive].chat)
          let body = null
          const length_chats = userChat.chat.length
          if (length_chats>3){
            const questions  = userChat.chat.slice(length_chats-5,length_chats-1).filter((e,i) => i%2 === 0).map((x) => x.title);
            const answers  = userChat.chat.slice(length_chats-4,length_chats).filter((e,i) => i%2 === 0).map((x) => x.title);
            // console.log(`questions: ${questions}`)
            // console.log(`answers: ${answers}`)
            body = {
              questions,answers
            }
          }
          
          var response
          var modelOpenAI = "GPT3.5"
          console.log("QUESTION",question)
          //console.log("QUESTION",userChat.chat[userChat.chat.length-1].title)
          if (personality == null){
            personality = "an assistant"
            response = await getAnswer(context_url,personality,question,body,modelOpenAI)
          }else{
            response = await getAnswer(context_url,personality,question,body,modelOpenAI)
          }
          //const response = await getAnswer(context_url,models[chatActive].chat[models[chatActive].chat.length-1].title,body)
        
          if (response.data){
            // Create a Date object using the current timestamp
            const dateObject = new Date();

            // Function to get the UTC offset string (e.g., UTC-3)
            function getUTCOffsetString(date) {
              const offsetInMinutes = date.getTimezoneOffset();
              const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
              const offsetMinutes = Math.abs(offsetInMinutes) % 60;
              const sign = offsetInMinutes < 0 ? '+' : '-';
              return `UTC${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
            }

            // Format the date as "July 25, 2023, 3:10:07 PM UTC-3"
            const formattedDate = `${dateObject.toLocaleString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })} ${getUTCOffsetString(dateObject)}`; 

            const aux = {
              title: response.data.answer,
              date: formattedDate
            }
            // console.log("ANSSS ",response.data)
            //await addChatToUser(aux,user.docId,models,chatActive)
            console.log("addQuestion use effect",aux,userId,modelId)
            await addChatMessageToExportChat(aux,userId,modelId)
            // addToChat(aux,chatActive)
          }
          await discountCredits(uuid,modelOpenAI);
          const coins = await getUserCredits(uuid);
          setCredits(coins);
          setCountChat((x) => x+1)
          setLoading(false)
          
        } catch (error) {
          // console.log("ERROR ",error)
          removeLastChatOfUser(user.docId,models,chatActive)
          setLoading(false)
          if (error?.response?.status == 429){
            alert(error.response.data.message+"("+error.response.data.seconds_remaining+" seconds)")
          }else alert(error.message)
  
        }
      }
      if (isLoading){
        if (!isFirstTime && userChat.chat.length>0 && userChat.chat.length%2 === 0 )
          fetchData();
        else {
          _isFirstTime(false)
          setLoading(false)  
        }
      }
    },[countChat])
  
    useEffect(() => {//Set the text of the transcription to the input
      _msg(transcript)
    }, [transcript])
  
    const handleListen = () => {//Starts speech recognition while talking
      if (!listening) {
        SpeechRecognition.startListening();
      } else {
        SpeechRecognition.stopListening();
  
      }
    };
  
    const changeActive = (idx) =>  {
      _chatActive(idx)
    }
  
  
    // useEffect(() => {
    //   if (user.models.length>0){
    //     addMessageChat(user.models[chatActive].chat)
  
    //     if (user.models[user.models.length-1].link){
    //       _idChatUnfinish(user.models[user.models.length-1].uuid)
    //     }
    //   }
    // },[chatActive])
    // useEffect(() => {
    //   // console.log("HOLIS",context)
      
    // },[])
  
  
    const _deleteModel = (idx) => {
      
      if (window.confirm('Are you sure you wish to delete this model?')) {
        const _models = models;
        if (_models.length-1 === idx){
          _existPending(false)
        }
        if (idx>0){
          _chatActive(idx-1)
        }else {
          _chatActive(null)
        }
        
        _models.splice(idx,1)
        //setModels(_models)
        setUser({
          ...user,
          hasUnfinishModel: false
        })
        deleteModel(user.docId,models,user)
  
      } 
    }
    
  
  
    // const updateState = useCallback(async () => {
    //   // if (context.user.models[context.user.models.length-1].link){
    //     // console.log(context.user)
    //      const response = await checkStatus(idChatUnfinish);
    //      if (response && response.data.state == true){
    //       updateModel()
  
    //      }
    //   // }
      
    // }, []);
  
  
    // useEffect(() => {
      
    //   setInterval(updateState, 3000);
    // }, [updateState]);
    const toggleRef = useRef(null);
    const modelMenuRef = useRef(null);
    const chatRef = useRef(null);
    const navBarRef = useRef(null);
  
    function toggleElements() {
      const modelMenuShown = !modelMenuRef.current.classList.contains('show');
      modelMenuRef.current.classList.toggle('show');
      navBarRef.current.classList.toggle('w-100', modelMenuShown);
      chatRef.current.classList.toggle('d-none');
    }
    
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    function toggleSidebar() {
      setIsSidebarOpen(!isSidebarOpen);
    }



  return (
    <section className='chatbox1 m-0 row navbar-window show' id='chat' ref={chatRef}>
            <div className='box-chat-container p-0'>
            <div className='export-chat-header-preview' style={{ background: `linear-gradient(to right, ${headerColor1}, ${headerColor2})` }}>
                {logoImage ? (
                  <img src={logoImage} alt='Logo' className='logo-image' />
                  ) : (
                  <i className='bx bxs-chat features-item-icon'></i>
                  )}

                    <span className='ml-1 font-inter text-white'>{chatTitle}</span>
                    <i class='bx bx-x close-chat-preview'></i>
                </div>
               {
             userChat?.chat?.length>0 ?
             (  
              <PerfectScrollbar style={{ height: '80svh', marginTop: '5px'}} containerRef={el => (ps.current = el)} >
                  
              <div className='chat-window1' ref={chatWindowRef}>
                     {
                       userChat?.chat.map((e,i) => {
                         return (
                           <ItemChatModel key={i} is_ans={i%2 !== 0} {...e} />
                         )
                       })
                     }
                     {
                      isLoading &&
                      <div className='text-center mb-4' ><div class="dot-flashing"></div></div>
                     }
                     
                </div>
                </PerfectScrollbar>
            
             )
             :
             (
                 <div className='container my-5' >
                   <div className='row justify-content-center'  >
                     <div className='col-11  ' >
                       <div className='row text-center mb-5' >
                         <div className='col-12' >
                           <img className='image_card_model my-2' alt="No chat started" src="https://proton-resources-production.imgix.net/59fc0aed21ac16c3809c0c2b79373b442be6eac8c6d2326229f1ab1712e03853.png?orient=&auto=compress" />
                         </div>
                         <div className='col-12' >
                           <div className='title_card' >
                             No Chat started
                           </div>
                         </div>
                         <div className='col-12' >
                           <div className='sub_title_card' >
                             Send a new message and star a new conversation!
                           </div>
                         </div>
                         
                         
                       </div>
                     </div>
                   </div>
                 </div>
             )
             
           }
               
                  <div className='chat-input-holder1'>
                  <textarea 
                    disabled={isLoading} 
                    placeholder='Chat with your AI!' 
                    name="msg" 
                    value={msg} 
                    onChange={(value) => { 
                      _msg(value.target.value)
                      value.target.style.height = 'auto';
                      value.target.style.height = value.target.scrollHeight + 'px';
                    }} 
                    onKeyDown={(event) => {
                    if (event.keyCode === 13 && !isLoading) {
                      addQuestion();
                    }
                    }} 
                    className='chat-input-textarea1' 
                    style={{ resize: 'none', overflow: 'hidden' }} 
                    rows={1}>
                    </textarea>
                    <a  onClick={handleListen} >
                      <i class='bx bx-microphone button-mic-transcription'></i>
                    </a>                   
                    <a  onClick={addQuestion} >
                      <i class='bx bx-send button_send_chat'></i>
                    </a>
                  </div>
                </div> 
              
        </section> 
  );
}

export default ChatExport;
