import Card from '../../components/CardLogin';
import './styles.css';

import {  useState } from 'react';
import { useAuth } from '../../utils';
function Login() {
  const [isLoading, setLoading] = useState(false);
 
  const {onLogin} = useAuth();
  const submit = async (email,password) => {
    setLoading(true)

    if (email===""){
      alert("you need to define a email")
      setLoading(false)

    }else if(password===""){
      alert("you need to define a password")
      setLoading(false)

    }else {
      const res = await onLogin(email, password)
      if (res!==true){
        setLoading(false)
        alert(res)
      }

    }
    setLoading(false)

  }

  return (
    <div className="login ">
      <div className='container py-5' >
        <div className='col justify-content-center logo-image ' >
          <h2 className='title_login text-center h2' >  Jigma</h2>
        </div>
        <div className='row justify-content-center my-2' >
          <div className='row justify-content-center' >
            <div className='col-md-6 col-10 col-lg-4 row' >
              <Card submit={submit} isLoading={isLoading} title="Login" />
            </div>
          </div>
        </div>
      </div>
      <footer className='footer' >
      </footer>
    </div>
  );
}

export default Login;
