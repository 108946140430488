import React, { useCallback } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../utils';
import QRCode from "qrcode.react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Select from "react-dropdown-select";
import Swal from 'sweetalert2';

import { getModels, uploadfile, getUserCredits, getExportChats, newDiscountCredits } from '../../APIFirebase';
import { calculateTokens, createModelNewVersion, updateModelNewVersion, deleteExportChat} from '../../API';

import business from '../../Assets/personalityAvatars/business.png';
import customerService from '../../Assets/personalityAvatars/customerService.png';
import legal from '../../Assets/personalityAvatars/legal.png';
import custom from '../../Assets/personalityAvatars/custom.png';
import chef from '../../Assets/personalityAvatars/chef.png';
import doctor from '../../Assets/personalityAvatars/doctor.png';
import organizer from '../../Assets/personalityAvatars/organizer.png';
import travel from '../../Assets/personalityAvatars/travel.png';
import priest from '../../Assets/personalityAvatars/priest.png';
import './styles.css';
import Loading from '../../components/Loading';
import NewHeader from '../../components/NewHeader';
import NewFooter from '../../components/NewFooter';
import NotificationBar from '../../components/NotificationBar';
import { colorBrewer } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function TrainChatbot() {
    const {user,setUser, onLogout} = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    //Variables for upload files
    const [url,_url] = useState(null);
    const [nameFileReal,_nameFileReal] = useState([]);
    const [file,_file] = useState([]);
    const [nameFile,setNamefile] = useState([]);
    const [isLoading,setLoading] = useState(false);

    ///Variables for chatbot training
    const [personality, setPersonality] = useState("");
    const [chatTitle, setChatTitle] = useState('MyCustomerAssistant');
    const [customPersonality, setcustomPersonality] = useState("You are a fitness coach. Use the knowledge I have provided to assist gym-goers.");
    const [selectedColor, setSelectedColor] = useState('#000000');
    const [welcomeMessage, setWelcomeMessage] = useState('Hello! How may I assist you today?');
    const [buttonText, setbuttonText] = useState("Chat with me!");
    const [additionalText, setadditionalText] = useState("");
    ///Dropdown
    const [options, setOptions] = useState([]);
    const [selectedChatbot, setSelectedChatbot] = useState([]);
    const [modelID, setmodelID] = useState(null);
    const [loadCustomizations, setloadCustomizations] = useState(false);
    const [context_url, setcontext_url] = useState(null);
    ///Others
    const [selectedId, setSelectedId] = useState(null);
    const [readyToTrain, setReadyToTrain] = useState(false);
    const [iframeUrl, setiframeUrl] = useState(null);
    const [trainSuccessfull, setTrainSuccessfull] = useState(false);
    const [activeTab, setActiveTab] = useState('share');
    const [activeTabDrop, setActiveTabDrop] = useState('dropFile');
    const [js, setjs] = useState("");
    const [isUpdate, setisUpdate] = useState(false);
    const [credits, setcredits] = useState(null);
    const [creditsUsed, setcreditsUsed] = useState(null);
    const [isHumanAssistanceEnabled, setHumanAssistance] = useState(false);
    const [iframeKey, setIframeKey] = useState(0);
    const qrRef = useRef(null);
    const [tokensLimitReached, settokensLimitReached] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
      async function fetch(){
        const export_chats = await getExportChats(modelID);
        setSelectedColor(export_chats.headerColor1);
        setChatTitle(export_chats.chatTitle);
        setcustomPersonality(export_chats.personality);
        setWelcomeMessage(export_chats.welcomeMessage);
        setbuttonText(export_chats.buttonText);
        setSelectedId(9);
        setiframeUrl(`https://jigma-chat.web.app/?modelId=${modelID}`);
        setcontext_url(export_chats.contextUrl);
        setjs(`<script type="text/javascript">
              (function(d, t) {
                  var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
                  // create the link element
                  var link = d.createElement('link');
                  link.rel = 'stylesheet';
                  link.href = 'https://jigma-export-chat.web.app/static/css/main.22f864ae.css';
                  // add to the head of the document
                  d.getElementsByTagName('head')[0].appendChild(link);
                  v.onload = function() {
                    window.initChatbot("${modelID}");
                  }
                  v.src = "https://jigma-export-chat.web.app/static/js/main.0e503606.js";
                  v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
              })(document, 'script');
            </script>`);
      }
      if (selectedChatbot.value !== "new") {
        fetch();
      }
    }, [isUpdate,selectedChatbot]);
    


    useEffect(() => {
      if (selectedId !== null && (nameFileReal.length > 0 || additionalText !== "")) {
          setReadyToTrain(true);
      } else {
          setReadyToTrain(false);
      }
  }, [selectedId, nameFileReal, additionalText]);  

    useEffect(() => {
        switch(selectedId) {
          case 1:
            setPersonality("You are a highly trained business professional with extensive knowledge in finance, marketing, and strategy. Your focus is on providing data-driven advice to help users make informed decisions in their professional lives.");
            break;
          case 2:
            setPersonality("As a customer service representative, you prioritize user satisfaction above all. You're friendly, approachable, and always eager to resolve any issues that the user may have. Your primary objective is to provide swift and efficient solutions.");
            break;
          case 3:
            setPersonality("You are a well-versed legal assistant with a comprehensive understanding of laws and regulations. You help users by providing general legal information, clarifying complex terms, and guiding them through legal processes. Note that you don't give legal advice but can offer useful insights.");
            break;
          case 4:
            setPersonality("You are a seasoned traveler and a walking encyclopedia of travel tips, destinations, and cultural know-how. Your aim is to help users plan the perfect trip by offering recommendations on places to visit, activities to try, and foods to eat");
            break;
          case 5:
            setPersonality("You are an excellent chef that knows all the recipes in the world. You have all the information and tips needed to cook the best food. Your demeanor is gentle, and you passionately help people improve their cooking skills.");
            break;
          case 6:
            setPersonality("As a medical expert, you have a wealth of knowledge about healthcare and medicine. You offer general health tips, describe common medical conditions, and provide guidelines for a healthy lifestyle. Note that you can't give medical advice, but you can offer useful information.");
            break;
          case 7:
            setPersonality("You are a meticulous organizer, skilled in time management and project coordination. You love helping users sort out their schedules, set reminders, and keep track of their tasks. Your ultimate goal is to make the user's life more organized and less stressful.");
            break;
          case 8:
            setPersonality("As a spiritual guide, your focus is on moral and ethical guidance. You offer words of wisdom, comfort in times of need, and provide insights into various religious and spiritual questions. Your main objective is to help users find peace and spiritual fulfillment.");
            break;
          case 9:
            setPersonality(customPersonality);
            break;
          default:
            setPersonality(""); // Set to empty string or some default value if selectedId doesn't match any cases
            break;
        }
      }, [selectedId,customPersonality]);

      useEffect(() => {
        async function fetch_(){
          console.log(user.docId);
          const res = await getModels(user.docId);
          //setModels(res);
          const newOptions = res.map(model => ({value: model.uuid, label: model.title}));
          const defaultOption = {value: "new", label: "New Chatbot"};
          newOptions.unshift(defaultOption);
          setOptions(newOptions);
         /* if(modelId){
            const selectedModel = res.find(model => model.uuid === modelId);
            if (selectedModel) {
              setSelectedOption({value: selectedModel.uuid, label: selectedModel.title}); // Set selectedOption state
            }
            const export_chats = await getExportChats(modelId);
            setmodelID(modelId);
            setHeaderColor1(export_chats.headerColor1);
            setHeaderColor2(export_chats.headerColor2);
            setLogoImage(export_chats.logoImage);
            setChatTitle(export_chats.chatTitle);
            setPersonality(export_chats.personality);
            setWelcomeMessage(export_chats.welcomeMessage);
            setButtonText(export_chats.buttonText);
          }
          const uid = await localStorage.getItem('uid');
          if (uid){
            const subscriptions = await getUserActiveSubscription(uid)
            if(subscriptions.length>0){
              const subscription_description = subscriptions[0].items[0].price.product.description
              setActiveSubscriptionDescription(subscription_description)
            }
          }*/
        }
        fetch_()
      },[]);

    useEffect(() => {
      if(credits === 0){
        Swal.fire({
          icon: 'warning',
          title: '0 credits',
          text: 'You ran out of credits',
          footer: '<a href="/payments">Buy more credtis</a>'
        })
      }
    }, [credits]);
    

    useEffect(() => {
      async function getCredits(){
        console.log("retrieve credits from firebase");
        const coins = await getUserCredits(user.docId);
        setcredits(coins);
        await localStorage.setItem("credits",coins);
      }
      const creditCheck = localStorage.getItem("credits");
      if(creditCheck === null){
        getCredits();
      }else{
        setcredits(creditCheck);
      }
    }, []);

    useEffect(() => {
      async function getCredits(){
        const coins = await getUserCredits(user.docId);
        setcredits(coins);
        await localStorage.setItem("credits",coins);
      }
      getCredits();

    }, [trainSuccessfull]);
    

    useEffect(() => {
      if(!trainSuccessfull){
        setPersonality("");
        setChatTitle('Chat');
        setcustomPersonality("");
        setSelectedColor('#ffffff');
        setWelcomeMessage('');
        setbuttonText("");
        _url(null);
        _nameFileReal([]);
        _file([]);
        setNamefile([]);
        setSelectedChatbot([]);
        setmodelID(null);
        setloadCustomizations(false);
        setSelectedId(null);
        setReadyToTrain(false);
        setiframeUrl(null);
        setTrainSuccessfull(false);
        setActiveTab('share');
        setjs("");
        setisUpdate(false);
        setadditionalText("");
        setHumanAssistance(false);
      }
    }, [trainSuccessfull]);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    }

    const Popup = () => (
      <div className="popup">
          <div className="popup-inner">
              <button onClick={togglePopup} className="close-btn" style={{backgroundColor:'transparent'}}>x</button>
              <iframe 
                  src="https://www.youtube.com/embed/l6hlpr4n95s" 
                  title="YouTube video" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen>
              </iframe>
          </div>
      </div>
    );

    const submit = async () => {
      console.log("creditos disponibles: ",credits," Creditos a utilizar: ",creditsUsed);
      if(tokensLimitReached === true){
        Swal.fire({
          icon: 'error',
          title: 'Token Limit Surpassed',
          text: 'Your files reach the limit of tokens. Reduce the amount of tokens',
        });
        setLoading(false);
        return;
      }
      if(credits > creditsUsed){
        if(!isUpdate){
          try {
              console.log("el texto adicional es: ",additionalText);
              const additionalTextWithSpaces = additionalText.replace(/\n/g, " ");
              const encodedColor = encodeURIComponent(selectedColor);
              let finalWelcomeMessage;
              if(isHumanAssistanceEnabled === true){
                finalWelcomeMessage = `${welcomeMessage}\n If you ever need personalized support from one of our team members, simply type 'Human Assistance,' and we'll connect you with someone right away.`;
              }else{
                finalWelcomeMessage = welcomeMessage;
              }
              const res = await createModelNewVersion(user.docId,user.docId,chatTitle,chatTitle,personality,finalWelcomeMessage,encodedColor,encodedColor,buttonText,additionalTextWithSpaces,file);
              //const res = await createFreeModel(personality,chatTitle,file,"");
              setLoading(false);
              console.log(res);
              console.log(res.data);
              console.log(res.data.export_chat_id);
              await newDiscountCredits(user.docId,creditsUsed);
              setTrainSuccessfull(true);
              setiframeUrl(`https://jigma-chat.web.app/?modelId=${res.data.export_chat_id}`);
              setjs(`<script type="text/javascript">
              (function(d, t) {
                  var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
                  // create the link element
                  var link = d.createElement('link');
                  link.rel = 'stylesheet';
                  link.href = 'https://jigma-export-chat.web.app/static/css/main.22f864ae.css';
                  // add to the head of the document
                  d.getElementsByTagName('head')[0].appendChild(link);
                  v.onload = function() {
                    window.initChatbot("${res.data.export_chat_id}");
                  }
                  v.src = "https://jigma-export-chat.web.app/static/js/main.0e503606.js";
                  v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
              })(document, 'script');
            </script>`);
            const res1 = await getModels(user.docId);
            //setModels(res);
            const newOptions = res1.map(model => ({value: model.uuid, label: model.title}));
            const defaultOption = {value: "new", label: "New Chatbot"};
            newOptions.unshift(defaultOption);
            setOptions(newOptions);
          } catch (error) {
              console.log(error);
        }
        }else{
          const additionalTextWithSpaces = additionalText.replace(/\n/g, " ");
          const encodedColor = encodeURIComponent(selectedColor);
          const res = await updateModelNewVersion(user.docId,chatTitle,personality,welcomeMessage,encodedColor,encodedColor,buttonText,context_url,modelID,additionalTextWithSpaces,file);
          //const res = await createFreeModel(personality,chatTitle,file,"");
          setLoading(false);
          console.log(res);
          console.log(res.data);
          console.log(res.data.export_chat_id);
          await newDiscountCredits(user.docId,creditsUsed);
          setTrainSuccessfull(true);
          setiframeUrl(`https://jigma-chat.web.app/?modelId=${res.data.export_chat_id}`);
          setjs(`<script type="text/javascript">
          (function(d, t) {
              var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
              // create the link element
              var link = d.createElement('link');
              link.rel = 'stylesheet';
              link.href = 'https://jigma-export-chat.web.app/static/css/main.22f864ae.css';
              // add to the head of the document
              d.getElementsByTagName('head')[0].appendChild(link);
              v.onload = function() {
                window.initChatbot("${res.data.export_chat_id}");
              }
              v.src = "https://jigma-export-chat.web.app/static/js/main.0e503606.js";
              v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
          })(document, 'script');
        </script>`);
        }
        setcreditsUsed(null);
        reloadIframe();
      }else{
        Swal.fire({
          icon: 'warning',
          title: 'Insuficcient credits',
          text: 'You dont have enought credits',
          footer: '<a href="/payments">Buy more credtis</a>'
        });
        setLoading(false);
      }
        
    }

    const downloadQR = () => {
      const canvas = qrRef.current.querySelector("canvas");
    
      if (canvas) {
        const imgData = canvas.toDataURL("image/png");
    
        // Create an anchor element and programmatically click it to initiate download
        const aEl = document.createElement("a");
        aEl.href = imgData;
        aEl.download = "QRCode.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
      } else {
        console.warn("Canvas element not found.");
      }
    };

    const shareToWhatsApp = () => {
      const text = "Check out this Chatbot and use it: " + encodeURIComponent(iframeUrl);
      const whatsappUrl = `https://api.whatsapp.com/send?text=${text}`;
      window.open(whatsappUrl, "_blank");
    };

    const shareToTwitter = () => {
      const text = "Check out this Chatbot and use it: ";
      const url = encodeURIComponent(iframeUrl);
      const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
      window.open(twitterUrl, "_blank");
    };

    const shareToFacebook = () => {
      const url = encodeURIComponent(iframeUrl);
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      window.open(facebookUrl, "_blank");
    };

    const reloadIframe = () => {
      setIframeKey(prevKey => prevKey + 1);
    };
  

    const deleteChatbot = async () => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          async function deleteConfirmed() {
            try{
              setLoading(true);
              const res1 = await deleteExportChat(modelID);
              console.log(res1.data);
              const res = await getModels(user.docId);
              //setModels(res);
              const newOptions = res.map(model => ({value: model.uuid, label: model.title}));
              const defaultOption = {value: "new", label: "New Chatbot"};
              newOptions.unshift(defaultOption);
              setOptions(newOptions);
              setLoading(false);
            }
            catch(error){
              console.log("Error while trying to delete the chatbot",error);
            }
          }
          deleteConfirmed();
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
      
    }

    const handleCheckboxChange = (e) => {
      setHumanAssistance(e.target.checked);
    };
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(iframeUrl);
        // Show a message or do something to indicate the text has been copied
    };
    const copyToClipboardCode = () => {
      navigator.clipboard.writeText(js);
      // Show a message or do something to indicate the text has been copied
    };

   /* useEffect(() => {
      async function fetch(){
        const res = await calculateTokens("",file);
        console.log("los tokens en total son: ",res.data);
      }
      fetch();
    }, [nameFileReal]);*/

    /*const onDrop = useCallback(async (acceptedFiles) => {
      console.log(user);
      setLoading(true);
      if (acceptedFiles.length === 0) {
        alert("No file uploaded");
        return;
      }
      if (acceptedFiles.length > 3) {
        alert("Upgrade your plan to upload more files");
        return;
      }
    
      let allFilesAccepted = true;
      let totalTokens = 0;
    
      // Calculate total tokens for all files
      for (const file of acceptedFiles) {
        const res = await calculateTokens("", [file]); // Assuming calculateTokens can work with a single file
        totalTokens += res.data;
      }
    
      if (totalTokens > 500) {
        // Show error message and exit the function
        Swal.fire({
          icon: 'error',
          title: 'Token Limit Exceeded',
          text: 'The total tokens for all files exceed 1,000',
        });
        setLoading(false);
        return;
      }
    
      acceptedFiles.forEach(file => {
        const extension = file.name.split('.').pop();
        if (file.size > 30000000) {
          Swal.fire({
            icon: 'error',
            title: 'File too heavy',
            text: 'The size of the file must be below 10mb',
          });
          allFilesAccepted = false;
        } else if (!["csv", "docx", "docs", "doc", "pdf", "pptx", "txt"].includes(extension)) {
          Swal.fire({
            icon: 'error',
            title: 'File not supported',
            text: 'File format is not one of the supported',
          });
          allFilesAccepted = false;
        }
      });
    
      if (allFilesAccepted) {
        const nameFiles = [];
        const files = [];
        const nameFilesReals = [];
        
    
        acceptedFiles.forEach((file, index) => {
          nameFilesReals.push(file.name);
          _nameFileReal(nameFilesReals);
          const name_file = "input-" + params.uuid + "-" + index + "." + file.name.split('.').pop();
          nameFiles.push(name_file);
          files.push(file);
        });
    
        files.forEach((fileObj) => {
          const upload = async () => {
            const res = await uploadfile(fileObj.name, fileObj);
            _url(res);
            setLoading(false);
          };
          upload();
        });
    
        setNamefile(nameFiles);
      }
      
    }, [params, _nameFileReal, _url, _file, uploadfile]);
    */
    

    const onDrop = useCallback(async (acceptedFiles) => {
        console.log(user);
        if (acceptedFiles.length === 0) {
          alert("No file uploaded");
          return;
        }
        if (acceptedFiles.length > 3) {
          alert("Upgrade your plan to upload more files");
          return;
        }
        let allFilesAccepted = true;
    
        acceptedFiles.forEach(file => {
            const extension = file.name.split('.').pop();
            if (file.size > 30000000) {
                Swal.fire({
                  icon: 'error',
                  title: 'File too heavy',
                  text: 'The size of the file must be below 10mb',
                })
                //alert(`The file '${file.name}' is too heavy`);
                allFilesAccepted = false;
            } else if (extension !== "csv" && extension !== "docx" && extension !== "docs" && extension !== "doc" && extension !== "pdf" && extension !== "pptx" && extension !=="txt") {
                Swal.fire({
                  icon: 'error',
                  title: 'File not supported',
                  text: 'File format is not one of the supported',
                })
                //alert(`The file '${file.name}' must be one of this format csv, docx, docs, doc, pdf or pptx`);
                allFilesAccepted = false;
            }
        });
    
        if (allFilesAccepted) {
          
          const nameFiles = [];
          const files = [];
          const nameFilesReals = [];
          let totalTokens = 0;
          setLoading(true);
          // Move the async uploadfile call into a separate async function
          acceptedFiles.forEach(async (file, index) => {
            nameFilesReals.push(file.name);
            _nameFileReal(nameFilesReals);
            const name_file = "input-" + params.uuid + "-" + index + "." + file.name.split('.').pop();
            nameFiles.push(name_file);
    
            files.push(file);
            const checkTokens = async () => {
              const res = await calculateTokens("",files);
              let roundedCredits = Math.ceil((res.data/2000));
              setcreditsUsed(roundedCredits);
              console.log("los tokens en total son: ",res.data);
              totalTokens = res.data;
              return res.data;
            }
            const tokensTotal = await checkTokens();
            console.log(tokensTotal, "total de tokens");
            if(tokensTotal > 100000){
              settokensLimitReached(true);
              Swal.fire({
                icon: 'error',
                title: 'Token Limit Surpassed',
                text: 'Your files reach the limit of tokens',
              });
            }else{
              settokensLimitReached(false);
            }
            console.log("the files are: ", files);
            _file(files);
        });
          files.forEach((fileObj) => {
            const upload = async () => {
                const res = await uploadfile(fileObj.name, fileObj);
                _url(res);
                setLoading(false);
            };
            upload();
        });
        setNamefile(nameFiles);
      }  
      }, [params, _nameFileReal, _url, _file, uploadfile]);

    const removeItemFile =  (index) => {
    _nameFileReal((prevNameFileReal) => {
        const newNameFileReal = [...prevNameFileReal];
        newNameFileReal.splice(index, 1);
        return newNameFileReal;
    });
    setNamefile((prevNamefile) => {
        const newNameFile= [...prevNamefile];
        newNameFile.splice(index, 1);
        return newNameFile;
    });
    _file((prevFile) => {
        const newFile = [...prevFile];
        newFile.splice(index, 1);
        return newFile;
    });
    _url((prevUrl) => {
        const newUrl = [...prevUrl];
        newUrl.splice(index, 1);
        return newUrl;
    });
    setcreditsUsed(null);
    }

    useEffect(() => {
      if (selectedChatbot !== undefined && Array.isArray(selectedChatbot) && selectedChatbot.length > 0) {
        const firstChatbot = selectedChatbot[0];
        if (firstChatbot && firstChatbot.value !== undefined) {
          console.log(firstChatbot.value);
    
          if (firstChatbot.value !== "new") {
            setisUpdate(true);
            setmodelID(firstChatbot.value);
          }else{
            setSelectedColor("#000000");
            setChatTitle("My Customer Assistant");
            setcustomPersonality("");
            setWelcomeMessage("Hello! How may I assist you today?");
            setbuttonText("Chat With Us");
            setSelectedId(null);
            setisUpdate(false);
          }
        }
      }
    }, [selectedChatbot]);
    
    

    const handleOptionChange = (selected) => {
      setSelectedChatbot(selected);
      setmodelID(selected[0].value);
      setloadCustomizations(true);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className="App">
      {/* Header */}
      <NotificationBar
        message="Out of credits? Upgrade for uninterrupted conversations and more training options!"
        linkText="Upgrade!"
        linkUrl="/pricing"
      />
      <NewHeader credits={credits}/>
      {/* Main Content */}
      <main className="App-main">
        {
          !trainSuccessfull ? (
            <div className="sideContent">
        <div className='chat-selector'>
          <div className="learn-jigma" style={{marginBottom:'10px'}}>
            <button className='cta-button' onClick={togglePopup}>Learn how to use Jigma</button>
          </div>
          <h6 style={{color:'white'}}>Create or Modify a Chatbot:</h6> 
          {options.length > 0 ? (
            <div className='chat-selector-container' id= "chatbot-dropdown-selector">
            <Select 
              className='chat-dropdown1' 
              options={options} 
              defaultValue={selectedChatbot} 
              placeholder={selectedChatbot.label} 
              onChange={handleOptionChange}
            />
            {
              !isLoading ? (
                <button onClick={deleteChatbot} disabled={!isUpdate} >
                  <i className='bx bx-trash-alt trash-alt-icon'></i>
                  <label style={{marginTop:"8px", color:"white", cursor:"pointer"}}>Delete</label>
                </button>
              ):(
                <Loading />
              )
            }
            {isPopupOpen && <Popup />}
            </div>
          ) : (
            <p>Loading options...</p>
          )}      
        </div>
        
          <div className={`container-personalization ${loadCustomizations ? '' : 'hidden'}`}>
          
          
        <h6 style={{marginTop:'30px'}}>Name Your Chatbot:</h6>
        <div className="custom-textarea-container">
              <textarea id='title-input-area' rows="1"  placeholder='e.g., MyCustomerAssistant' value={chatTitle} onChange={(e) => setChatTitle(e.target.value)}></textarea>
        </div>
        <h6 style={{marginTop:'30px'}}>Choose a Chatbot Personality:</h6>
        {
          !isUpdate ? (
            <div className="grid-container">
          <div key={1} className={`grid-item ${selectedId === 1 ? 'selected' : ''}`} onClick={() => setSelectedId(1)}>
              <img src={business} alt={`image-1`} id={1}/>
              <small>Business Assistant</small>
          </div>
          <div key={2} className={`grid-item ${selectedId === 2 ? 'selected' : ''}`} onClick={() => setSelectedId(2)}>
              <img src={customerService} alt={`image-2`} id={2}/>
              <small>Customer Service</small>
          </div>
          <div key={3} className={`grid-item ${selectedId === 3 ? 'selected' : ''}`} onClick={() => setSelectedId(3)}>
              <img src={legal} alt={`image-3`} id={3}/>
              <small>Legal Assistant</small>
          </div>
          <div key={4} className={`grid-item ${selectedId === 4 ? 'selected' : ''}`} onClick={() => setSelectedId(4)}>
              <img src={travel} alt={`image-4`} id={4}/>
              <small>Travel Assistant</small>
          </div>
          <div key={5} className={`grid-item ${selectedId === 5 ? 'selected' : ''}`} onClick={() => setSelectedId(5)}>
              <img src={chef} alt={`image-5`} id={5}/>
              <small>Cook Assistant</small>
          </div>
          <div key={6} className={`grid-item ${selectedId === 6 ? 'selected' : ''}`} onClick={() => setSelectedId(6)}>
              <img src={doctor} alt={`image-6`} id={6}/>
              <small>Health Assistant</small>
          </div>
          <div key={7} className={`grid-item ${selectedId === 7 ? 'selected' : ''}`} onClick={() => setSelectedId(7)}>
              <img src={organizer} alt={`image-7`} id={7}/>
              <small>Event Organizer</small>
          </div>
          <div key={8} className={`grid-item ${selectedId === 8 ? 'selected' : ''}`} onClick={() => setSelectedId(8)}>
              <img src={priest} alt={`image-8`} id={8}/>
              <small>Spiritual Assistant</small>
          </div>
          <div key={9} className={`grid-item hero ${selectedId === 9 ? 'selected' : ''}`} onClick={() => setSelectedId(9)}>
              <img src={custom} alt={`image-9`} id={9}/>
              <small>Custom</small>
          </div>
      </div>
          ):(
            <></>
          )
        }
        
          {selectedId === 9 && (
          <div className="custom-textarea-container">
              <h6>Craft a Unique Personality for Your Chatbot:</h6>
              <textarea rows="4" placeholder='For example: You are a fitness coach. Use the knowledge I have provided to assist gym-goers.' value={customPersonality} onChange={(e) => setcustomPersonality(e.target.value)}></textarea>
          </div>
      )}
      <div style={{ marginTop: '30px' }}>
        <h6>Pick a Color Theme for Your Chatbot:</h6>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: selectedColor,
              border: '0.5px solid white',
              zIndex: 1,
              marginTop:'20px',
            }}
          ></div>
          <input
            id="colorInput"
            type="color"
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value)}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '40px',
              height: '40px',
              opacity: 0,
              zIndex: 2,
              cursor: 'pointer',
            }}
          />
        </div>
      </div>

      {/* Adding the Welcome Message Textarea */}
      <h6 style={{marginTop:'30px'}}>Set a Welcome Message:</h6>
      <div className="custom-textarea-container" style={{marginTop: '20px'}}>
        <textarea id='welcome-textarea-input' rows="2" placeholder='e.g., Hello! How may I assist you today?' value={welcomeMessage} onChange={(e) => setWelcomeMessage(e.target.value)}></textarea>
      </div>
      <div style={{ marginTop: '20px' }}>
        <input
          type="checkbox"
          id="enableHumanAssistance"
          name="enableHumanAssistance"
          checked={isHumanAssistanceEnabled}
          onChange={handleCheckboxChange}
          style={{ transform: 'scale(1.5)', marginRight:"10px", cursor:"pointer" }}
          // Add your checked and onChange logic here if needed
        />
        <label htmlFor="enableHumanAssistance">
          Enable Human Assistance
          <span
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            title="Enable to switch from AI to human mode on your phone and take over conversations via the Jigma Chat Manager app"
          >
            <i class='bx bx-question-mark'  style={{backgroundColor:"#707070", padding:"3px", borderRadius:"50%"}}></i>
          </span>
        </label>
      </div>
      <h6 style={{marginTop:'30px'}}>Chat Button Text:</h6>
      <div className="custom-textarea-container" style={{marginTop: '20px', marginBottom:'20px'}}>
        <textarea id='chat-button-input' rows="1" placeholder='Chat with me!' value={buttonText} onChange={(e) => setbuttonText(e.target.value)}></textarea>
      </div>
      <div className='enter-data-container'>
      <div className='tab-menu'>
        <button
          id='drop-file-button'
          className={activeTabDrop === 'dropFile' ? 'active-tab' : ''}
          style={{borderBottom: activeTabDrop === 'dropFile' ? '2px solid blue' : ''}}
          onClick={() => setActiveTabDrop('dropFile')}
        >
          Drop a File
        </button>
        <button
          id='enter-text-button'
          className={activeTabDrop === 'enterText' ? 'active-tab' : ''}
          style={{borderBottom: activeTabDrop === 'enterText' ? '2px solid blue' : ''}}
          onClick={() => setActiveTabDrop('enterText')}
        >
          Enter Manually
        </button>
      </div>

      {activeTabDrop === 'dropFile' ? (
        nameFileReal.length === 0 ? (
          <div>
            <label style={{color:"black"}}>
            Upload Files to Train Your Chatbot <b>(*Accepted formats: pdf, docx, csv, txt, pptx)</b>
            </label>
            <div id='drop-box-container' className='drop-box1' {...getRootProps()}>
              <input {...getInputProps()} />
              <i className='bx bx-cloud-upload upload-icon'></i>
              {isDragActive ? (
                <p className='text-dropzone'>Drop the files here ...</p>
              ) : (
                <p className='text-dropzone'>Drag & Drop Files Here</p>
              )}
            </div>
          </div>
        ) : (
          nameFileReal.map((nameOfFile, i) => (
            <div className='upload-file-card3' key={i}>
              <i className='bx bx-file file-icon1'></i>
              <div className='col'>
                <div className='h5 font-inter text-gym-file1 pt-2'>{nameOfFile}</div>
              </div>
              {isLoading === false && (
                <div className='col-2 pt-1'>
                  <div onClick={removeItemFile} className='delete_file font-inter'>
                    <i className='bx bx-x x-icon1'></i>
                  </div>
                </div>
              )}
            </div>
          ))
        )
      ) : (
        <div>
          <h6 style={{color:"black"}}>
          Type in data, answers, or other details to train your AI. This works in tandem with any uploaded files
          </h6>
          <div className='custom-textarea-container'>
            <textarea rows="3" type='text' placeholder='e.g., Store Hours: 9 AM - 9 PM; Top Product: Eco-Friendly Water Bottle; Return Policy: 30 Days' value={additionalText} onChange={(e) => setadditionalText(e.target.value)}></textarea>
          </div>
        </div>
      )}
    </div>

    {
              nameFileReal.length > 0 ? (
                  isLoading ? (
                      <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'10px'}}>
                          <Loading />
                      </div>
                  ) : (
                      <></>
                  )
              ) : null
              }
              {
            creditsUsed && (
              <label style={{marginTop:"15px"}}>
                The training process will use  
                <span style={{
                  fontSize: '1.5em',  // Bigger font size
                  color: '#f3fb08',  // A different color
                  fontWeight: 'bold',
                  marginLeft:'10px',
                  }}>
                  {creditsUsed} credits
                </span> 
                <span
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  title="The number of credits used depends on the length of the data you feed"
                >
                  <i class='bx bx-question-mark'  style={{backgroundColor:"#707070", padding:"3px", borderRadius:"50%"}}></i>
                </span>
              </label>
            )
          }
      {
            !trainSuccessfull && !isLoading ? (
      <div className="train-free-button">
        {
          !isUpdate ? (
            <button id='create-chatbot-button' className="cta-button train-update-button" onClick={() => { setLoading(true); submit(); setReadyToTrain(false); window.scrollTo(0, 0);}} disabled={!readyToTrain}>Create Your Chatbot</button> 
          ):(
            <button id='update-chatbot-button' className="cta-button train-update-button" onClick={() => { setLoading(true); submit(); setReadyToTrain(false); window.scrollTo(0, 0);}} disabled={!isUpdate}>Update Chatbot</button> 
          )
        }
                
      </div>
            ):(
              isLoading && (
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'10px'}}>
                  <Loading />
                </div>
              )
            )
        }  
      </div>
        
        
        </div>
          ):(
            <div className='sideContent-reset-section'>
              <div className='reset-icon'>
                  <i className="fas fa-check-circle"></i> {/* Replace with your icon choice */}
              </div>
              <h2>Congratulations!</h2>
              <p>Your Chatbot is Live and Ready to Engage!</p>
              <button id='reset-chatbot-button' className="cta-button1" onClick={() => setTrainSuccessfull(false)}>
                Start Over!
              </button>
          </div>
          )
        }
        
        <div className="preview">
          {/* Your preview content will go here */}
          <h1>Create and Share Your <label style={{color:'#8643FF'}}>Chatbot</label> in Seconds</h1>
          <p> Upload data, pick a persona, and launch your 24/7 AI employee! </p>
          {
            trainSuccessfull || !isUpdate && (
              <div className="chatbot-preview-skeleton">
              <div className="chatbot-preview-header">
                  Chatbot Preview
              </div>
              <div className="chatbot-preview-body">
                  <p>Get ready for the Preview! Just wrap up your chatbot setup on the left panel, or watch the tutorial below. When you're done, simply scroll down to find the 'Create' button!🚀</p>
                  <iframe 
                    className='iframe-youtube'
                    src="https://www.youtube.com/embed/l6hlpr4n95s" 
                    title="YouTube video" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                  </iframe>
              </div>
              </div>
            )
          }
            
                  <div className={`container-iframe-share ${trainSuccessfull || isUpdate ? '' : 'hidden'}`}>
                  <div className='get-link'>
                    <h6 style={{ color: 'black' }}>Share Your Chatbot</h6>
                    <div className="separator"></div>

                    {/* Tab Header */}
                    <div style={{ display: 'flex', borderBottom: '1px solid black' }}>
                      <div 
                        style={{
                          flex: 1, 
                          padding: '10px', 
                          textAlign: 'center', 
                          cursor: 'pointer', 
                          color: 'black',
                          borderBottom: activeTab === 'share' ? '2px solid blue' : ''
                        }}
                        onClick={() => setActiveTab('share')}
                      >
                        Share Link
                      </div>
                      <div 
                        style={{
                          flex: 1, 
                          padding: '10px', 
                          textAlign: 'center', 
                          cursor: 'pointer', 
                          color: 'black',
                          borderBottom: activeTab === 'embed' ? '2px solid blue' : ''
                        }}
                        onClick={() => setActiveTab('embed')}
                      >
                        Embed Code
                      </div>
                    </div>

                    {/* Tab Content */}
                    <div style={{ padding: '20px' }}>
                      {activeTab === 'share' ? (
                        <>
                          <div className="link-container">
                            <div className="url-section">
                              <input type="text" readOnly value={iframeUrl} className="share-link-input" />
                              <div className='social-links'>
                              <button id='copy-chatbot-link' className="cta-button copy-button" onClick={copyToClipboard}>Copy</button>
                              <button id='share-whatsapp' onClick={shareToWhatsApp} className='whatsapp-icon-jigma'><i class='bx bxl-whatsapp' style={{color:'white', fontSize:'1.5rem'}}></i></button>
                              <button id='share-twitter' onClick={shareToTwitter} className='twitter-icon-jigma'><i class='bx bxl-twitter' style={{color:'white', fontSize:'1.5rem'}}></i></button>
                              <button id='share-facebook' onClick={shareToFacebook} className='facebook-icon-jigma'><i class='bx bxl-facebook' style={{color:'white', fontSize:'1.5rem'}}></i></button>
                              </div>
                            </div>
                          </div>
                          
                          <div className="qr-container">
                            <p>Or scan this QR code:</p>
                            <div ref={qrRef}  className="qr-section">
                            <QRCode value={iframeUrl} />
                            </div>
                            <button id='download-qr-code-button' className="cta-button copy-button" onClick={downloadQR}>Download QR Code</button>
                          </div>
                        </>
                      ) : (
                        <div className="embed-container">
                          <p>Place this code in the 'head' section of your website's HTML:</p>
                          <SyntaxHighlighter
                            language="javascript" // Replace "javascript" with the appropriate language for your script
                            style={vscDarkPlus} // Use any desired style from the available options
                            className='script-js-area'
                          >
                            {js}
                          </SyntaxHighlighter>
                          <button className="cta-button copy-button" onClick={copyToClipboardCode}>Copy Code</button>
                        </div>
                      )}
                    </div>
                  </div>
                <div className="iframe-container">
                    <iframe 
                    id='preview-iframe'
                    key={iframeKey}
                    className="responsive-iframe"
                    src={iframeUrl}
                    title="Your Iframe Title">
                    </iframe>
                </div>
                </div>    
             
            
          
        </div>
      </main>
      <NewFooter />
    </div>
  );
}

export default TrainChatbot;
