import { useNavigate} from 'react-router-dom';
import { useAuth } from '../../utils';
import Select from "react-dropdown-select";

import { getUserCredits, newDiscountCredits } from '../../APIFirebase';

import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Legend } from 'recharts';
import {getUserActiveSubscription, getExportChatsByUserId} from '../../APIFirebase';
import {getBasicAnalyticsInfo, getAiAnalyticsInfo, generatePDFReport, getSessionsData} from '../../API';
import './styles.css';
import Loading from '../../components/Loading';
import NewHeader from '../../components/NewHeader';
import NewFooter from '../../components/NewFooter';


function NewAnalytics() {

    const today = new Date();

    // Get the date 7 days ago
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const formatDateForInput = (timestamp) => {
      const date = new Date(timestamp);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    };

    function formatDateSessionData(epochMilliseconds) {
      const date = new Date(epochMilliseconds);
      const month = date.getMonth() + 1; // Months are zero-based in JavaScript
      const day = date.getDate();
      const year = date.getFullYear();
    
      return `${month}/${day}/${year}`;
    }
    
  const colors = ['#ff7300', '#82ca9d', '#f44336', /* add more colors as needed */];
  const {user,setUser, onLogout} = useAuth();
  const navigate = useNavigate();
  
  const [credits, setcredits] = useState(null);

  const [needUpgrade, setneedUpgrade] = useState(false);
  const [activeSubscriptionDescription, setActiveSubscriptionDescription] = useState("");
  const [infoBasic, setinfoBasic] = useState(null);
  const [sessionsInfo, setSessionsInfo] = useState(null);
  const [deviceBySession, setdeviceBySession] = useState(null);
  const [browserBySession, setbrowserBySession] = useState(null);
  const [usersByDay, setusersByDay] = useState(null);
  const [dataSentiment, setdataSentiment] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [generateReportSuccesfull, setgenerateReportSuccesfull] = useState(false);
  const [startDate, setStartDate] = useState(sevenDaysAgo.getTime());
  const [endDate, setEndDate] = useState(today.getTime());
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [hasExportChats, setHasExportChats] = useState(false);

  const handleOptionChange = (selected) => {
    console.log(selected[0].value);
    setSelectedOption(selected);
  };

  const handleStartDateChange = (event) => {
    const date = new Date(event.target.value);
    const timestamp = date.getTime();
    setStartDate(timestamp);
  };
  

  const handleEndDateChange = (event) => {
    const date = new Date(event.target.value);  // Create a Date object from the string
    const timestamp = date.getTime();
    setEndDate(timestamp);
  };

  const generatePDF = async () => {
    if(hasExportChats==true){
      try {
        setisLoading(true);
        const AiInfo = await getAiAnalyticsInfo(selectedOption[0].value,startDate,endDate);
        console.log(AiInfo);
        generatePDFReport(AiInfo.data,"Report AI")
        .then((success) => {
          if (success) {
            async function discountCredits(){
              console.log("descuenta creditos");
              await newDiscountCredits(user.docId,5);
              setgenerateReportSuccesfull(true);
            }
            discountCredits();
          } else {
            alert("There was an error while generating the report, please try again");
          }
        });
        setisLoading(false);
      }
      catch(error){
        setisLoading(false);
        alert("There was an error while generating the report, please try again");
      }
    }else{
      alert("The selected assistant does not have export chats.");
    }
  }

  useEffect(() => {
    async function getCredits(){
      console.log("retrieve credits from firebase");
      const coins = await getUserCredits(user.docId);
      setcredits(coins);
      await localStorage.setItem("credits",coins);
    }
    const creditCheck = localStorage.getItem("credits");
    if(creditCheck === null){
      getCredits();
    }else{
      setcredits(creditCheck);
    }
  }, []);

  useEffect(() => {
    async function getCredits(){
      console.log("retrieve credits from firebase");
      const coins = await getUserCredits(user.docId);
      setcredits(coins);
      await localStorage.setItem("credits",coins);
    }
    getCredits();
    setgenerateReportSuccesfull(false);
  }, [generateReportSuccesfull]);
  

  useEffect(() => {
    async function basicAnalytics(){
      console.log("value selected: ",selectedOption[0].value,startDate,endDate);
      const basicInfo = await getBasicAnalyticsInfo(selectedOption[0].value,startDate,endDate);
      const sessionsData = await getSessionsData(selectedOption[0].value,formatDateSessionData(startDate),formatDateSessionData(endDate));
      setSessionsInfo(sessionsData);
      console.log(sessionsData.data);
      if (basicInfo.data.state=="Failed"){
        setHasExportChats(false)
      }else{
        setHasExportChats(true)
      }
      setinfoBasic(basicInfo);
      console.log(basicInfo);
    }
    basicAnalytics();
  }, [startDate,endDate,selectedOption]);
  

  useEffect(() => {
    if(activeSubscriptionDescription !== ""){
      //setneedUpgrade(false);
      console.log("setea");
    }
  }, [activeSubscriptionDescription]);

  useEffect(() => {
    async function fetch_(){
      const uid = await localStorage.getItem('uid');
      if (uid){
        const subscriptions = await getUserActiveSubscription(uid)
        if(subscriptions.length>0){
          const subscription_description = subscriptions[0].items[0].price.product.description
          setActiveSubscriptionDescription(subscription_description)
        }
      }
    }
    async function getListOfChats(){
      const listOfChats = await getExportChatsByUserId(user.docId);
      console.log(listOfChats);
      const listOfIds = listOfChats.map(chat => ({ value: chat.id, label: chat.chatTitle }));

      console.log(listOfIds);
      setOptions(listOfIds);
    }
    async function basicAnalytics(){
      if(selectedOption.value !==null){
        const basicInfo = await getBasicAnalyticsInfo(selectedOption[0].value,startDate,endDate);
        if (basicInfo.data.state=="Failed"){
          setHasExportChats(false)
        }else{
          setHasExportChats(true)
        }
      setinfoBasic(basicInfo);
      console.log(basicInfo);
      }
    }
    fetch_();
    getListOfChats();
    basicAnalytics();
    
  },[]);
  
  useEffect(() => {
    if (infoBasic && infoBasic['data'] && infoBasic['data']['Users by Day']) {
      const usersByDayData = Object.keys(infoBasic['data']['Users by Day']).map((key) => ({
        name: key,
        Users: infoBasic['data']['Users by Day'][key]
      }));
      const sentimentData = [
        {
          name: 'Sentiments',
          Positive: infoBasic.data.percent_positive,
          Negative: infoBasic.data.percent_negative,
          Neutral: infoBasic.data.percent_neutral
        }]
      setdataSentiment(sentimentData);
      setusersByDay(usersByDayData);
    }
  }, [infoBasic]);

  useEffect(() => {
    if(sessionsInfo){
      const deviceTypeBySession = Object.entries(sessionsInfo.data.sessionsAnalyticsBySession.device_type_by_session).map(([name, value]) => ({ name, value }));
      const browserTypeBySession = Object.entries(sessionsInfo.data.sessionsAnalyticsBySession.browser_by_session).map(([name, value]) => ({ name, value }));
      setdeviceBySession(deviceTypeBySession);
      setbrowserBySession(browserTypeBySession);
      console.log("datos", deviceTypeBySession);
    }
  }, [sessionsInfo]);
  

    
  return (
    <div className="App">
      {/* Header */}
      <NewHeader credits={credits}/>
      {/* Main Content */}
      <main className="App-analytics">
            <div className='top-analytics'>
              <div className='analytics-config'>
              <div className="date-selectors mt-2 ml-4">
                <label style={{ marginRight: '10px' }}>Start Date: 
                  <input type="date" value={formatDateForInput(startDate)} onChange={handleStartDateChange} />
                </label>
                <label>End Date: 
                  <input type="date" value={formatDateForInput(endDate)} onChange={handleEndDateChange} />
                </label>
              </div>
              <div className='chat-selector ml-4'>
                    <label className='assistant-label'>Your Chatbots:</label> 
                    {options.length > 0 ? (
                      <Select 
                        className='chat-dropdown' 
                        options={options} 
                        defaultValue={selectedOption} 
                        placeholder={selectedOption.label} 
                        onChange={handleOptionChange}
                        style={{width:"250px"}}
                      />
                    ) : (
                      <p>Loading options...</p>
                    )}      </div>
              </div>
              <div className='ai-report-panel'>
                <div className="report-container">
                  <div className="report-content">
                    <h5>Try out our AI generated reports:</h5>
                    {
                      isLoading ? (
                        <Loading/>
                      ) : (
                        <button onClick={generatePDF} className='pdf-button ai-button'>
                          <span className="ai-icon">🪄</span> Generate AI Analysis Report
                        </button>
                      )
                    }
                    <label>Cost: 5 Credits</label>
                  </div>
                  <div className="video-content">
                    <iframe 
                      src="https://www.youtube.com/embed/0yQsZxWkppI?si=HNpbG80dnvAXNwb2" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          <div className='scorecard-container p-2'>
            <div className='scorecard'>
              <h6>Total Conversations</h6>
              <p style={{color:"white"}}>{infoBasic?.data?.total_conversations || 0}</p>
            </div>
            <div className='scorecard'>
              <h6>Avg Conversation Duration</h6>
              <p style={{color:"white"}}>{(infoBasic?.data?.average_conversation_duration*60 || 0).toFixed(1)} Seconds</p>
            </div>
            <div className='scorecard'>
              <h6>Avg Conversation Length</h6>
              <p style={{color:"white"}}>{(infoBasic?.data?.mean_conversation_length || 0).toFixed(1)} Messages</p>
            </div>
          </div>
          <div className='charts-container'>
          <ResponsiveContainer width='100%' height={300} >
            <LineChart data={usersByDay}>
              <XAxis dataKey="name" stroke="white" tick={{ fill: 'white' }} />
              <YAxis stroke="white" tick={{ fill: 'white' }} />
              <Tooltip />
              <CartesianGrid stroke="#7c7c7c" />
              <Line type="monotone" dataKey="Users" stroke="#ff7300" />
              
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={300}>
            <BarChart data={dataSentiment}>
              <XAxis dataKey="name" stroke="white" tick={{ fill: 'white' }} />
              <YAxis stroke="white" tick={{ fill: 'white' }} />
              <Tooltip />
              <CartesianGrid stroke="#7c7c7c" />
              <Bar dataKey="Positive" fill="#82ca9d" />
              <Bar dataKey="Negative" fill="#f44336" />
              <Bar dataKey="Neutral" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
          
          <ResponsiveContainer width='70%' height={300}>
            <PieChart>
              <Pie data={deviceBySession} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} >
                {
                  deviceBySession?.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                }
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='70%' height={300}>
            <PieChart>
              <Pie data={browserBySession} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} >
                {
                  deviceBySession?.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                }
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
          </div>
     
      </main>

      <NewFooter />
    </div>
  );
}

export default NewAnalytics;
