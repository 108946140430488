import React from 'react';

const avatarBot = ({avatarColor}) => {
  return (
    <div className="bot-avatar" style={{ width: '40px', height: '40px',marginRight:'10px', borderRadius: '50%', backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center',border: '1px solid lightgray' }}>
  <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12.0001" cy="12" r="12" fill={avatarColor}/>
  <path d="M11.3875 17.5253H10.1815C7.15747 17.5253 4.69147 15.0593 4.69147 12.0353V7.69727H6.83347V12.0533C6.83347 13.9073 8.32747 15.4013 10.1815 15.4013H11.3875V17.5253Z" fill="white"/>
  <path d="M17.1652 7.69727V12.0533C17.1652 13.9073 15.6712 15.4013 13.8172 15.4013H13.3492L11.3692 17.5073V20.4953L11.4412 20.5493L14.2852 17.5073C17.0932 17.2553 19.2892 14.8973 19.2892 12.0533V7.69727H17.1652Z" fill="white"/>
  <path d="M17.2023 7.7336C14.3403 4.8716 9.69633 4.8716 6.83433 7.7336L5.32233 6.2216C9.01233 2.5316 15.0243 2.5316 18.7143 6.2216L17.2023 7.7336Z" fill="white"/>
  <path d="M14.7712 8.99414H9.31717C8.47117 8.99414 7.78717 9.67814 7.78717 10.5241V11.1181C7.78717 11.9641 8.47117 12.6481 9.31717 12.6481H14.7892C15.6352 12.6481 16.3192 11.9641 16.3192 11.1181V10.5241C16.3012 9.69614 15.6172 8.99414 14.7712 8.99414ZM9.92917 11.6221C9.49717 11.6221 9.13717 11.2801 9.13717 10.8301C9.13717 10.3801 9.47917 10.0381 9.92917 10.0381C10.3792 10.0381 10.7212 10.3801 10.7212 10.8301C10.7212 11.2801 10.3612 11.6221 9.92917 11.6221ZM14.0152 11.6221C13.5832 11.6221 13.2232 11.2801 13.2232 10.8301C13.2232 10.3801 13.5652 10.0381 14.0152 10.0381C14.4652 10.0381 14.8072 10.3801 14.8072 10.8301C14.8072 11.2801 14.4472 11.6221 14.0152 11.6221Z" fill="white"/>
  </svg>
</div>

  );
};
export default avatarBot;


