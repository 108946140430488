import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import Input from '../Input';
import Loading from '../Loading';
import './styles.css';

function Card({title,submit,isLoading}) {
  const [email,_email] = useState('')
  const [password,_password] = useState('');

  const onChangeEmail = (value) => {
    _email(value.target.value)
  }
  const onChangePass = (value) => {
    _password(value.target.value)
  }
  return (
    <div className="card_login">
      <div className='container mt-2 mb-5' >
        <div className='row justify-content-center'  >
          <div className='col-10 col-lg-10 ' >
            <div className='row' >
              <div className='col-12 text-center pt-3' >
                <h2 className='card_title font-inter' >{title}</h2>
              </div>
              <div className='col-12 my-2' >
                <Input className='font-inter' type={"email"} name="email" value={email} onChange={onChangeEmail} title="Email" placeholder={"Enter email..."} />
              </div>
              <div className='col-12 my-2' >
                <Input className='font-inter' type={"password"} name="password" value={password} onChange={onChangePass}  title="Password"  placeholder={"Enter password..."} />
              </div>
              
              {
                isLoading ?
                (<div className='text-center pt-5'><Loading /></div>)
                :
                (
                  <div className='col-12 mt-5' >
                    <button onClick={() => submit(email,password)} className='button-login-signup w-100 font-inter'  >LOG IN</button>
                  </div>
                )

              }
              
              {/* <div className='text-center pt-4' >
                <Link className='button_text' to="/forgot-password" >FORGOT PASSWORD?</Link>
              </div> */}
              <div className='bottom_button mt-2 ' >
            {
              isLoading==false &&
              <Link className='button-login-signup w-100 signup font-inter' to="/signup" >SIGNUP</Link>
            }
          </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Card;
