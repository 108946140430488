import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../Input';
import './styles.css';
import { useAuth } from '../../utils';
import Loading from '../Loading';
import { addModelToUser, getModels, getModelsRemaining } from '../../APIFirebase';


function Card(props) {
  const [title,_title] = useState('')
  const [description,_description] = useState('');
  const [personality,_personality] = useState('');
  const [loading,_setLoading] = useState(false);
  const {user,setUser} = useAuth()
  const onChangeTitle = (value) => {
    _title(value.target.value)
  }
  const onChangeDescription = (value) => {
    _description(value.target.value)
  }
  const onChangePersonality = (value) => {
    _personality(value.target.value)
  }
 const navigate = useNavigate();

 useEffect(() => {
  // console.log(context.user.models)
  // if ((context.user.models.length> 0) && (context.user.models[context.user.models.length-1].link == null))
  //   navigate('/dashboard')

 },[])

  const submit = async () => {
    // console.log(context.user.models[context.user.models.length-1])
    _setLoading(true)
    //const models = await getModels(user.docId);
    const remaining_models = await getModelsRemaining(user.docId)
      try {
        if (title===""){
          alert("You need to define a title for your model");
          _setLoading(false)
        }else if (description===""){
          alert("You need to define a description for your model");
          _setLoading(false)
        }else if(user.hasUnfinishModel){
          alert("You must wait, the last model created is not already finish. If you never load a file,delete the model and create a new one");
          _setLoading(false)
        }else if(remaining_models<=0){
          alert("You reached the maximum number of model creation. Buy more credits to continue training!");
          _setLoading(false)
        }else {
          setUser({
            ...user,
            hasUnfinishModel: true
          })
          const res = await addModelToUser(title,description,user.docId,personality);
          _setLoading(false)
          navigate("/gym/"+title+"/"+description+"/"+res);
        }
        
      } catch (error) {
        alert(error)
        _setLoading(false)
      }
   }


  return (
    < >
      <div className="card_pre_gym">
        <div className='container mt-2 mb-5' >
          <div className='row justify-content-center'  >
            <div className='col-10  ' >
              <div className='row' >
                <div className='col-12 text-center pt-3' >
                  <h2 className='card_title font-inter' >{props.title}</h2>
                </div>
                <div className='col-12 text-center pt-3' >
                  <div className='card_sub_title_modal font-inter' >
                    Please name your next amazing creation!
                  </div>
                </div>
                <div className='col-12 my-2' >
                  <Input  name="title" type={"title"} onChange={onChangeTitle} value={title} t title="Assistant Name" placeholder={"Enter assitant name..."} rows="1" />
                </div>
                <div className='col-12 my-2' >
                  <Input required={true} name="description" type={"description"} onChange={onChangeDescription} value={description} t title="Description" placeholder={"Enter description..."} rows="1"/>
                </div>
                <div className='col-12 my-2' >
                  <Input required={true} name="personality" row={6} type={"personality"} onChange={onChangePersonality} value={personality} t title="Personality of your Assistant" placeholder={"e.g.: You are a travel assistant"} rows="4"/>
                </div>
                <div className='col-12 mt-5' >
                 {
                  loading ?
                  (
                    <div className='text-center' ><Loading /></div>
                  )
                  :
                  (
                    <>
                    <div className="button_container">
                      <button onClick={submit} className='btn btn-primary font-inter  col-12 button-train-pregym w-100'  ><label className='button-train-text'>Start AI Education</label></button>
                    </div>
                    <div className="button_container">
                      <Link to="/dashboard" className='btn mt-2 col-12 button-goback font-inter'  ><label className='button-goback-text'>Go back</label></Link>
                    </div>
                    </>
                  )
                 }
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    
  );
}

export default Card;
