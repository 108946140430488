import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

export const getDate = () => {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    return dateTime;
}


export const useAuth = () => {
    return useContext(AuthContext);
  };