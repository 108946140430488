import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../utils';


import { getUserCredits} from '../../APIFirebase';

import './styles.css';
import Card from '../../components/CardProfile';
import NewHeader from '../../components/NewHeader';
import NewFooter from '../../components/NewFooter';

function NewProfile() {
    const {user,setUser, onLogout} = useAuth();
    const navigate = useNavigate();
    const [credits, setcredits] = useState(null);

    useEffect(() => {
      async function getCredits(){
        console.log("retrieve credits from firebase");
        const coins = await getUserCredits(user.docId);
        setcredits(coins);
        await localStorage.setItem("credits",coins);
      }
      const creditCheck = localStorage.getItem("credits");
      if(creditCheck === null){
        getCredits();
      }else{
        setcredits(creditCheck);
      }
    }, []);

  return (
    <div className="App">
      {/* Header */}
      <NewHeader credits={credits}/>
      {/* Main Content */}
      <main className="App-main">
        <Card logout={onLogout}/>
      </main>

      <NewFooter />
      </div>
  );
}

export default NewProfile;
