import Card from '../../components/CardProfile';
import Header from '../../components/Header';
import './styles.css';
import { useAuth } from '../../utils';
import SideBar from '../../components/SideBar';
import { useState } from 'react';

    
function Profile() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }
  const {onLogout} = useAuth();
  return (
    <div className="app-web">
      <SideBar isSidebarOpen={isSidebarOpen} />
      <div className="content-static">
        <Header title="Profile" onToggleSidebar={toggleSidebar} profile_active={0} gym_active={0} back_active={0} edit_active={1}/>
      <div className='content-dynamic'>
      <div className='container-fluid profile-container' >
        <div className='row justify-content-center my-2' >
          
          <div className='col-12 col-lg-6 mt-2 '>
              <Card logout={onLogout}  title="Profile" />
          </div>
          
        </div>
      </div>
      </div>
      </div>
  </div>
  );
}

export default Profile;
