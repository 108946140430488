import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import {getUserActiveSubscription, getExportChatsByUserId} from '../../APIFirebase';
import Card from '../../components/CardProfile';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import {getBasicAnalyticsInfo, getAiAnalyticsInfo, generatePDFReport} from '../../API';
import './styles.css';
import { useAuth } from '../../utils';
import SideBar from '../../components/SideBar';
import Select from "react-dropdown-select";
import Loading from '../../components/Loading';

const data = [
  { month: 'Jan', conversations: 400, recommendations: 300, satisfaction: 78, feedbacks: 200, duration: 5 },
  { month: 'Feb', conversations: 600, recommendations: 450, satisfaction: 80, feedbacks: 230, duration: 4.8 },
  { month: 'Mar', conversations: 800, recommendations: 700, satisfaction: 82, feedbacks: 400, duration: 5.2 },
  // more data...
];

function Analytics() {
  const today = new Date();

  // Get the date 7 days ago
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  // Format the dates in 'yyyy-mm-dd' format
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading 0 and slice last 2 digits
    const day = ("0" + date.getDate()).slice(-2); // Add leading 0 and slice last 2 digits

    return `${year}-${month}-${day}`;
  };

  const {user,setUser} = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [needUpgrade, setneedUpgrade] = useState(false);
  const [activeSubscriptionDescription, setActiveSubscriptionDescription] = useState("");
  const [infoBasic, setinfoBasic] = useState(null);
  const [usersByDay, setusersByDay] = useState(null);
  const [dataSentiment, setdataSentiment] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [startDate, setStartDate] = useState(formatDate(sevenDaysAgo));
  const [endDate, setEndDate] = useState(formatDate(today));
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [hasExportChats, setHasExportChats] = useState(false);
  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  const handleOptionChange = (selected) => {
    console.log(selected[0].value);
    setSelectedOption(selected);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  useEffect(() => {
    async function basicAnalytics(){
      console.log("value selected: ",selectedOption.value);
      const basicInfo = await getBasicAnalyticsInfo(selectedOption[0].value,startDate,endDate);
      if (basicInfo.data.state=="Failed"){
        setHasExportChats(false)
      }else{
        setHasExportChats(true)
      }
      setinfoBasic(basicInfo);
      console.log(basicInfo);
    }
    basicAnalytics();
  }, [startDate,endDate,selectedOption]);
  

  useEffect(() => {
    if(activeSubscriptionDescription !== ""){
      //setneedUpgrade(false);
      console.log("setea");
    }
  }, [activeSubscriptionDescription]);

  useEffect(() => {
    async function fetch_(){
      const uid = await localStorage.getItem('uid');
      if (uid){
        const subscriptions = await getUserActiveSubscription(uid)
        if(subscriptions.length>0){
          const subscription_description = subscriptions[0].items[0].price.product.description
          setActiveSubscriptionDescription(subscription_description)
        }
      }
    }
    async function getListOfChats(){
      const listOfChats = await getExportChatsByUserId(user.docId);
      console.log(listOfChats);
      const listOfIds = listOfChats.map(chat => ({ value: chat.id, label: chat.chatTitle }));

      console.log(listOfIds);
      setOptions(listOfIds);
    }
    async function basicAnalytics(){
      if(selectedOption.value !==null){
        const basicInfo = await getBasicAnalyticsInfo(selectedOption[0].value,startDate,endDate);
        if (basicInfo.data.state=="Failed"){
          setHasExportChats(false)
        }else{
          setHasExportChats(true)
        }
      setinfoBasic(basicInfo);
      console.log(basicInfo);
      }
    }
    fetch_();
    getListOfChats();
    basicAnalytics();
    
  },[]);


  useEffect(() => {
    if (infoBasic && infoBasic['data'] && infoBasic['data']['Users by Day']) {
      const usersByDayData = Object.keys(infoBasic['data']['Users by Day']).map((key) => ({
        name: key,
        Users: infoBasic['data']['Users by Day'][key]
      }));
      const sentimentData = [
        {
          name: 'Sentiments',
          Positive: infoBasic.data.percent_positive,
          Negative: infoBasic.data.percent_negative,
          Neutral: infoBasic.data.percent_neutral
        }]
      setdataSentiment(sentimentData);
      setusersByDay(usersByDayData);
    }
  }, [infoBasic]);
  
  const generatePDF = async () => {
    if(hasExportChats==true){
      try {
        setisLoading(true);
        const AiInfo = await getAiAnalyticsInfo(selectedOption[0].value,startDate,endDate);
        console.log(AiInfo);
        await generatePDFReport(AiInfo.data,"Report AI");
        setisLoading(false);
      }
      catch(error){
        setisLoading(false);
        alert("There was an error while generating the report, please try again");
      }
    }else{
      alert("The selected assistant does not have export chats.");
    }
  }
  

  const totalConversations = data.reduce((total, item) => total + item.conversations, 0);
  const successfulRecommendations = data.reduce((total, item) => total + item.recommendations, 0);
  const avgSatisfaction = (data.reduce((total, item) => total + item.satisfaction, 0) / data.length).toFixed(2);
  const totalFeedbacks = data.reduce((total, item) => total + item.feedbacks, 0);
  const avgDuration = (data.reduce((total, item) => total + item.duration, 0) / data.length).toFixed(2);

  return (
    <div className="app-web">
      <SideBar isSidebarOpen={isSidebarOpen} />
      <div className="content-static-analytics">
        <Header title="Profile" onToggleSidebar={toggleSidebar} profile_active={0} gym_active={0} back_active={0} edit_active={1}/>
        {
          needUpgrade ? (
            <div className="upgrade-plan">
            <div className="upgrade-plan-content">
                <h2>Expand Your Access!</h2>
                <p>Unlock more features and capabilities with our premium plan.</p>
                <Link to="/payments" >
                <button className="upgrade-button" >Upgrade Now!</button>
                </Link>
            </div>
            </div>
          ):
          (
            <div className='content-dynamic-analytics'>
              <div className="date-selectors mt-2 ml-4">
                <label style={{ marginRight: '10px' }}>Start Date: 
                  <input type="date" value={startDate} onChange={handleStartDateChange} />
                </label>
                <label>End Date: 
                  <input type="date" value={endDate} onChange={handleEndDateChange} />
                </label>
              </div>
              <div className='chat-selector ml-4'>
                    <label className='assistant-label'>Your Assistants:</label> 
                    {options.length > 0 ? (
                      <Select 
                        className='chat-dropdown' 
                        options={options} 
                        defaultValue={selectedOption} 
                        placeholder={selectedOption.label} 
                        onChange={handleOptionChange}
                      />
                    ) : (
                      <p>Loading options...</p>
                    )}      </div>
          <div className='scorecard-container p-2'>
            <div className='scorecard'>
              <h4>Total Conversations</h4>
              <p>{infoBasic?.data?.total_conversations || 0}</p>
            </div>
            <div className='scorecard'>
              <h4>Avg Conversation Duration</h4>
              <p>{(infoBasic?.data?.average_conversation_duration*60 || 0).toFixed(1)} Seconds</p>
            </div>
            <div className='scorecard'>
              <h4>Avg Message Length</h4>
              <p>{(infoBasic?.data?.mean_conversation_length || 0).toFixed(1)} Messages</p>
            </div>
          </div>
          <div className='row'>
          <ResponsiveContainer width="45%" height={350}>
            <LineChart data={usersByDay}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Line type="monotone" dataKey="Users" stroke="#ff7300" />
              
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="45%" height={350}>
            <BarChart data={dataSentiment}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Bar dataKey="Positive" fill="#82ca9d" />
              <Bar dataKey="Negative" fill="#f44336" />
              <Bar dataKey="Neutral" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
          </div>
          {
            isLoading ? (
            <div className='pdf-button-container'>
              <Loading/>
            </div>
            ):(
            <div className='pdf-button-container'>
              <button onClick={generatePDF} className='pdf-button'>Generate PDF</button>
            </div>
            )
          }
        </div>
          )
        }
        
      </div>
    </div>
  );
}

export default Analytics;
