import './styles.css';
import './../../App.css';

function Home() {
  return (
    <div className="App ">
      <div className='mt-5 ' >
        <h3 className='text-center' >SORRY</h3>
        <h1 className='text-center' >PAGE NOT FOUND</h1>
      </div>
    </div>
  );
}

export default Home;
