import React from "react";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AuthContext } from '../../AuthProvider';
import './styles.css';
import { useAuth } from '../../utils';
import profilePicture from "../../Assets/SignIn/profile_picture_signin.png";
import googleIcon from "../../Assets/SignIn/google_icon.png";
import Loading from '../../components/Loading';


function LoginNew() {
    const [isLoading, setLoading] = useState(false);
    const {onLogin} = useAuth();
    const [email,_email] = useState('')
    const [password,_password] = useState('');
    const {onLoginWithGoogle} = useAuth();

    useEffect(() => {
        // Function to get the value of a URL parameter by name
        const getParameterByName = (name, url) => {
          if (!url) url = window.location.href;
          name = name.replace(/[\[\]]/g, '\\$&');
          const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
            results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, ' '));
        };
        // Get the trakdeskid parameter from the URL
        const trakdeskid = getParameterByName('trakdeskid');
        // Set the trakdeskid as a cookie with a one-day expiration
        if (trakdeskid != null){
          document.cookie = `trakdesk_cid={"tenantId":"jigma","cid":"${trakdeskid}"}; expires=${new Date(Date.now() + 86400000).toUTCString()}; path=/`;
        }
      }, []);

    const onChangeEmail = (value) => {
        _email(value.target.value)
    }
    const onChangePass = (value) => {
        _password(value.target.value)
    }

    const submit = async (email,password) => {
      setLoading(true)
  
      if (email===""){
        Swal.fire(
            'Error',
            `Enter your email`,
            'error'
          );
        setLoading(false)
  
      }else if(password===""){
        Swal.fire(
            'Error',
            `Enter your password`,
            'error'
          );
        setLoading(false)
  
      }else {
        const res = await onLogin(email, password)
        if (res!==true){
          setLoading(false)
          alert(res)
        }
  
      }
      setLoading(false)
  
    }
  
  return (
    <div className="signin-login">
        <div className="background">
        </div>
        <div className="left-content">
            <div className="left-container">
                <div className="start-content-container">
                    <div className="start-top-content">
                        <div>
                            <span className="jigma-text">Jigma.</span><span className="io-text">io</span>
                            <div className="start-title-description">
                                <span className="start-title">Welcome Back! Please sign in to your Jigma account</span>
                                <span className="description">The Ultimate No-Code Tool for Training your Own AI Assistant</span>
                            </div>
                        </div>
                        <div className="features-container">
                            <div className="features-content">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_512_2727)">
                                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_512_2727)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.48643 16.0854L6.31048 12.6862C5.87567 12.2208 5.90063 11.4846 6.366 11.0497C6.83137 10.6149 7.56765 10.64 8.00245 11.1053L10.4522 13.7272L14.3299 10.1042C14.3648 10.0716 14.4013 10.0417 14.4389 10.0142L16.2625 8.31045C16.7278 7.87564 17.4642 7.90067 17.8989 8.36604C18.3337 8.83134 18.3087 9.56769 17.8434 10.0025L12.2739 15.2063L12.2679 15.1999L10.3413 17L9.48643 16.0854Z" fill="white"/>
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_512_2727" x1="1.78814e-07" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#7122FF"/>
                                    <stop offset="1" stop-color="#8643FF"/>
                                    </linearGradient>
                                    <clipPath id="clip0_512_2727">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                                </svg>
                                <span className="start-feature-text">Get started for Free. No credit card required</span>
                            </div>
                            <div className="features-content">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_512_2727)">
                                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_512_2727)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.48643 16.0854L6.31048 12.6862C5.87567 12.2208 5.90063 11.4846 6.366 11.0497C6.83137 10.6149 7.56765 10.64 8.00245 11.1053L10.4522 13.7272L14.3299 10.1042C14.3648 10.0716 14.4013 10.0417 14.4389 10.0142L16.2625 8.31045C16.7278 7.87564 17.4642 7.90067 17.8989 8.36604C18.3337 8.83134 18.3087 9.56769 17.8434 10.0025L12.2739 15.2063L12.2679 15.1999L10.3413 17L9.48643 16.0854Z" fill="white"/>
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_512_2727" x1="1.78814e-07" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#7122FF"/>
                                    <stop offset="1" stop-color="#8643FF"/>
                                    </linearGradient>
                                    <clipPath id="clip0_512_2727">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                                </svg>
                                <span className="start-feature-text">Pay as you grow. Instant scalability</span>
                            </div>
                            <div className="features-content">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_512_2727)">
                                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_512_2727)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.48643 16.0854L6.31048 12.6862C5.87567 12.2208 5.90063 11.4846 6.366 11.0497C6.83137 10.6149 7.56765 10.64 8.00245 11.1053L10.4522 13.7272L14.3299 10.1042C14.3648 10.0716 14.4013 10.0417 14.4389 10.0142L16.2625 8.31045C16.7278 7.87564 17.4642 7.90067 17.8989 8.36604C18.3337 8.83134 18.3087 9.56769 17.8434 10.0025L12.2739 15.2063L12.2679 15.1999L10.3413 17L9.48643 16.0854Z" fill="white"/>
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_512_2727" x1="1.78814e-07" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#7122FF"/>
                                    <stop offset="1" stop-color="#8643FF"/>
                                    </linearGradient>
                                    <clipPath id="clip0_512_2727">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                                </svg>
                                <span className="start-feature-text">No coding required. Intuitive interface</span>
                            </div>
                        </div>
                    </div>
                    <div className="start-bottom-container">
                        <div className="start-bottom-content">
                            <div className="start-bottom-quota">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <g clip-path="url(#clip0_512_2748)">
                                <path d="M6.11067 23.0956C4.73733 21.637 4 20.001 4 17.349C4 12.6823 7.276 8.49964 12.04 6.43164L13.2307 8.26897C8.784 10.6743 7.91467 13.7956 7.568 15.7636C8.284 15.393 9.22133 15.2636 10.14 15.349C12.5453 15.5716 14.4413 17.5463 14.4413 20.001C14.4413 21.2387 13.9497 22.4256 13.0745 23.3008C12.1993 24.176 11.0123 24.6676 9.77467 24.6676C8.344 24.6676 6.976 24.0143 6.11067 23.0956ZM19.444 23.0956C18.0707 21.637 17.3333 20.001 17.3333 17.349C17.3333 12.6823 20.6093 8.49964 25.3733 6.43164L26.564 8.26897C22.1173 10.6743 21.248 13.7956 20.9013 15.7636C21.6173 15.393 22.5547 15.2636 23.4733 15.349C25.8787 15.5716 27.7747 17.5463 27.7747 20.001C27.7747 21.2387 27.283 22.4256 26.4078 23.3008C25.5327 24.176 24.3457 24.6676 23.108 24.6676C21.6773 24.6676 20.3093 24.0143 19.444 23.0956Z" fill="url(#paint0_linear_512_2748)"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_512_2748" x1="4" y1="15.5496" x2="27.7747" y2="15.5496" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#7122FF"/>
                                <stop offset="1" stop-color="#9256FF"/>
                                </linearGradient>
                                <clipPath id="clip0_512_2748">
                                <rect width="32" height="32" fill="white"/>
                                </clipPath>
                            </defs>
                            </svg>
                                <span className="quota-text">“As a developer, integrating Jigma.io into client sites has been a breeze. It elevates their customer engagement effortlessly. It's now my top recommendation”</span>
                            </div>
                            <div className="start-bottom-profile">
                                <div className="start-bottom-profile-info">
                                    <img src={profilePicture} />
                                    <div className="">
                                        <span className="name">Jahid H</span>
                                        <span className="profile-job">UX/UI Designer</span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>

                </div>
                <div className="start-three-dots">

                </div>
            </div>
        </div>
        <div className="right-container">
            <div className="right-content">
                <div className="signup-container">
                    <div className="signup-title-description">
                        <span className="signup-title">Sign In</span>
                        <span className="signup-description">Join us today and unlock a world of possibilities with our seamless sign-up process.</span>
                    </div>
                    <div className="signup-buttons">
                        <button onClick={onLoginWithGoogle} className="signup-google">
                            <img src={googleIcon} />
                            <span className="google-button-text">Sign in with Google</span>
                        </button>
                    </div>
                </div>
                <span className="or-text">Or</span>
                <div className="info-container">
                    <div className="signup-inputs">
                        <label>Email*</label>
                        <input className="input-email" type="email" value={email} onChange={onChangeEmail} placeholder="Enter you Email"></input>
                        <label>Password*</label>
                        <input className="input-password" type="password" value={password} onChange={onChangePass} placeholder="Create a password"></input>
                            {
                                !isLoading ? (
                                    <button onClick={() => submit(email,password)} className="create-account-button">
                                        <span className="create-account-button-text">Sign in to your account</span>
                                    </button>
                                ):(
                                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'10px'}}>
                                        <Loading />
                                    </div>
                                )
                            }
                        <div>
                            <span className="have-an-account-text" style={{color:"black"}}>Don’t have an account?</span>
                            <Link to='/signup'>
                            <span className="signin-to-your-account">Sign up here</span>
                            </Link>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>
  );
}

export default LoginNew;




