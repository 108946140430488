import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  signInWithRedirect,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  doc,
  where,
  addDoc,
  setDoc,
} from "firebase/firestore";
import Swal from 'sweetalert2';
const firebaseConfig = {
  apiKey: "AIzaSyDQb0lS_ZE8BMkBQ7JDMH446vlQxsaOSok",
  authDomain: "sam-ai-177ab.firebaseapp.com",
  databaseURL: "https://sam-ai-177ab-default-rtdb.firebaseio.com",
  projectId: "sam-ai-177ab",
  storageBucket: "sam-ai-177ab.appspot.com",
  messagingSenderId: "921626854653",
  appId: "1:921626854653:web:d6453bcdf7e9df99942a94",
  measurementId: "G-J0LPZ70MYY"
};



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "customers"), where("docId", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {

      await setDoc(doc(db, "customers", user.uid), {
        name: user.displayName,
        email: user.email,
        accessToken: user.accessToken,
        hasUnfinishModel: false,
        credits: 50,
        remaining_models: 3,
        createdModels: 0,
        models: [],
        authProvider: "google",
        docId: user.uid,
        customData: null
      });
      return [true,user.email,user.accessToken,user.displayName,user.uid,false];
    }
    if (docs.docs.length > 0) {
      return res;
      alert("Logged In");
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res
  } catch (err) {
    console.error(err);
    return err;
    // alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await sendEmailVerification(auth.currentUser);
    await setDoc(doc(db, "customers", user.uid), {
      name,
      email,
      accessToken: user.accessToken,
      hasUnfinishModel: false,
      credits: 50,
      remaining_models: 3,
      createdModels: 0,
      models: [],
      docId: user.uid,
      customData: null
    });
    

    return res;
  } catch (err) {
    console.error("ERROR: registerWithEmailAndPassword",err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    Swal.fire(
      'Done!',
      'Password Reset Link Sent',
      'success'
    )
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  firebaseConfig
};