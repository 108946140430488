import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDate, useAuth } from '../../utils';
import Select from "react-dropdown-select";
import Swal from 'sweetalert2';

import { getUserCredits } from '../../APIFirebase';
import { sendEmail } from '../../API';

import './styles.css';
import Loading from '../../components/Loading';
import PricingPanel from '../../components/PricingPanel';
import PricingPanelEdited from '../../components/PricingPanel copy';
import NewHeader from '../../components/NewHeader';
import NewFooter from '../../components/NewFooter';

function ContactUs() {
    const navigate = useNavigate();
    const {user,setUser, onLogout} = useAuth();
    const [credits, setcredits] = useState(null);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const [email,_email] = useState('');
    const [name,_name] = useState('');
    const [message,_message] = useState('');
    const [body, setbody] = useState({})

    useEffect(() => {
        async function getCredits(){
          console.log("retrieve credits from firebase");
          const coins = await getUserCredits(user.docId);
          setcredits(coins);
          await localStorage.setItem("credits",coins);
        }
        const creditCheck = localStorage.getItem("credits");
        if(creditCheck === null){
          getCredits();
        }else{
          setcredits(creditCheck);
        }
      }, []);

    const submitEmail = async () => {
        setisLoading(true);
        let bodyData = {
            "to_email": "developer@samprand.com",
            "contact_name": "User",
            "subject": "User Message",
            "message": `${message} From ${email}`
          };
        let bodyDataForUser = {
            "to_email": email,
            "contact_name": "Jigma",
            "subject": "Jigma Support",
            "message": `Thank you for submiting a message. We have received the message and will be back with you as soon as we can.`
          };
        console.log("sending email... ", bodyData);
        try{
            const res = await sendEmail(bodyData);
        const res1 = await sendEmail(bodyDataForUser);
        setisLoading(false);
        Swal.fire(
            'Email Sent!',
            'Your email has been successfully sent. We will be back with you as soon as we can!',
            'success'
        )
        console.log(res.data);
        console.log(res1.data);
        }catch(error){
            console.log(error);
        }
        
    }

    const onChangeMessage = (value) => {
        _message(value.target.value)
    }


    const onChangeEmail = (value) => {
        _email(value.target.value)
    }
    const onChangeName = (value) => {
        _name(value.target.value)
    }
    
  return (
    <div className="App">
      {/* Header */}
      <NewHeader credits={credits} />
      {/* Main Content */}
      <main className="App-main">
      <div className="content-contact-us">      
        </div>
        <div className="signin-login">
            <div className="background-card">
            </div>
            <div className="left-content">
                <div className="left-container">
                    <div className="start-content-container">
                        <div className="start-top-content">
                            <div>
                                <span className="jigma-text">Jigma.</span><span className="io-text">io</span>
                                <div className="start-title-description">
                                    <span className="start-title">Get in touch</span>
                                    <span className="description">Empower Your Business 24/7 with AI-Driven Support and Solutions</span>
                                </div>
                            </div>
                            <div className="features-container">
                                <div className="features-content">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_512_2727)">
                                        <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_512_2727)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.48643 16.0854L6.31048 12.6862C5.87567 12.2208 5.90063 11.4846 6.366 11.0497C6.83137 10.6149 7.56765 10.64 8.00245 11.1053L10.4522 13.7272L14.3299 10.1042C14.3648 10.0716 14.4013 10.0417 14.4389 10.0142L16.2625 8.31045C16.7278 7.87564 17.4642 7.90067 17.8989 8.36604C18.3337 8.83134 18.3087 9.56769 17.8434 10.0025L12.2739 15.2063L12.2679 15.1999L10.3413 17L9.48643 16.0854Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_512_2727" x1="1.78814e-07" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#7122FF"/>
                                        <stop offset="1" stop-color="#8643FF"/>
                                        </linearGradient>
                                        <clipPath id="clip0_512_2727">
                                        <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    <span className="start-feature-text">Customer Support: Get assistance from our team with in-depth support to maximize your AI employee's capabilities.</span>
                                </div>
                                <div className="features-content">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_512_2727)">
                                        <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_512_2727)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.48643 16.0854L6.31048 12.6862C5.87567 12.2208 5.90063 11.4846 6.366 11.0497C6.83137 10.6149 7.56765 10.64 8.00245 11.1053L10.4522 13.7272L14.3299 10.1042C14.3648 10.0716 14.4013 10.0417 14.4389 10.0142L16.2625 8.31045C16.7278 7.87564 17.4642 7.90067 17.8989 8.36604C18.3337 8.83134 18.3087 9.56769 17.8434 10.0025L12.2739 15.2063L12.2679 15.1999L10.3413 17L9.48643 16.0854Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_512_2727" x1="1.78814e-07" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#7122FF"/>
                                        <stop offset="1" stop-color="#8643FF"/>
                                        </linearGradient>
                                        <clipPath id="clip0_512_2727">
                                        <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    <span className="start-feature-text">Bug Reporting: Notice something off? Your input helps us refine our platform to serve you better.</span>
                                </div>
                                <div className="features-content">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_512_2727)">
                                        <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_512_2727)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.48643 16.0854L6.31048 12.6862C5.87567 12.2208 5.90063 11.4846 6.366 11.0497C6.83137 10.6149 7.56765 10.64 8.00245 11.1053L10.4522 13.7272L14.3299 10.1042C14.3648 10.0716 14.4013 10.0417 14.4389 10.0142L16.2625 8.31045C16.7278 7.87564 17.4642 7.90067 17.8989 8.36604C18.3337 8.83134 18.3087 9.56769 17.8434 10.0025L12.2739 15.2063L12.2679 15.1999L10.3413 17L9.48643 16.0854Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_512_2727" x1="1.78814e-07" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#7122FF"/>
                                        <stop offset="1" stop-color="#8643FF"/>
                                        </linearGradient>
                                        <clipPath id="clip0_512_2727">
                                        <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    <span className="start-feature-text">Enterprise Solutions: Interested in custom-built chatbots tailored for your business needs? Let's talk.</span>
                                </div>
                            </div>
                        </div>
                        <div className="start-bottom-container">
                            <div className="start-bottom-content">
                                <div className="start-bottom-quota">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <g clip-path="url(#clip0_512_2748)">
                                    <path d="M6.11067 23.0956C4.73733 21.637 4 20.001 4 17.349C4 12.6823 7.276 8.49964 12.04 6.43164L13.2307 8.26897C8.784 10.6743 7.91467 13.7956 7.568 15.7636C8.284 15.393 9.22133 15.2636 10.14 15.349C12.5453 15.5716 14.4413 17.5463 14.4413 20.001C14.4413 21.2387 13.9497 22.4256 13.0745 23.3008C12.1993 24.176 11.0123 24.6676 9.77467 24.6676C8.344 24.6676 6.976 24.0143 6.11067 23.0956ZM19.444 23.0956C18.0707 21.637 17.3333 20.001 17.3333 17.349C17.3333 12.6823 20.6093 8.49964 25.3733 6.43164L26.564 8.26897C22.1173 10.6743 21.248 13.7956 20.9013 15.7636C21.6173 15.393 22.5547 15.2636 23.4733 15.349C25.8787 15.5716 27.7747 17.5463 27.7747 20.001C27.7747 21.2387 27.283 22.4256 26.4078 23.3008C25.5327 24.176 24.3457 24.6676 23.108 24.6676C21.6773 24.6676 20.3093 24.0143 19.444 23.0956Z" fill="url(#paint0_linear_512_2748)"/>
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_512_2748" x1="4" y1="15.5496" x2="27.7747" y2="15.5496" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#7122FF"/>
                                    <stop offset="1" stop-color="#9256FF"/>
                                    </linearGradient>
                                    <clipPath id="clip0_512_2748">
                                    <rect width="32" height="32" fill="white"/>
                                    </clipPath>
                                </defs>
                                </svg>
                                    <span className="quota-text">“As a developer, integrating Jigma.io into client sites has been a breeze. It elevates their customer engagement effortlessly. It's now my top recommendation”</span>
                                </div>
                                <div className="start-bottom-profile">
                                    <div className="start-bottom-profile-info">
                                        <img />
                                        <div className="">
                                            <span className="name">Jahid H</span>
                                            <span className="profile-job">UX/UI Designer</span>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>

                    </div>
                    <div className="start-three-dots">

                    </div>
                </div>
            </div>
            <div className="right-container">
                <div className="right-content">
                    <div className="signup-container">
                        <div className="signup-title-description">
                            <span className="signup-title" style={{color:"white"}}>Send us a message</span>
                            <span className="signup-description" style={{color:"white"}}> Use the form below to tell us what you need. Whether it's technical support, bug reporting, or inquiries about our enterprise solutions, we've got you covered.</span>
                        </div>
                        
                    </div>

                    <div className="info-container">
                        <div className="signup-inputs">
                            <label style={{color:'white'}}>Name</label>
                            <input className="input-password" type="text" value={name} onChange={onChangeName} placeholder="Your Name"></input>
                            <label style={{color:'white'}}>Email</label>
                            <input className="input-email" type="email" value={email} onChange={onChangeEmail} placeholder="Your Email"></input>
                            <label style={{color:'white'}}>Message</label>
                            <textarea className="input-message" rows="6" cols="50" value={message} onChange={onChangeMessage} placeholder="Enter your message here"></textarea>
                            {
                                !isLoading ? (
                                    <button  className="create-account-button" onClick={submitEmail}><span className="create-account-button-text">Send Message</span></button>
                                ):(
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Loading /></div>
                                )
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
      </main>

      <NewFooter />
    </div>
  );
}

export default ContactUs;
